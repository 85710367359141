import {ApolloError, FetchResult, gql, MutationHookOptions, MutationTuple, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {SignInOtp, SignInOtpVariables} from './__types__/SignInOtp'

const SIGN_IN_OTP = gql`
    mutation SignInOtp($otp: String!, $email: String!) {
        signInOTP(otp: $otp, email: $email) {
            accessToken
        }
    }
`

export const useMutationSignInOtp = (
    options?: MutationHookOptions<SignInOtp, SignInOtpVariables>,
): [
    MutationTuple<SignInOtp, SignInOtpVariables>,
    (variables: {
        otp: string
        email: string
    }) => Promise<FetchResult<SignInOtp, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<SignInOtp, SignInOtpVariables>(SIGN_IN_OTP, options)
    const callMutation = async (variables: {otp: string; email: string}) => {
        try {
            const result = await mutation[0]({variables})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
