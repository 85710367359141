import {uniq} from 'lodash'
import {Button} from 'nf-ui'
import SvgNamesAndFaces from 'nf-ui/Icons/NamesAndFaces'
import React, {useEffect, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row, scrollIfNeeded} from '../Primitives'
import {Heading1, Heading3, ProgressBars, Questions} from './Primitives'

export type FormFieldConfig = {name: string; availableValues: string[]; subType?: string | null}

export type FormState = Record<string, string>

export const mergeFormState = (state1: FormState, state2: FormState) => {
    return Object.fromEntries(
        uniq([...Object.keys(state1), ...Object.keys(state2)]).map((key) => [
            key,
            state2[key] !== undefined ? state2[key] : state1[key],
        ]),
    )
}

const Question = ({
    heading,
    children,
    bottomBorder,
}: {
    heading: string
    children: string | string[] | JSX.Element[]
    bottomBorder?: boolean
}) => {
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const bodyRef = React.createRef<HTMLDivElement>()

    useEffect(() => {
        if (!collapsed && bottomBorder === false) {
            bodyRef.current?.scrollIntoView()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsed])

    return (
        <>
            <Row height="30px"></Row>
            <Row ref={bodyRef}>
                {collapsed ? (
                    <Column
                        grow={0}
                        shrink={0}
                        style={{fontSize: '25px'}}
                        cursor="pointer"
                        onClick={() => setCollapsed(false)}
                    >
                        +
                    </Column>
                ) : (
                    <Column
                        grow={0}
                        shrink={0}
                        style={{fontSize: '40px', lineHeight: '20px'}}
                        cursor="pointer"
                        onClick={() => setCollapsed(true)}
                        width="14px"
                    >
                        -
                    </Column>
                )}
                <Column grow={0} shrink={0} width="15px"></Column>
                <Column grow={1} shrink={1}>
                    <Row cursor="pointer">
                        <Heading3
                            color="black"
                            onClick={() => setCollapsed(!collapsed)}
                            deviceWidths={['Desktop', 'Tablet']}
                        >
                            {heading}
                        </Heading3>
                        <Heading3
                            color="black"
                            fontSize="18px"
                            onClick={() => setCollapsed(!collapsed)}
                            deviceWidths={['Phone', 'Small']}
                        >
                            {heading}
                        </Heading3>
                    </Row>
                    {!collapsed ? (
                        <>
                            <Row height="20px"></Row>
                            <Row>
                                <Heading3 maxWidth="690px" deviceWidths={['Desktop', 'Tablet']}>
                                    {children}
                                </Heading3>
                                <Heading3 maxWidth="690px" fontSize="18px" deviceWidths={['Phone', 'Small']}>
                                    {children}
                                </Heading3>
                            </Row>
                        </>
                    ) : (
                        <></>
                    )}
                </Column>
                <Column grow={1} shrink={1}></Column>
            </Row>
            <Row height="30px" style={{borderBottom: bottomBorder === false ? '' : 'solid 1px rgba(0,0,0,0.1)'}}></Row>
        </>
    )
}

const QuestionsPanelContent = ({directoryName}: {directoryName: string}) => (
    <Column>
        <Question heading="What are Names &amp; Faces directories?">
            Names &amp; Faces directories are quick, visual lists of people in specific communities, designed to help
            people get to know one another and get in touch. These lists are private and can only be accessed by
            authorised users.
        </Question>
        <Question heading="Who can access the directory I'm adding my details to?">
            Only the {directoryName} families who add their details to this directory will be given access. School staff
            members who interact regularly with the grade will also be given access.
        </Question>
        <Question heading="How do I access Names &amp; Faces?">
            Once you've completed your details you'll be given login details to access the {directoryName} Names &amp;
            Faces directory through a web browser or through the Names &amp; Faces mobile app.
        </Question>
        <Question heading="Is Names &amp; Faces secure?">
            Yes, Names &amp; Faces is SOC 2 certified and is used by schools and organizations around the world. We
            follow POPIA &amp; GDPR best practices and our systems undergo regular audits and penetration tests to
            ensure we meet international security standards.
        </Question>
        <Question heading="Is Names &amp; Faces a social network?">
            <Row>
                No, Names &amp; Faces is not a social network of any kind. There is no messsaging, no pinging or poking
                and no random streams of consciousness.
            </Row>
            <Row height="20px"></Row>
            <Row>
                It is simply a fast, visual list of your community, designed to help you get to know who's who and get
                in touch when you need to.
            </Row>
        </Question>
        <Question heading="What is the cost of Names &amp; Faces?">
            Names &amp; Faces is free for 90 days. Thereafter it is R100 per user per year. We will send you a reminder
            14 days before the end of your free 90 day period.
        </Question>
        <Question
            heading="Can I create a Names &amp; Faces directory for another community I am part of?"
            bottomBorder={false}
        >
            Yes, anyone can create a Names &amp; Faces directory! Visit us at www.namesandfaces.com to find out more.
        </Question>
    </Column>
)

const QuestionsPanel = ({directoryName, onClose}: {directoryName: string; onClose: () => void}) => (
    <Column backgroundColor="rgba(0,0,0,0.8)" width="100%" height="100%">
        <Row grow={0} shrink={0} height="20px"></Row>
        <Row grow={0} shrink={0} height="calc(100% - 60px)">
            <Column grow={0} shrink={0} width="30px"></Column>
            <Column
                grow={0}
                shrink={0}
                width="calc(100% - 60px)"
                maxWidth="880px"
                borderRadius="5px"
                backgroundColor="white"
            >
                <Row grow={0} shrink={0} height="100%" overflow="auto">
                    <Column grow={0} shrink={0} width="40px"></Column>
                    <Column grow={1} shrink={1} height="100%">
                        <Row grow={0} shrink={0} position="sticky" top="0px" backgroundColor="white">
                            <Column grow={1}>
                                <Row grow={0} height="40px"></Row>
                                <Row grow={1}>
                                    <Column shrink={1} deviceWidths={['Desktop', 'Tablet']}>
                                        <SvgNamesAndFaces width="170" height="36"></SvgNamesAndFaces>
                                    </Column>
                                    <Column shrink={1} deviceWidths={['Phone', 'Small']} maxWidth="calc(100% - 107px)">
                                        <SvgNamesAndFaces width="143" height="30"></SvgNamesAndFaces>
                                    </Column>
                                    <Column
                                        onClick={onClose}
                                        cursor="pointer"
                                        style={{
                                            fontSize: '50px',
                                            lineHeight: '35px',
                                            transform: 'rotate(45deg)',
                                            transformOrigin: '50% 50% 0px',
                                        }}
                                    >
                                        +
                                    </Column>
                                </Row>
                                <Row grow={0} height="10px"></Row>
                            </Column>
                        </Row>
                        <Row grow={0} shrink={0}>
                            <QuestionsPanelContent directoryName={directoryName}></QuestionsPanelContent>
                        </Row>
                        <Row grow={1}></Row>
                    </Column>
                    <Column grow={0} shrink={1} width="40px"></Column>
                </Row>
            </Column>
            <Column grow={0} shrink={0} width="30px"></Column>
        </Row>
        <Row grow={0} shrink={0} height="40px"></Row>
    </Column>
)

export const Form = ({
    heading,
    subHeading,
    progress,
    directoryName,
    onNext,
    onBack,
    children,
}: {
    heading: string
    subHeading: string | string[]
    progress?: number | string
    directoryName: string
    onNext?: () => Promise<void>
    onBack?: () => void
    children: React.ReactNode
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [questionsPanelOpen, setQuestionsPanelOpen] = useState<boolean>(false)
    const headingRow = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (headingRow.current) scrollIfNeeded(headingRow.current, {align: 'top', finishTimeoutMilliseconds: 0})
    }, [headingRow])

    return (
        <Row width="100%" height="100%" position="relative" ref={headingRow}>
            <Column width="160px" grow={0} shrink={0} deviceWidths={['Desktop']}></Column>
            <Column width="100px" grow={0} shrink={0} deviceWidths={['Tablet']}></Column>
            <Column width="25px" grow={0} shrink={0} deviceWidths={['Phone', 'Small']}></Column>
            <Column grow={1} shrink={1} height="100%" maxWidth="min(880px, calc(100% - 50px))">
                <Row grow={0} shrink={0} height="49px" deviceWidths={['Desktop']}></Row>
                <Row grow={0} shrink={0} height="45px" deviceWidths={['Tablet']}></Row>
                <Row grow={0} shrink={0} height="25px" deviceWidths={['Phone', 'Small']}></Row>
                <Row grow={0} shrink={0}>
                    <Column shrink={1} deviceWidths={['Desktop', 'Tablet']}>
                        <SvgNamesAndFaces width="170" height="36"></SvgNamesAndFaces>
                    </Column>
                    <Column shrink={1} deviceWidths={['Phone', 'Small']} maxWidth="calc(100% - 107px)">
                        <SvgNamesAndFaces width="143" height="30"></SvgNamesAndFaces>
                    </Column>
                    <Column shrink={0} deviceWidths={['Desktop']}>
                        <Questions fontSize="14px" onClick={() => setQuestionsPanelOpen(true)}>
                            Questions?
                        </Questions>
                    </Column>
                    <Column shrink={0} deviceWidths={['Tablet', 'Phone', 'Small']} backgroundColor="white">
                        <Questions fontSize="12px" onClick={() => setQuestionsPanelOpen(true)}>
                            Questions?
                        </Questions>
                    </Column>
                </Row>
                <Row grow={0} shrink={0} height="44px" deviceWidths={['Desktop', 'Tablet']}></Row>
                <Row grow={0} shrink={0} height="25px" deviceWidths={['Phone', 'Small']}></Row>
                <Column grow={1} shrink={0}>
                    <Row grow={0} shrink={0} height="31px" deviceWidths={['Desktop', 'Tablet']}></Row>
                    <Row grow={0} shrink={0} height="35px" deviceWidths={['Phone', 'Small']}></Row>
                    <Row grow={0} shrink={0}>
                        <Heading1 fontSize="88px" deviceWidths={['Desktop']}>
                            {heading}
                        </Heading1>
                        <Heading1 fontSize="72px" deviceWidths={['Tablet']}>
                            {heading}
                        </Heading1>
                        <Heading1 fontSize="48px" deviceWidths={['Phone', 'Small']}>
                            {heading}
                        </Heading1>
                    </Row>
                    <Row grow={0} shrink={0} height="15px" deviceWidths={['Desktop', 'Tablet']}></Row>
                    <Row grow={0} shrink={0} height="10px" deviceWidths={['Phone', 'Small']}></Row>
                    {Array.isArray(subHeading) ? (
                        subHeading.map((sh, index) => (
                            <Row grow={0} shrink={0} key={index} padding="0 0 0.8em 0">
                                <Heading3 fontSize="20px" letterSpacing="-0.4px" deviceWidths={['Desktop', 'Tablet']}>
                                    {sh}
                                </Heading3>
                                <Heading3 fontSize="18px" letterSpacing="-0.2px" deviceWidths={['Phone', 'Small']}>
                                    {sh}
                                </Heading3>
                            </Row>
                        ))
                    ) : (
                        <Row grow={0} shrink={0}>
                            <Heading3 fontSize="20px" letterSpacing="-0.4px" deviceWidths={['Desktop', 'Tablet']}>
                                {subHeading}
                            </Heading3>
                            <Heading3 fontSize="18px" letterSpacing="-0.2px" deviceWidths={['Phone', 'Small']}>
                                {subHeading}
                            </Heading3>
                        </Row>
                    )}
                    <Row grow={0} shrink={0} height="30px"></Row>
                    <Row grow={0} shrink={0}>
                        {children}
                    </Row>
                    {(typeof progress === 'string' ? parseInt(progress) > 0 : !!progress) && (
                        <>
                            <Row grow={0} shrink={0} height="40px" deviceWidths={['Desktop', 'Tablet']}></Row>
                            <Row grow={0} shrink={0} height="20px" deviceWidths={['Phone', 'Small']}></Row>
                        </>
                    )}
                    <Row grow={1} shrink={1}></Row>
                </Column>
                {(typeof progress === 'string' ? parseInt(progress) > 0 : !!progress) && (
                    <Column position="sticky" bottom="0px" backgroundColor="white">
                        <Row grow={0} shrink={0} height="20px"></Row>
                        <Row grow={0} shrink={0}>
                            <ProgressBars progress={progress}></ProgressBars>
                        </Row>
                        <Row grow={0} shrink={0} height="20px"></Row>
                        <Row grow={0} shrink={0} deviceWidths={['Desktop', 'Tablet']}>
                            <Button
                                variant="tertiary"
                                style={{padding: '0px 36px', borderRadius: '5px', height: '44px'}}
                                onClick={() => onBack?.()}
                            >
                                Back
                            </Button>
                            <Button
                                style={{padding: '0px 36px', borderRadius: '5px', height: '44px'}}
                                disabled={!onNext}
                                loading={loading}
                                onClick={async () => {
                                    if (onNext) {
                                        setLoading(true)
                                        await onNext()
                                        setLoading(false)
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </Row>
                        <Row grow={0} shrink={0} deviceWidths={['Phone', 'Small']}>
                            <Button
                                variant="tertiary"
                                style={{padding: '0px 24px', borderRadius: '5px', height: '44px'}}
                                onClick={() => onBack?.()}
                            >
                                Back
                            </Button>
                            <Button
                                style={{padding: '0px 24px', borderRadius: '5px', height: '44px'}}
                                disabled={!onNext}
                                loading={loading}
                                onClick={async () => {
                                    if (onNext) {
                                        setLoading(true)
                                        await onNext()
                                        setLoading(false)
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </Row>
                        <Row grow={0} height="20px"></Row>
                    </Column>
                )}
            </Column>
            <Column width="160px" grow={0} shrink={0} deviceWidths={['Desktop']}></Column>
            <Column width="100px" grow={0} shrink={0} deviceWidths={['Tablet']}></Column>
            <Column width="25px" grow={0} shrink={0} deviceWidths={['Phone', 'Small']}></Column>
            {questionsPanelOpen ? (
                <Column position="fixed" top="0px" right="0px" bottom="0px" left="0px">
                    <QuestionsPanel
                        directoryName={directoryName}
                        onClose={() => setQuestionsPanelOpen(false)}
                    ></QuestionsPanel>
                </Column>
            ) : (
                <></>
            )}
        </Row>
    )
}

playground.push({
    path: 'src/components/CommunityBuild/Form.tsx',
    component: Form,
    props: {
        directoryName: 'Webpups Grade 3',
        heading: 'Heading',
        subHeading: 'Sub-heading',
        progress: 1,
        children: ['Form Content'],
    },
    propOptions: {
        formContent: {
            get: (props: any) => (props.children || ['Form Content'])[0],
            set: (props: any, value: string) => ({...props, children: [value]}),
        },
        heading: {
            get: (props: any) => props.heading,
            set: (props: any, value: string) => ({...props, heading: value}),
        },
        subHeading: {
            get: (props: any) => props.subHeading,
            set: (props: any, value: string) => ({...props, subHeading: value}),
        },
        progress: {
            get: (props: any) => props.progress,
            set: (props: any, value: string) => ({...props, progress: value}),
        },
        onNext: () => {},
        onBack: () => {},
    },
})
