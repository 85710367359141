/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdditionalFieldType {
    DATE = 'DATE',
    EMAIL = 'EMAIL',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    SELECT_MULTIPLE = 'SELECT_MULTIPLE',
    SELECT_ONE = 'SELECT_ONE',
    TEXT = 'TEXT',
    URL = 'URL',
}

export enum DataCollectionStatus {
    NOT_SENT = 'NOT_SENT',
    RESPONDED_ALL = 'RESPONDED_ALL',
    RESPONDED_NONE = 'RESPONDED_NONE',
    RESPONDED_SOME = 'RESPONDED_SOME',
}

export enum DataSourceFileType {
    CSV = 'CSV',
    XLS = 'XLS',
}

export enum DataSourceRequestType {
    ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY',
    ADP = 'ADP',
    AIRTABLE = 'AIRTABLE',
    BAMBOOHR = 'BAMBOOHR',
    GOOGLE_DRIVE = 'GOOGLE_DRIVE',
    GUSTO = 'GUSTO',
    HIBOB = 'HIBOB',
    KRONOS = 'KRONOS',
    MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
    NAMELY = 'NAMELY',
    ORACLE = 'ORACLE',
    OTHER = 'OTHER',
    PAYCHEX = 'PAYCHEX',
    PAYCOM = 'PAYCOM',
    PAYLOCITY = 'PAYLOCITY',
    RIPPLING = 'RIPPLING',
    SAGE_PEOPLE = 'SAGE_PEOPLE',
    SALESFORCE = 'SALESFORCE',
    SAP_SUCCESS_FACTORS = 'SAP_SUCCESS_FACTORS',
    SHAREPOINT = 'SHAREPOINT',
    SLACK = 'SLACK',
    TRINET = 'TRINET',
    ULTIPRO = 'ULTIPRO',
    WORKDAY = 'WORKDAY',
    ZENEFITS = 'ZENEFITS',
}

export enum DataSourceType {
    ADP = 'ADP',
    DIRECT_UPLOAD = 'DIRECT_UPLOAD',
    GOOGLE_SHEETS = 'GOOGLE_SHEETS',
    PAYCHEX = 'PAYCHEX',
    PAYLOCITY = 'PAYLOCITY',
    WORKDAY_REPORT = 'WORKDAY_REPORT',
}

export enum FieldType {
    CATEGORY = 'CATEGORY',
    DATE = 'DATE',
    EMAIL = 'EMAIL',
    FIRST_NAME = 'FIRST_NAME',
    LANDLINE_NUMBER = 'LANDLINE_NUMBER',
    LAST_NAME = 'LAST_NAME',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    MS_TEAMS = 'MS_TEAMS',
    SKYPE = 'SKYPE',
    SLACK = 'SLACK',
    TEXT = 'TEXT',
    URL = 'URL',
}

export enum HomeItemDisplayMode {
    GROUPED = 'GROUPED',
    LIST = 'LIST',
}

export enum HomeItemType {
    ALL = 'ALL',
    CATEGORY = 'CATEGORY',
    CHILD_CATEGORY = 'CHILD_CATEGORY',
}

export enum InviteStatus {
    ACTIVE = 'ACTIVE',
    INVITED = 'INVITED',
    PENDING = 'PENDING',
}

export enum MessageOption {
    EMAIL = 'EMAIL',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
}

/**
 * OAuth provider to do authorization flow for
 */
export enum OAuthProvider {
    GOOGLE = 'GOOGLE',
}

export enum ProfileLineType {
    CATEGORY = 'CATEGORY',
    DATE = 'DATE',
    EMAIL = 'EMAIL',
    FIRST_NAME = 'FIRST_NAME',
    LANDLINE_NUMBER = 'LANDLINE_NUMBER',
    LAST_NAME = 'LAST_NAME',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    MS_TEAMS = 'MS_TEAMS',
    SKYPE = 'SKYPE',
    SLACK = 'SLACK',
    TEXT = 'TEXT',
    URL = 'URL',
}

export enum RoleType {
    Manage = 'Manage',
    View = 'View',
}

export enum SignInMethodIcon {
    ADP = 'ADP',
    AZURE = 'AZURE',
    OKTA = 'OKTA',
}

export enum SignInMethodType {
    NF_AUTH_MAGIC_LINK = 'NF_AUTH_MAGIC_LINK',
    NF_AUTH_PASSWORD = 'NF_AUTH_PASSWORD',
    OAUTH = 'OAUTH',
    OTP = 'OTP',
    SET_PASSWORD = 'SET_PASSWORD',
    VERIFY_SIGN_IN = 'VERIFY_SIGN_IN',
}

export enum SystemAccessOption {
    LEGACY = 'LEGACY',
    SELF_BUILD = 'SELF_BUILD',
}

export enum TokenValidResult {
    Expired = 'Expired',
    Invalid = 'Invalid',
    Valid = 'Valid',
}

export enum UserRole {
    Admin = 'Admin',
    AdminGuest = 'AdminGuest',
    AdminUser = 'AdminUser',
    Guest = 'Guest',
    User = 'User',
}

export interface AdditionalFieldInput {
    idStr?: string | null
    name: string
    type: AdditionalFieldType
    helpText?: string | null
    options?: string[] | null
}

export interface AppFeaturesInput {
    orgChart?: boolean | null
    photoApproval?: boolean | null
    photoCollection?: boolean | null
    weeklyUserEmail?: boolean | null
    groupMessage?: boolean | null
    meetings?: boolean | null
    downloadPhotos?: boolean | null
    downloadData?: boolean | null
    profileThreshold?: boolean | null
    useRulesBasedImport?: boolean | null
    inAppData?: boolean | null
    communityBuild?: boolean | null
    masterDirectory?: boolean | null
    multiplePhotosPerProfile?: boolean | null
}

export interface BaseDirectorySpec {
    nameSuffix: string
    dataFields?: DataEditFieldSpec[] | null
    homeItems?: HomeItemSpec[] | null
    isAccessibleByOtherBaseDirectoriesInMaster?: boolean | null
    canAccessAllOtherBaseDirectoriesInMaster?: boolean | null
    appFeatures?: AppFeaturesInput | null
}

export interface CategoryPriorityInput {
    idStr: string
    priority: number
}

export interface ColumnMap {
    fieldOrCatIdStr: string
    column: string
}

export interface CropAndRotateInput {
    rotation: number
    profile?: CropRectangle | null
    thumb?: CropRectangle | null
}

export interface CropRectangle {
    left: number
    top: number
    width: number
    height: number
}

export interface DataCollectionFormField {
    idStr: string
    value?: string | null
    options?: string[] | null
}

export interface DataEditFieldSpec {
    name: string
    type: string
    subType?: string | null
    availableValues: string[]
    usersCanCreateValues?: boolean | null
}

export interface DataEditFieldUpsert {
    idStr?: string | null
    organisationIdStr: string
    name: string
    type: string
    subType?: string | null
    availableValues: string[]
    usersCanCreateValues?: boolean | null
}

export interface DataEditFieldValue {
    fieldOrParentCategoryIdStr: string
    value: string
    organisationIdStr?: string | null
}

export interface DataEditValueUpsert {
    fieldOrParentCategoryIdStr: string
    profileIdStr: string
    value: string
    organisationIdStr?: string | null
}

export interface DraftProfileInput {
    name: string
    value: string
}

export interface FieldRuleInput {
    fieldName: string
    fieldType: string
    isKeyField: boolean
    expression: string
}

export interface FormHomeItem {
    forCategoryName?: string | null
    label: string
}

export interface FormProfileLine {
    fieldNames: string[]
    prefix?: string | null
    separator?: string | null
    suffix?: string | null
    showLabel: boolean
    fieldStyle: string
    labelStyle?: string | null
    fieldBehaviour: string
    labelDescription?: string | null
}

export interface GoogleSheetsData {
    sheetId?: string | null
    refreshToken?: string | null
}

export interface HomeItemInput {
    idStr: string
    priority?: number | null
    label?: string | null
    visible?: boolean | null
}

export interface HomeItemSpec {
    forCategoryName?: string | null
    label: string
}

export interface ImportOptionsInput {
    processDataset: boolean
    processDirectory: boolean
    processUsers: boolean
    processPhotos: boolean
}

export interface IntegrationConfig {
    clientId?: string | null
    clientSecret?: string | null
    companyId?: string | null
    employeeUrl?: string | null
    photoUrl?: string | null
    username?: string | null
    password?: string | null
}

export interface OpenPositionField {
    fieldIdStr: string
    categoryIdStr?: string | null
    value?: string | null
}

export interface ProfileLineInput {
    idStr?: string | null
    priority?: number | null
    fieldOrCategoryIdStrs?: string[] | null
    showField?: boolean | null
    showLabel?: boolean | null
    userCanEdit?: boolean | null
    userCanHide?: boolean | null
    sectionLineBelow?: boolean | null
    fieldStyle?: string | null
    labelStyle?: string | null
    labelDescription?: string | null
    fieldBehaviour?: string | null
    prefix?: string | null
    separator?: string | null
    suffix?: string | null
    hideYear?: boolean | null
    organisationIdStr?: string | null
}

export interface ProfileLineSpec {
    fieldNames: string[]
    prefix?: string | null
    separator?: string | null
    suffix?: string | null
    showLabel: boolean
    fieldStyle: string
    labelStyle?: string | null
    fieldBehaviour: string
}

export interface RegionInput {
    left: number
    top: number
    width: number
    height: number
}

export interface SelectedColumn {
    columnName: string
    fieldName: string
    type?: string | null
}

export interface UpdateImportConfigInput {
    fieldRules?: FieldRuleInput[] | null
    filterRules?: string[] | null
}

export interface UserEventAttributes {
    redirectLink?: string | null
    name?: string | null
    device_type?: string | null
    button?: string | null
    organisationId?: string | null
}

export interface UserNPSInput {
    score?: number | null
    primaryUsage?: string | null
    additionalFeedback?: string | null
    requestCallback?: boolean | null
}

export interface UserRestrictionsInput {
    canEditProfilePicture?: boolean | null
    canEditAdditionalFields?: boolean | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
