import {omit} from 'lodash'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import styled from 'styled-components'
import {HideableCss, scrollIfNeeded} from '../Primitives'
import {colors} from 'nf-ui'

export const Inter = styled.span<{fontSize?: string; fontWeight?: string}>`
    font-family: 'inter';
    font-size: ${({fontSize}) => fontSize || '14px'};
    ${({fontWeight}) => (fontWeight ? `font-weight: ${fontWeight};` : '')}
`

export type StyledInputProps = {
    maxWidth?: string
    textAlign?: string
    padding?: string
    hasError?: boolean
    variant?: 'primary' | 'heading' | 'heading-grey' | 'legacy' | 'textarea'
    hasValue?: boolean
    color?: string
    backgroundColor?: string
}

export const StyledInput = styled.input<StyledInputProps>`
    height: ${({height}) => (height ? height : '20px')};
    width: ${({width}) => (width ? width : 'calc(100% - 30px)')};
    max-width: ${({maxWidth}) => (maxWidth ? maxWidth : '422px')};
    ${({textAlign}) => textAlign && `text-align: ${textAlign};`}
    padding: ${({padding}) => padding || '14px'};
    font-family: 'inter';
    font-size: 16px;
    outline: none;
    border-radius: 5px;
    ${({variant, hasError, padding, color, backgroundColor}) =>
        variant === 'legacy'
            ? `
                font-family: 'halyard-display';
                font-weight: 300;
                box-sizing: border-box;
                transition: 0.2s border-color ease-in-out;
                background-color: ${backgroundColor || 'white'};
                border: ${hasError ? '1px solid #ff0000' : `1px solid ${colors.darkGray}`};
                padding: 0 16px;
                height: 40px;
                width: 100%;
                :not(:disabled) {
                    color: ${color || colors.black};
                }
                :disabled {
                    color: ${colors.darkGray};
                }
                :not(:disabled):hover, :not(:disabled):focus {
                    border-color: ${colors.primary[100]}
                }`
            : variant === 'heading'
            ? `
                font-weight: 600;
                color: ${color || '#3C8EFF'};
                background-color: ${backgroundColor || 'rgba(60, 142, 255, 0.1)'};
                border: none;
                :disabled {
                    opacity: 1;
                }`
            : variant === 'heading-grey'
            ? `
                font-weight: 600;
                color: ${color || 'rgb(135,135,135)'};
                background-color: ${backgroundColor || 'rgba(255,255,255)'};
                border: none;
                :disabled {
                    opacity: 1;
                }`
            : `
                color: ${color || 'black'};
                background-color: ${backgroundColor || '#ebebeb'};
                border: ${hasError ? '1px solid #ff0000' : 'none'};
                :focus {
                    border: 1px solid ${hasError ? '#ff0000' : '#3c8eff'};
                    padding: ${padding?.split(' ')?.map((p) => `calc(${p} - 1px)`) || '13px'};
                }`}
    ${HideableCss}
`

export type StyledTextAreaProps = {
    width?: string
    height?: string
    maxWidth?: string
    textAlign?: string
    padding?: string
    hasError?: boolean
    hasValue?: boolean
    color?: string
    backgroundColor?: string
}

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
    height: ${({height}) => (height ? height : '20px')};
    width: ${({width}) => (width ? width : 'calc(100% - 30px)')};
    max-width: ${({maxWidth}) => (maxWidth ? maxWidth : '422px')};
    ${({textAlign}) => textAlign && `text-align: ${textAlign};`}
    padding: ${({padding}) => padding || '14px'};
    font-family: 'inter';
    font-size: 16px;
    outline: none;
    border-radius: 5px;
    color: ${({color}) => color || 'black'};
    background-color: ${({backgroundColor}) => backgroundColor || '#ebebeb'};
    border: ${({hasError}) => (hasError ? '1px solid #ff0000' : 'none')};
    ${({hasError, padding}) => `
        :focus {
            border: 1px solid ${hasError ? '#ff0000' : '#3c8eff'};
            padding: ${padding?.split(' ')?.map((p) => `calc(${p} - 1px)`) || '13px'};
        }`}
    ${HideableCss}
`

export type VisibleInputProps = StyledInputProps & {
    type: string
    inputMode?: 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined
    pattern?: string
    width?: string
    height?: string
    placeholder?: string
    value?: string
    readOnly?: boolean
    disabled?: boolean
    scrollMarginBottom?: number
    scrollMarginTop?: number
    onChange?: (event: React.ChangeEvent<TextInputElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<TextInputElement>) => void
    onKeyPress?: (event: React.KeyboardEvent<TextInputElement>) => void
    onKeyUp?: (event: React.KeyboardEvent<TextInputElement>) => void
    onClick?: (event: React.MouseEvent<TextInputElement>) => void
    onHasFocusChange?: (hasFocus: boolean) => void
    onScrollIntoViewBegin?: () => void
    onScrollIntoViewComplete?: () => void
}

export type TextInputElement = HTMLInputElement & HTMLTextAreaElement

export const VisibleInput = forwardRef<TextInputElement, VisibleInputProps>((props: VisibleInputProps, ref) => {
    const innerRef = useRef<TextInputElement>(null)
    useImperativeHandle<TextInputElement | null, TextInputElement | null>(ref, () => innerRef.current)
    const [hasFocus, setHasFocus] = useState<boolean>(false)
    useEffect(() => {
        if (hasFocus) {
            scrollIfNeeded(innerRef.current, {
                marginTop: props.scrollMarginTop,
                marginBottom: props.scrollMarginBottom,
                align: 'bottom',
                onScrollBegin: () => props.onScrollIntoViewBegin?.(),
                onScrollComplete: () => props.onScrollIntoViewComplete?.(),
            })
        }
        props.onHasFocusChange?.(hasFocus)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFocus])
    return props.variant === 'textarea' ? (
        <StyledTextArea
            {...omit(props, ['onHasFocusChange', 'onScrollIntoViewBegin', 'onScrollIntoViewComplete'])}
            ref={innerRef}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
        ></StyledTextArea>
    ) : (
        <StyledInput
            {...omit(props, ['onHasFocusChange', 'onScrollIntoViewBegin', 'onScrollIntoViewComplete'])}
            ref={innerRef}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
        ></StyledInput>
    )
})
