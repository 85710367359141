import React, {useEffect, useState} from 'react'
import {StringParam, useQueryParams} from 'use-query-params'
import {Column, Row} from '~/components/Primitives'

export const playground: {
    path: string
    component: any
    props: any
    propOptions: any
    grow?: number
}[] = []

export const RenderByIndex = (index: number, props: any) => {
    const item = playground[index] as any
    return <item.component {...props}></item.component>
}

const Control = ({
    options,
    prop,
    props,
    index,
    setProps,
}: {
    options: any
    prop: string
    props: Record<string, any>
    index: number
    setProps: (value: any) => void
}) => (
    <>
        {Array.isArray(options) ? (
            options.map((option: string) => (
                <span key={`${prop}-${option}`}>
                    <input
                        type="radio"
                        id={`${prop}-${option}`}
                        name={`${prop}`}
                        value={`${option}`}
                        checked={props[prop] === option}
                        onChange={() => {
                            playground[index].props = {
                                ...props,
                                ...Object.fromEntries([[prop, option]]),
                            }
                            setProps(playground[index].props)
                        }}
                    ></input>
                    <label htmlFor={`${prop}-${option}`} style={{cursor: 'pointer'}}>{`${option}`}</label>
                    <br />
                </span>
            ))
        ) : typeof options === 'function' ? (
            playground[index].props[prop] || 0
        ) : typeof options === 'object' && typeof options.get === 'function' && typeof options.set === 'function' ? (
            <input
                type="text"
                onChange={(event) => {
                    playground[index].props = options.set(props, event.target.value)
                    setProps(playground[index].props)
                }}
                value={options.get(props)}
            ></input>
        ) : typeof options === 'object' && typeof options.get === 'function' ? (
            options.get(props)
        ) : (
            ''
        )}
    </>
)

const Dock = ({
    panelSide,
    setPanelSide,
}: {
    panelSide: 'top' | 'right' | 'bottom' | 'left'
    setPanelSide: (value: 'top' | 'right' | 'bottom' | 'left') => void
}) => (
    <>
        <span>
            <input
                type="radio"
                name="dock"
                id="dock-top"
                checked={panelSide === 'top'}
                onChange={() => setPanelSide('top')}
            ></input>
            <label htmlFor="dock-top" style={{cursor: 'pointer'}}>
                top
            </label>
        </span>
        <br />
        <span>
            <input
                type="radio"
                name="dock"
                id="dock-right"
                checked={panelSide === 'right'}
                onChange={() => setPanelSide('right')}
            ></input>
            <label htmlFor="dock-right" style={{cursor: 'pointer'}}>
                right
            </label>
        </span>
        <br />
        <span>
            <input
                type="radio"
                name="dock"
                id="dock-bottom"
                checked={panelSide === 'bottom'}
                onChange={() => setPanelSide('bottom')}
            ></input>
            <label htmlFor="dock-bottom" style={{cursor: 'pointer'}}>
                bottom
            </label>
        </span>
        <br />
        <span>
            <input
                type="radio"
                name="dock"
                id="dock-left"
                checked={panelSide === 'left'}
                onChange={() => setPanelSide('left')}
            ></input>
            <label htmlFor="dock-left" style={{cursor: 'pointer'}}>
                left
            </label>
        </span>
    </>
)

const Controls = ({
    index,
    setIndex,
    props,
    setProps,
    collapsed,
    setCollapsed,
    panelSide,
    setPanelSide,
}: {
    index: number
    setIndex: (value: number) => void
    props: any
    setProps: (value: any) => void
    collapsed: boolean
    setCollapsed: (value: boolean) => void
    panelSide: 'top' | 'right' | 'bottom' | 'left'
    setPanelSide: (value: 'top' | 'right' | 'bottom' | 'left') => void
}) => {
    return (
        <Column>
            {collapsed ? (
                <b style={{margin: 'auto', cursor: 'pointer', fontSize: 30}} onClick={() => setCollapsed(false)}>
                    =
                </b>
            ) : (
                <b style={{margin: '10px auto', cursor: 'pointer'}} onClick={() => setCollapsed(true)}>
                    Component Playground
                </b>
            )}
            <select
                onChange={(event) => {
                    const index = playground.map((item) => item.path).indexOf(event.target.value)
                    setProps(playground[index].props)
                    setIndex(index)
                }}
                style={{
                    margin: '10px auto',
                    maxWidth: panelSide === 'right' || panelSide === 'left' ? '255px' : undefined,
                    display: collapsed ? 'none' : undefined,
                }}
                value={playground[index].path}
            >
                {playground.map((item) => (
                    <option key={item.path}>{item.path}</option>
                ))}
            </select>

            {panelSide === 'right' || panelSide === 'left' ? (
                <Column
                    style={{
                        margin: '10px auto',
                        display: collapsed ? 'none' : undefined,
                    }}
                >
                    {Object.keys(playground[index].propOptions).map((prop) => {
                        const options = playground[index].propOptions[prop]
                        return (
                            <Row key={prop}>
                                <div style={{padding: '2px 10px', fontSize: '14px', fontWeight: 600}}>{prop}</div>
                                <div style={{padding: '2px 10px', fontSize: '14px', width: 200}}>
                                    <Control
                                        options={options}
                                        prop={prop}
                                        props={props}
                                        index={index}
                                        setProps={setProps}
                                    />
                                </div>
                            </Row>
                        )
                    })}
                    <Row>
                        <div style={{padding: '2px 10px', fontSize: '14px', fontWeight: 600}}>dock</div>
                        <div style={{padding: '2px 10px', fontSize: '14px', width: 200}}>
                            <Dock panelSide={panelSide} setPanelSide={setPanelSide} />
                        </div>
                    </Row>
                </Column>
            ) : (
                <Row
                    style={{
                        margin: 'auto 10px',
                        display: collapsed ? 'none' : undefined,
                    }}
                    wrapLine
                >
                    {Object.keys(playground[index].propOptions).map((prop) => {
                        const options = playground[index].propOptions[prop]
                        return (
                            <Column key={prop}>
                                <div style={{padding: '2px 2px', fontSize: '14px', fontWeight: 600}}>{prop}</div>
                                <div style={{padding: '2px 2px', fontSize: '14px', minWidth: 100}}>
                                    <Control
                                        options={options}
                                        prop={prop}
                                        props={props}
                                        index={index}
                                        setProps={setProps}
                                    />
                                </div>
                            </Column>
                        )
                    })}
                    <Column>
                        <div style={{padding: '2px 2px', fontSize: '14px', fontWeight: 600}}>dock</div>
                        <div style={{padding: '2px 2px', fontSize: '14px', minWidth: 100}}>
                            <Dock panelSide={panelSide} setPanelSide={setPanelSide} />
                        </div>
                    </Column>
                </Row>
            )}
        </Column>
    )
}

export const Playground = () => {
    const [query, setQuery] = useQueryParams({component: StringParam, dock: StringParam})
    const [index, setIndex] = useState<number>(
        Math.max(0, playground.map((item) => item.path).indexOf(query.component || '')),
    )
    const [props, setProps] = useState(playground[index].props)
    const [collapsed, setCollapsed] = useState(true)
    const [panelSide, setPanelSide] = useState((query.dock || 'top') as 'top' | 'right' | 'bottom' | 'left')
    useEffect(() => {
        setQuery({component: playground[index].path})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index])
    useEffect(() => {
        setQuery({dock: panelSide})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelSide])
    const handlerProps = () =>
        Object.fromEntries(
            Object.keys(playground[index].propOptions)
                .filter((prop) => typeof playground[index].propOptions[prop] === 'function')
                .map((prop) => [
                    prop,
                    (...args: any[]) => {
                        const newProps = playground[index].propOptions[prop]({props: playground[index].props, args})
                        if (typeof newProps === 'object') {
                            playground[index].props =
                                typeof newProps[prop] === 'string'
                                    ? newProps
                                    : {
                                          ...newProps,
                                          ...Object.fromEntries([[prop, (playground[index].props[prop] || 0) + 1]]),
                                      }
                            setProps(playground[index].props)
                        } else {
                            playground[index].props = {
                                ...playground[index].props,
                                ...Object.fromEntries([[prop, (playground[index].props[prop] || 0) + 1]]),
                            }
                            setProps(playground[index].props)
                        }
                    },
                ]),
        )
    const grow = typeof playground[index].grow === 'undefined' ? 1 : playground[index].grow || 0
    return collapsed ? (
        <>
            <Row position="fixed" bottom="0px" right="0px" style={{zIndex: 1000}}>
                <Controls
                    index={index}
                    setIndex={setIndex}
                    props={props}
                    setProps={setProps}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    panelSide={panelSide}
                    setPanelSide={setPanelSide}
                ></Controls>
            </Row>
            {RenderByIndex(index, {...props, ...handlerProps()})}
        </>
    ) : panelSide === 'right' ? (
        <Row height={grow ? '100%' : undefined} width="100%">
            <Column grow={grow} shrink={grow}>
                {RenderByIndex(index, {...props, ...handlerProps()})}
            </Column>
            <Column grow={grow === 0 ? 1 : 0} width="20px" />
            <Column
                grow={0}
                style={{
                    zIndex: 1000000,
                    backgroundColor: '#f8f8f8',
                    padding: '10px',
                    fontFamily: 'halyard-display',
                    overflow: 'auto',
                }}
            >
                <Controls
                    index={index}
                    setIndex={setIndex}
                    props={props}
                    setProps={setProps}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    panelSide={panelSide}
                    setPanelSide={setPanelSide}
                ></Controls>
            </Column>
        </Row>
    ) : panelSide === 'left' ? (
        <Row height={grow ? '100%' : undefined}>
            <Column
                grow={0}
                style={{
                    zIndex: 1000000,
                    backgroundColor: '#f8f8f8',
                    padding: '10px',
                    fontFamily: 'halyard-display',
                    overflow: 'auto',
                }}
            >
                <Controls
                    index={index}
                    setIndex={setIndex}
                    props={props}
                    setProps={setProps}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    panelSide={panelSide}
                    setPanelSide={setPanelSide}
                ></Controls>
            </Column>
            <Column width="20px"></Column>
            <Column grow={grow} shrink={grow}>
                {RenderByIndex(index, {...props, ...handlerProps()})}
            </Column>
            {grow === 0 && <Column grow={1}></Column>}
        </Row>
    ) : panelSide === 'top' ? (
        <Column height={grow ? '100%' : undefined}>
            <Row
                style={{
                    zIndex: 1000000,
                    backgroundColor: '#f8f8f8',
                    padding: '10px',
                    fontFamily: 'halyard-display',
                    overflow: 'auto',
                }}
                centerHorizontal
            >
                <Controls
                    index={index}
                    setIndex={setIndex}
                    props={props}
                    setProps={setProps}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    panelSide={panelSide}
                    setPanelSide={setPanelSide}
                ></Controls>
            </Row>
            <Row grow={grow} shrink={grow} height="calc(100% - 233px)">
                {RenderByIndex(index, {...props, ...handlerProps()})}
            </Row>
        </Column>
    ) : (
        <Column height="100%">
            <Row grow={grow} shrink={grow} height="calc(100% - 233px)">
                {RenderByIndex(index, {...props, ...handlerProps()})}
            </Row>
            <Row grow={grow ? 0 : 1} height="0px"></Row>
            <Row
                style={{
                    zIndex: 1000000,
                    backgroundColor: '#f8f8f8',
                    padding: '10px',
                    fontFamily: 'halyard-display',
                    overflow: 'auto',
                }}
                centerHorizontal
            >
                <Controls
                    index={index}
                    setIndex={setIndex}
                    props={props}
                    setProps={setProps}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    panelSide={panelSide}
                    setPanelSide={setPanelSide}
                ></Controls>
            </Row>
        </Column>
    )
}
