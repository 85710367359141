import {Button, Form, Typography, colors, useAnalyticsEventTracker} from 'nf-ui'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {ModalLayout} from '~/components/ModalLayout'
import {EditPrimaryDataLightbox} from './EditPrimaryDataLightbox'
import {OpenPositionFields} from './OpenPositionFields'
import {HiddenText, PrimaryFieldsPanel} from './PrimaryFields'
import {EditProfileData_profile, EditProfileData_profile_primaryFields} from './__types__/EditProfileData'
import {
    DataEditField,
    DataEditValue,
    FieldSubType,
    FieldType,
    convertDataEditValuesByProfileIdStr,
    useOrganisationContext,
} from '~/components/OrganisationContext'
import {useMutationDeleteProfile, useQueryDataEditHiddenValuesByProfile} from '~/apollo/queries/DataEditValue'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {ErrorRows} from '~/components/CommunityBuild/Primitives'
import {useHiddenFields} from './useHiddenFields'
import {VisibleInput} from '~/components/InputFields/Primitives'
import {SelectInput} from '~/components/InputFields/SelectInput'
import {parseDate, toStorage} from '~/util/date'
import {DatePicker} from '~/components/InputFields/DatePicker'
import {Column, Row} from '~/components/Primitives'
import styled from 'styled-components'
import {DataEditGetValues_dataEditValues} from '~/apollo/queries/__types__/DataEditGetValues'
import {FieldState, useUpdateProfile, validateField} from './useUpdateProfile'
import {useSignout} from '~/components/useSignout'

export type PrimaryFields = EditProfileData_profile['primaryFields']

const ActionBanner = styled.div`
    position: fixed;
    bottom: 0;
    left: 0px;
    right: 16px;
    height: 72px;
    background: ${colors.primary[100]};
`

const Field = ({
    field,
    fieldState,
    setFieldState,
    validate,
    hideable,
    isHidden,
    toggleIsHidden,
    togglingIsHidden,
}: {
    field: {
        dataEditField: DataEditField & {masterField: DataEditField}
        dataEditValues: DataEditValue[]
        field?: EditProfileData_profile_primaryFields
    }
    fieldState?: FieldState
    setFieldState: (state: FieldState) => void
    validate: (dataEditField: DataEditField, values: string[]) => string
    hideable: boolean
    isHidden: boolean
    toggleIsHidden: () => void
    togglingIsHidden: boolean
}) => {
    const rows: number = useMemo(
        () =>
            field.dataEditField?.type === 'text'
                ? field.dataEditField?.subType
                      ?.split(',')
                      ?.filter((pair) => pair.split('=')[0] === 'rows')
                      ?.map((pair) => parseInt(pair.split('=')[1]))[0] || 1
                : 1,
        [field.dataEditField?.subType, field.dataEditField?.type],
    )
    const values = fieldState?.values || field.dataEditValues?.map((value) => value.value || '') || []

    return (
        <>
            <Row>
                <Column>
                    <Row grow={1}></Row>
                    <Typography.Label>
                        <HiddenText color={hideable && isHidden ? colors.darkGray : colors.black}>
                            {field.field?.label || field.dataEditField?.name}
                        </HiddenText>
                        <HiddenText style={{opacity: hideable && isHidden ? 1 : 0}}>
                            {hideable && isHidden ? ` (Hidden on profile)` : ''}
                        </HiddenText>
                    </Typography.Label>
                    <Row height="4px"></Row>
                </Column>
                {hideable && (
                    <Column>
                        <Row grow={1}></Row>
                        <Form.Switch
                            checked={!isHidden}
                            onChange={() => toggleIsHidden()}
                            disabled={togglingIsHidden}
                        />
                        <Row height="7px"></Row>
                    </Column>
                )}
            </Row>
            <Row height="2px"></Row>
            <Row>
                {field.dataEditField?.type === 'date' ? (
                    <DatePicker
                        width="275px"
                        value={parseDate(values[0] || '') || undefined}
                        placeholder=""
                        onChange={(value) => {
                            const dateString = value && toStorage(value)
                            setFieldState({
                                values: [dateString || ''],
                                original: fieldState?.original || [''],
                                error: validate(field.dataEditField!, [dateString || '']),
                                baseFieldIdStr: field.dataEditField!.idStr,
                            })
                        }}
                        onDoneEditing={() => {}}
                    ></DatePicker>
                ) : field.dataEditField?.type === 'category' ? (
                    <SelectInput
                        width="275px"
                        availableValues={field.dataEditField?.availableValues}
                        defaultValues={values}
                        freeText={field.dataEditField.subType?.split(';')?.includes('freeText')}
                        multipleSelection={field.dataEditField.subType?.split(';')?.includes('multipleValues')}
                        availableValuesOrdered={!field.dataEditField.subType?.split(';')?.includes('orderCustom')}
                        placeholder=""
                        renderTextValue={(values) => values.join(', ')}
                        parseTextValue={(text) => text.split(',').map((value) => value.trim())}
                        onChange={(values) =>
                            setFieldState({
                                values: values,
                                original: fieldState?.original || [],
                                error: validate(field.dataEditField!, values),
                                baseFieldIdStr: field.dataEditField!.idStr,
                            })
                        }
                    ></SelectInput>
                ) : (
                    <VisibleInput
                        width="275px"
                        height={`${(rows - 1) * 70 + 20}px`}
                        variant={rows > 1 ? 'textarea' : undefined}
                        type="text"
                        value={values[0]}
                        onChange={(event) =>
                            setFieldState({
                                values: [event.currentTarget.value],
                                original: fieldState?.original || [],
                                error: validate(field.dataEditField!, [event.currentTarget.value]),
                                baseFieldIdStr: field.dataEditField!.idStr,
                            })
                        }
                    ></VisibleInput>
                )}
            </Row>
            <ErrorRows
                errorRecord={{
                    message: fieldState?.error || '',
                    visible: !!fieldState?.error,
                }}
            ></ErrorRows>
            <Row height="10px"></Row>
        </>
    )
}

const InAppDataPrimaryFieldsTab = ({
    primaryFields,
    profile,
    organisationIdStr,
    onDoneEditing,
}: {
    primaryFields: PrimaryFields
    profile: EditProfileData_profile
    organisationIdStr: string
    onDoneEditing: () => void
}) => {
    const [visibleValues, setVisibleValues] = useOrganisationContext.useDataEditValues()
    const [visibleValuesIndexed, setVisibleValuesIndexed] = useOrganisationContext.useDataEditValuesByProfileIdStr()
    const [hiddenValues, setHiddenValues] = useOrganisationContext.useHiddenValues()
    const [hiddenValuesIndexed, setHiddenValuesIndexed] = useOrganisationContext.useHiddenValuesByProfileIdStr()
    const hiddenValuesQuery = useQueryDataEditHiddenValuesByProfile(organisationIdStr, profile.idStr)
    const [dataEditFields] = useOrganisationContext.useDataEditFields()
    const [deleting, setDeleting] = useState(false)
    const bottomRef = useRef<HTMLDivElement>(null)
    const hiddenFields = useHiddenFields()
    const [updateProfileMutation, updateProfile] = useUpdateProfile(organisationIdStr, onDoneEditing)
    const [deleteProfileMutation, deleteProfile] = useMutationDeleteProfile()
    const {signOut} = useSignout()

    useEffect(() => {
        deleting && bottomRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [deleting])

    useEffect(() => {
        setHiddenValues(hiddenValuesQuery.data?.dataEditValues || [])
        setHiddenValuesIndexed(convertDataEditValuesByProfileIdStr(hiddenValuesQuery.data?.dataEditValues || []))
        return () => {
            setHiddenValues([])
            setHiddenValuesIndexed({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hiddenValuesQuery])

    const _fields = useMemo(
        () =>
            dataEditFields
                .map(
                    (_field) =>
                        (_field.baseFields || [])
                            ?.filter((baseField) => baseField.organisationIdStr === profile?.baseDirectoryIdStr)
                            ?.map((field) => ({
                                ...field,
                                type: field.type as FieldType,
                                subType: field.subType as FieldSubType,
                                masterField: _field,
                                baseFields: null,
                            }))[0] || {
                            ..._field,
                            baseFields: null,
                            masterField: _field,
                        },
                )
                .map((_field) => ({
                    dataEditField: _field,
                    dataEditValues: [
                        ...(visibleValuesIndexed[profile.idStr] || []).filter(
                            (value) => value.fieldOrParentCategoryIdStr === _field.masterField.idStr,
                        ),
                        ...(hiddenValuesIndexed?.[profile.idStr] || []).filter(
                            (value) => value.fieldOrParentCategoryIdStr === _field.masterField.idStr,
                        ),
                    ],
                }))
                .filter((_field) => _field.dataEditValues.length)
                .map((_field) => ({
                    dataEditField: _field.dataEditField,
                    dataEditValues: _field.dataEditValues,
                    field: primaryFields.find((field) => field.extraData?.fieldIdStr === _field.dataEditField.idStr),
                })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataEditFields, visibleValuesIndexed, hiddenValuesIndexed, primaryFields],
    )

    const [fieldState, setFieldState] = useState<Record<string, FieldState>>(
        _fields.reduce((prev, field) => {
            const values =
                field.dataEditField.type === 'date'
                    ? field.dataEditValues.map((value) => toStorage(parseDate(value.value || '')) || '')
                    : field.dataEditField.type === 'category'
                    ? field.dataEditField.availableValues.filter((value) =>
                          field.dataEditValues.some((v) => v.value === value),
                      )
                    : field.dataEditValues.map((value) => value.value || '')

            return {
                ...prev,
                ...Object.fromEntries([
                    [
                        field.dataEditField.masterField.idStr,
                        {
                            values,
                            error: validateField(field.dataEditField, values),
                            original: values,
                            baseFieldIdStr: field.dataEditField.idStr,
                        },
                    ],
                ]),
            }
        }, {}),
    )

    return (
        <Column>
            {_fields.map((_field, index) => (
                <Field
                    key={index}
                    field={_field}
                    fieldState={fieldState[_field.dataEditField.masterField.idStr]}
                    setFieldState={(newState) => {
                        setFieldState({
                            ...fieldState,
                            ...Object.fromEntries([[_field.dataEditField.masterField.idStr, newState]]),
                        })
                    }}
                    validate={validateField}
                    hideable={!!_field.field?.extraData?.hideable}
                    isHidden={!!_field.field && hiddenFields.isHidden(_field.field)}
                    toggleIsHidden={async () => {
                        if (_field.field) {
                            const valueIsMatch = (value: DataEditGetValues_dataEditValues) =>
                                value.profileIdStr === profile.idStr &&
                                value.fieldOrParentCategoryIdStr === _field.dataEditField.masterField.idStr
                            const isHidden = hiddenFields.isHidden(_field.field)
                            await hiddenFields.toggleVisibility(_field.field)
                            const affectedValue = (isHidden ? hiddenValues : visibleValues).find(valueIsMatch)
                            const newHiddenValues = isHidden
                                ? hiddenValues.filter((value) => !valueIsMatch(value))
                                : affectedValue
                                ? [...hiddenValues, affectedValue]
                                : hiddenValues
                            const newVisibleValues = !isHidden
                                ? visibleValues.filter((value) => !valueIsMatch(value))
                                : affectedValue
                                ? [...visibleValues, affectedValue]
                                : visibleValues
                            setVisibleValues(newVisibleValues)
                            setVisibleValuesIndexed(convertDataEditValuesByProfileIdStr(newVisibleValues))
                            setHiddenValues(newHiddenValues)
                            setHiddenValuesIndexed(convertDataEditValuesByProfileIdStr(newHiddenValues))
                        }
                    }}
                    togglingIsHidden={hiddenFields.loading}
                />
            ))}
            <Row height="22px"></Row>
            {deleting ? (
                <>
                    <Row>
                        <Typography.Paragraph>
                            Proceeding will delete this profile from this Names & Faces directory. If this is your only
                            profile you will no longer have access to Names & Faces.
                        </Typography.Paragraph>
                    </Row>
                    <Row ref={bottomRef}>
                        <Column grow={1}></Column>
                        <Button variant="secondary" onClick={() => setDeleting(false)}>
                            Cancel
                        </Button>
                        <Column width="10px"></Column>
                        <Button
                            variant="red"
                            onClick={async () => {
                                const result = await deleteProfile({profileIdStr: profile.idStr})
                                if (!result) return
                                if (!result.remainingUserOrganisations?.length) {
                                    await signOut()
                                } else if (result.remainingUserOrganisations.includes(organisationIdStr)) {
                                    window.location.href = `/${organisationIdStr}`
                                } else {
                                    window.location.href = `/`
                                }
                            }}
                            loading={deleteProfileMutation[1].loading}
                        >
                            Delete
                        </Button>
                    </Row>
                </>
            ) : (
                <Row>
                    <Button variant="red" onClick={() => setDeleting(true)}>
                        Delete profile
                    </Button>
                </Row>
            )}
            <Row height="32px"></Row>
            {Object.values(fieldState).some(
                (state) => state.values.sort().join(';') !== state.original.sort().join(';'),
            ) && (
                <>
                    <Row height="72px"></Row>
                    <ActionBanner>
                        <Row padding="16px 32px">
                            <Column grow={1}></Column>
                            <Column centerVertical>
                                <Typography.Label>
                                    <span style={{color: 'white'}}>Save changes?</span>
                                </Typography.Label>
                            </Column>
                            <Column width="32px"></Column>
                            <Button
                                variant="secondary"
                                disabled={Object.values(fieldState).some((state) => state.error)}
                                onClick={() => updateProfile(_fields, fieldState, setFieldState)}
                                loading={updateProfileMutation[1].loading}
                            >
                                Save
                            </Button>
                        </Row>
                    </ActionBanner>
                </>
            )}
        </Column>
    )
}

export const PrimaryFieldsTab = ({
    primaryFields,
    profile,
    onDoneEditing,
}: {
    primaryFields: PrimaryFields
    profile: EditProfileData_profile
    onDoneEditing: () => void
}) => {
    const [primaryDataLightboxOpen, setPrimaryDataLightboxOpen] = useState(false)
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {currentOrganisation} = useCurrentOrganisation()

    if (currentOrganisation?.appFeatures?.inAppData) {
        return (
            <InAppDataPrimaryFieldsTab
                primaryFields={primaryFields}
                profile={profile}
                organisationIdStr={currentOrganisation!.idStr}
                onDoneEditing={onDoneEditing}
            ></InAppDataPrimaryFieldsTab>
        )
    }

    if (profile.openPosition)
        return <OpenPositionFields fields={primaryFields} openPositionJobLink={profile.openPositionJobLink} />

    return (
        <>
            <PrimaryFieldsPanel
                fields={primaryFields}
                additionalProfile={profile.additional}
                onFieldClick={(field) => {
                    trackAnalyticsEvent('click_disabled_primary_field', {
                        fieldLabel: field.label,
                        fieldType: field.type,
                    })
                    setPrimaryDataLightboxOpen(true)
                }}
            />
            <EditPrimaryDataLightbox
                open={primaryDataLightboxOpen}
                onClose={() => setPrimaryDataLightboxOpen(false)}
                additionalProfile={profile.additional}
            />
            <ModalLayout.Footer />
        </>
    )
}
