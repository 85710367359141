import {gql, useQuery} from '@apollo/client'
import {SidebarLayout, Typography} from 'nf-ui'
import {Accordion, AccordionData} from 'nf-ui/Accordion'
import SvgDataBold from 'nf-ui/Icons/DataBold'
import SvgDataRegular from 'nf-ui/Icons/DataRegular'
import SvgDesignBold from 'nf-ui/Icons/DesignBold'
import SvgDesignRegular from 'nf-ui/Icons/DesignRegular'
import SvgHomeBold from 'nf-ui/Icons/HomeBold'
import SvgHomeRegular from 'nf-ui/Icons/HomeRegular'
import SvgPhotosBold from 'nf-ui/Icons/PhotosBold'
import SvgPhotosRegular from 'nf-ui/Icons/PhotosRegular'
import SvgScenariosBold from 'nf-ui/Icons/ScenariosBold'
import SvgScenariosRegular from 'nf-ui/Icons/ScenariosRegular'
import SvgUsersBold from 'nf-ui/Icons/UsersBold'
import SvgUsersRegular from 'nf-ui/Icons/UsersRegular'
import React, {FC, useState} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router'
import {matchPath} from 'react-router-dom'
import {useQueryParam} from 'use-query-params'
import {PhotoApprovalSettingLightbox} from '~/components/ApprovalSettingLightbox'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {OrganisationSwitcher} from '~/components/OrganisationSwitcher'
import {PageLoading} from '~/components/PageLoading'
import {RenameOrganisationLightbox} from '~/components/RenameOrganisationLightbox'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {RoleType} from '~/globalTypes'
import {Integrations} from '~/pages/Adminland/Integrations'
import {Photos} from '~/pages/Adminland/Photos'
import {NotFound} from '~/pages/NotFound'
import {getGraphQLErrorMessage} from '~/util'
import {CreateAccount, CreateAccountStep} from '../Onboarding/CreateAccount'
import {Branding} from './Branding'
import {Dashboard} from './Dashboard'
import {DesignHome} from './DesignHome/DesignHome'
import {DesignProfile} from './DesignProfile/DesignProfile'
import {Scenarios} from './Scenarios'
import {Users} from './Users'
import {AdminlandRootData} from './__types__/AdminlandRootData'
import {useOrganisationContext} from '~/components/OrganisationContext'

const DATA = gql`
    query AdminlandRootData {
        organisations {
            idStr
            name
        }
    }
`

const adminlandRoutes: AccordionData[] = [
    {id: '/', label: 'Home', icon: SvgHomeRegular, selectedIcon: SvgHomeBold, data: {analyticsEvent: 'select_home'}},
    {
        id: '/integrations',
        label: 'Data',
        icon: SvgDataRegular,
        selectedIcon: SvgDataBold,
        data: {analyticsEvent: 'select_data'},
    },
    {
        id: '/photos',
        label: 'Photos',
        icon: SvgPhotosRegular,
        selectedIcon: SvgPhotosBold,
        data: {analyticsEvent: 'select_photos'},
    },
    {
        id: 'design',
        label: 'Design',
        icon: SvgDesignRegular,
        selectedIcon: SvgDesignBold,
        unselectable: true,
        children: [
            {id: '/design/branding', label: 'Branding', data: {analyticsEvent: 'select_branding_page'}},
            {id: '/design/home', label: 'Home page', data: {exact: false, analyticsEvent: 'select_home_page'}},
            {id: '/design/profile', label: 'Profile page', data: {exact: false, analyticsEvent: 'select_profile_page'}},
        ],
    },
    {
        id: '/users',
        label: 'Users',
        icon: SvgUsersRegular,
        selectedIcon: SvgUsersBold,
        data: {analyticsEvent: 'select_users'},
    },
    {
        id: '/scenarios',
        label: 'Scenarios',
        icon: SvgScenariosRegular,
        selectedIcon: SvgScenariosBold,
        data: {analyticsEvent: 'select_scenarios'},
    },
]

const getSelectedId = (currentPath: string, routes: AccordionData[]): string | null => {
    for (const route of routes) {
        const match = matchPath(currentPath, {
            path: `${route.id}`,
            exact: route.data?.exact ?? true,
        })
        if (match) return `${route.id}`
        if (route.children) {
            const childMatch = getSelectedId(currentPath, route.children)
            if (childMatch) return childMatch
        }
    }
    return null
}

export const AdminlandRoot: FC = () => {
    const {path} = useRouteMatch()!
    const {relativePath, pushRelative} = useRelativeRoute()
    const {data, error, loading} = useQuery<AdminlandRootData>(DATA)
    const [renameLightboxOpen, setRenameLightboxOpen] = useState(false)
    const [approvalSettingsLightboxOpen, setApprovalSettingsLightboxOpen] = useState(false)
    const [reconnectPrimaryDataSource] = useQueryParam('reconnectPrimaryDataSource')
    const [refreshing] = useOrganisationContext.useOrganisationRefreshing()

    const {currentOrganisation} = useCurrentOrganisation()
    if (!currentOrganisation) throw new Error('No organisation')

    const isAdmin = currentOrganisation.currentUserRoles.includes(RoleType.Manage)
    const isScenario = currentOrganisation.scenario

    if (
        !isScenario &&
        !currentOrganisation.appFeatures.inAppData &&
        (!currentOrganisation.primaryDataSourceType || reconnectPrimaryDataSource)
    )
        return <CreateAccount initialStep={CreateAccountStep.DATA} organisationIdStr={currentOrganisation.idStr} />

    if (!isAdmin) return <Redirect to={`/${currentOrganisation.idStr}/`} />
    if (isScenario) return <Redirect to={`/${currentOrganisation.idStr}/org-chart/full`} />

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    if (refreshing?.error) return <Typography.Heading>{getGraphQLErrorMessage(refreshing?.error)}</Typography.Heading>
    if (loading || !data || refreshing?.loading) return <PageLoading />

    const selectedId = getSelectedId(relativePath || '', adminlandRoutes)

    return (
        <SidebarLayout
            logoLink={`/`}
            middleContent={
                <Accordion
                    lazySelect
                    data={adminlandRoutes}
                    selectedKey={selectedId || null}
                    onChange={(id, data) => {
                        if (data.href) {
                            window.open(data.href, '_blank')
                        } else if (typeof id === 'string') {
                            pushRelative(id)
                        }
                    }}
                />
            }
            bottomContent={
                <OrganisationSwitcher
                    onClickRename={() => setRenameLightboxOpen(true)}
                    onClickApprovalSettings={() => setApprovalSettingsLightboxOpen(true)}
                />
            }
        >
            <RenameOrganisationLightbox open={renameLightboxOpen} onClose={() => setRenameLightboxOpen(false)} />
            <PhotoApprovalSettingLightbox
                open={approvalSettingsLightboxOpen}
                onClose={() => setApprovalSettingsLightboxOpen(false)}
            />
            <Switch>
                <Route path={`${path}`} exact>
                    <Dashboard />
                </Route>
                <Route path={`${path}/integrations`}>
                    <Integrations />
                </Route>
                <Route path={`${path}/photos`}>
                    <Photos />
                </Route>
                <Route path={`${path}/design/home`}>
                    <DesignHome />
                </Route>
                <Route path={`${path}/design/branding`}>
                    <Branding />
                </Route>
                <Route path={`${path}/design/profile`}>
                    <DesignProfile />
                </Route>
                <Route path={`${path}/users`}>
                    <Users />
                </Route>
                <Route path={`${path}/scenarios`}>
                    <Scenarios />
                </Route>
                <Route default>
                    <NotFound />
                </Route>
            </Switch>
        </SidebarLayout>
    )
}
