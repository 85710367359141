import {
    ApolloError,
    FetchResult,
    gql,
    MutationTuple,
    QueryResult,
    StoreObject,
    useApolloClient,
    useMutation,
    useQuery,
} from '@apollo/client'
import {ReadFieldFunction} from '@apollo/client/cache/core/types/common'
import {useEffect, useState} from 'react'
import {useAlert} from 'react-alert'
import {ProfileLineInput} from '~/objectTypes'
import {getGraphQLErrorMessage} from '~/util'
import {DeleteProfileLines, DeleteProfileLinesVariables} from './__types__/DeleteProfileLines'
import {ProfileLines, ProfileLinesVariables} from './__types__/ProfileLines'
import {UpdateProfileLines, UpdateProfileLinesVariables} from './__types__/UpdateProfileLines'
import {hydrateProfileLineTypes, ProfileLine} from '~/components/OrganisationContext'

export const PROFILE_LINES = gql`
    query ProfileLines($organisationIdStr: String!) {
        profileLines(organisationIdStr: $organisationIdStr) {
            idStr
            organisationIdStr
            priority
            fieldOrCategoryIdStrs
            showField
            showLabel
            userCanEdit
            userCanHide
            sectionLineBelow
            fieldStyle
            labelStyle
            labelDescription
            fieldBehaviour
            prefix
            separator
            suffix
            hideYear
        }
    }
`

const PROFILE_LINES_UPDATE = gql`
    mutation UpdateProfileLines($organisationIdStr: String!, $profileLines: [ProfileLineInput!]!) {
        updateProfileLines(organisationIdStr: $organisationIdStr, profileLines: $profileLines) {
            idStr
            organisationIdStr
            priority
            fieldOrCategoryIdStrs
            showField
            showLabel
            userCanEdit
            userCanHide
            sectionLineBelow
            fieldStyle
            labelStyle
            labelDescription
            fieldBehaviour
            prefix
            separator
            suffix
            hideYear
        }
    }
`

const PROFILE_LINES_DELETE = gql`
    mutation DeleteProfileLines($organisationIdStr: String!, $profileLines: [String!]!) {
        deleteProfileLines(organisationIdStr: $organisationIdStr, profileLines: $profileLines)
    }
`

export const useQueryProfileLines = (
    organisationIdStr: string,
): [QueryResult<ProfileLines, ProfileLinesVariables>, ProfileLine[]] => {
    const query = useQuery<ProfileLines, ProfileLinesVariables>(PROFILE_LINES, {
        variables: {organisationIdStr},
    })
    const [dataView, setDataView] = useState<ProfileLine[]>([])

    useEffect(() => {
        setDataView(
            (query.data?.profileLines || [])
                .filter((line) => line.organisationIdStr === organisationIdStr)
                .map(hydrateProfileLineTypes),
        )
    }, [query, organisationIdStr])

    return [query, dataView]
}

const cacheEvictions: string[] = []

export const cachePolicyProfileLines = {
    read: (existing: StoreObject[] = []) => existing,
    merge: (existing: StoreObject[] = [], incoming: StoreObject[], {readField}: {readField: ReadFieldFunction}) => {
        const isSameObject = (a: StoreObject, b: StoreObject) => {
            return readField<string>('idStr', a) === readField<string>('idStr', b)
        }

        const isEvicted = (storeObject: StoreObject) => {
            return cacheEvictions.includes(readField<string>('idStr', storeObject) || '')
        }

        // console.log('cachePolicyProfileLines.merge', existing, incoming, cacheEvictions)

        return [
            ...existing.map(
                (existingObject) =>
                    incoming.find((incomingObject) => isSameObject(existingObject, incomingObject)) || existingObject,
            ),
            ...incoming.filter(
                (incomingObject) => !existing.some((existingObject) => isSameObject(existingObject, incomingObject)),
            ),
        ].filter((storeObject) => !isEvicted(storeObject))
    },
}

export const useCacheProfileLines = () => {
    const client = useApolloClient()
    const read = (organisationIdStr: string) =>
        client.readQuery<ProfileLines, ProfileLinesVariables>({
            query: PROFILE_LINES,
            variables: {organisationIdStr},
        })?.profileLines || []

    const write = (
        organisationIdStr: string,
        values: ProfileLine[] | ((currentData: ProfileLine[]) => ProfileLine[]),
    ) => {
        const data = (typeof values === 'function'
            ? values(read(organisationIdStr).map(hydrateProfileLineTypes))
            : values
        ).map((newValue) => ({
            ...newValue,
            id: `ProfileLines:{"idStr":${newValue.idStr}}`,
        }))
        // console.log('useCacheProfileLines.write', data)
        client.writeQuery({
            query: PROFILE_LINES,
            data: {
                profileLines: data,
            },
            variables: {organisationIdStr},
        })
    }

    return [
        {
            read,
            write,
        },
    ]
}

const patchProfileLine = ({incoming, existing}: {incoming: ProfileLineInput; existing: ProfileLine}) => ({
    __typename: 'ProfileLine' as 'ProfileLine',
    idStr: existing.idStr,
    organisationIdStr: incoming.organisationIdStr !== null ? incoming.organisationIdStr : existing.organisationIdStr,
    priority: incoming.priority !== null ? incoming.priority : existing.priority,
    fieldOrCategoryIdStrs:
        incoming.fieldOrCategoryIdStrs !== null ? incoming.fieldOrCategoryIdStrs : existing.fieldOrCategoryIdStrs,
    showField: incoming.showField !== null ? incoming.showField : existing.showField,
    showLabel: incoming.showLabel !== null ? incoming.showLabel : existing.showLabel,
    userCanEdit: incoming.userCanEdit !== null ? incoming.userCanEdit : existing.userCanEdit,
    userCanHide: incoming.userCanHide !== null ? incoming.userCanHide : existing.userCanHide,
    sectionLineBelow: incoming.sectionLineBelow !== null ? incoming.sectionLineBelow : existing.sectionLineBelow,
    fieldStyle: incoming.fieldStyle !== null ? incoming.fieldStyle : existing.fieldStyle,
    labelStyle: incoming.labelStyle !== null ? incoming.labelStyle : existing.labelStyle,
    labelDescription: incoming.labelDescription !== null ? incoming.labelDescription : existing.labelDescription,
    fieldBehaviour: incoming.fieldBehaviour !== null ? incoming.fieldBehaviour : existing.fieldBehaviour,
    prefix: incoming.prefix !== null ? incoming.prefix : existing.prefix,
    separator: incoming.separator !== null ? incoming.separator : existing.separator,
    suffix: incoming.suffix !== null ? incoming.suffix : existing.suffix,
    hideYear: incoming.hideYear !== null ? incoming.hideYear : existing.hideYear,
})

export const useMutationUpdateProfileLines = (
    organisationIdStr: string,
): [
    MutationTuple<UpdateProfileLines, UpdateProfileLinesVariables>,
    (variables: {
        profileLines: ProfileLineInput[]
    }) => Promise<FetchResult<UpdateProfileLines, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const [profileLinesCache] = useCacheProfileLines()
    const mutation = useMutation<UpdateProfileLines, UpdateProfileLinesVariables>(PROFILE_LINES_UPDATE, {})
    const callMutation = async (variables: {profileLines: ProfileLineInput[]}) => {
        try {
            const cached = profileLinesCache.read(organisationIdStr).map(hydrateProfileLineTypes)
            const updatingOnly = variables.profileLines.every(
                (line) => line.idStr && cached.some((cachedLine) => cachedLine.idStr === line.idStr),
            )
            const optimisticProfileLines = updatingOnly
                ? variables.profileLines
                      .map((line) => ({
                          incoming: line,
                          existing: cached.find((cachedLine) => cachedLine.idStr === line.idStr)!,
                      }))
                      .map(patchProfileLine)
                      .map(hydrateProfileLineTypes)
                : undefined
            if (optimisticProfileLines) {
                await profileLinesCache.write(organisationIdStr, optimisticProfileLines)
            }
            const result = await mutation[0]({
                variables: {organisationIdStr, ...variables},
                optimisticResponse: optimisticProfileLines ? {updateProfileLines: optimisticProfileLines} : undefined,
            })
            await profileLinesCache.write(
                organisationIdStr,
                result.data!.updateProfileLines.map(hydrateProfileLineTypes),
            )
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationDeleteProfileLines = (
    organisationIdStr: string,
): [
    MutationTuple<DeleteProfileLines, DeleteProfileLinesVariables>,
    (variables: {
        profileLines: string[]
    }) => Promise<FetchResult<DeleteProfileLines, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const [profileLinesCache] = useCacheProfileLines()
    const mutation = useMutation<DeleteProfileLines, DeleteProfileLinesVariables>(PROFILE_LINES_DELETE, {})
    const callMutation = async (variables: {profileLines: string[]}) => {
        try {
            const result = await mutation[0]({variables: {organisationIdStr, ...variables}})
            cacheEvictions.push(...variables.profileLines)
            await profileLinesCache.write(organisationIdStr, (items) => items)
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
