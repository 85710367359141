import {Icon, IconContainer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgOpenLink from 'nf-ui/Icons/OpenLink'
import React from 'react'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {RoleType} from '~/globalTypes'
import {CurrentOrganisation} from '../CurrentOrganisationContext'
import {UnstyledLink} from '../UnstyledLink'

export const Container = styled(UnstyledLink)`
    display: flex;
    margin-bottom: 16px;
    margin-left: 16px;
    align-items: center;
    ${IconContainer} {
        margin-right: 16px;
    }
`

export const DirectoryAdminSwitcher = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const {path} = useRouteMatch()!
    const isAdminLand = path.includes('admin')

    const {currentOrganisation} = CurrentOrganisation.useContainer()

    if (!currentOrganisation?.currentUserRoles?.includes(RoleType.Manage)) return null

    const scenario = currentOrganisation.scenario

    let to = ''
    if (scenario) {
        to = `/${currentOrganisation.parentIdStr}/admin/scenarios`
    } else {
        to = isAdminLand ? `/${currentOrganisation.idStr}` : `/${currentOrganisation.idStr}/admin`
    }

    return (
        <Container
            to={to}
            onClick={() => trackAnalyticsEvent(isAdminLand ? 'select_view_userland' : 'select_view_adminland')}
        >
            <Icon icon={SvgOpenLink} />
            <Typography.Paragraph bottomMargin={false}>
                View {isAdminLand ? 'directory' : 'Adminland'}
            </Typography.Paragraph>
        </Container>
    )
}
