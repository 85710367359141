import {omit} from 'lodash'
import {Panel} from 'nf-ui'
import SvgCheck from 'nf-ui/Icons/Check'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row} from '../Primitives'
import {Inter, StyledInputProps, TextInputElement, VisibleInput} from './Primitives'

export type SingleSelectInputProps = StyledInputProps & {
    width?: string
    freeText?: boolean
    availableValues: string[]
    defaultValue: string
    placeholder: string
    onChange?: (value: string) => void
    onDoneEditing?: () => void
    panelRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const SingleSelectInput = forwardRef<TextInputElement, SingleSelectInputProps>(
    (props: SingleSelectInputProps, ref) => {
        const defaultValue = props.defaultValue
        const [text, setText] = useState(defaultValue)
        const [value, setValue] = useState(defaultValue)
        const [hasFocus, setHasFocus] = useState(false)
        const [scrollingComplete, setScrollingComplete] = useState<boolean>(false)
        const innerRef = useRef<TextInputElement>(null)
        useImperativeHandle<TextInputElement | null, TextInputElement | null>(ref, () => innerRef.current)

        useEffect(() => {
            if (props.availableValues.length === 1 && !props.freeText) setValue(props.availableValues[0])
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            setText(value)
            props.onChange?.(value)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value])

        useEffect(() => {
            if (!hasFocus) {
                if (!props.freeText && !props.availableValues.includes(text)) {
                    setText(value)
                } else {
                    setValue(text)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hasFocus])

        const hasExactMatch = props.availableValues.some((value) => value === text)
        const filterValues = text.split(' ')
        const matches = hasExactMatch
            ? props.availableValues
            : props.availableValues.filter((value) => filterValues.every((filterValue) => value.includes(filterValue)))

        return (
            <span>
                <VisibleInput
                    {...omit(props, ['onDoneEditing', 'defaultValue'])}
                    type="text"
                    ref={innerRef}
                    value={text}
                    scrollMarginBottom={235}
                    readOnly={false}
                    onChange={(ev) => setText(ev.currentTarget.value)}
                    onHasFocusChange={setHasFocus}
                    onKeyUp={(ev) => {
                        if (ev.key === 'Enter') {
                            props.onDoneEditing?.()
                        }
                    }}
                    onScrollIntoViewBegin={() => setScrollingComplete(false)}
                    onScrollIntoViewComplete={() => setScrollingComplete(true)}
                ></VisibleInput>
                <Panel targetRef={innerRef} open={hasFocus && scrollingComplete} onClose={() => {}}>
                    <Column minWidth="280px" ref={props.panelRef}>
                        {matches.map((match, index) => (
                            <Column key={index}>
                                <Row onClick={() => setValue(match)} cursor="pointer">
                                    <Column width="10px" grow={0}></Column>
                                    <Column grow={0}>
                                        <Row height="16px"></Row>
                                        <Row>
                                            <Inter fontWeight={value === match ? 'bold' : ''}>{match}</Inter>
                                        </Row>
                                        <Row height="16px"></Row>
                                    </Column>
                                    <Column width="8px" grow={0}></Column>
                                    {value === match && (
                                        <Column grow={0}>
                                            <Row height="14px"></Row>
                                            <SvgCheck></SvgCheck>
                                            <Row height="16px"></Row>
                                        </Column>
                                    )}
                                    <Column width="10px" grow={1}></Column>
                                </Row>
                                {index < matches.length - 1 && <Row height="1px" backgroundColor="#d5dbdf"></Row>}
                            </Column>
                        ))}
                    </Column>
                </Panel>
            </span>
        )
    },
)

playground.push({
    path: 'src/components/InputFields/SingleSelectInput.tsx',
    component: SingleSelectInput,
    props: {
        freeText: true,
        placeholder: 'Placeholder',
        defaultValue: 'Grade 1',
        availableValues: ['Grade 1'],
    },
    propOptions: {
        freeText: [false, true],
        value: {
            get: (props: any) => props.value,
        },
        onChange: () => ({props, args}: {props: any; args: any[]}) => ({...props, value: args[0]}),
        onDoneEditing: () => {},
    },
})
