import {Icon, Spinner} from 'nf-ui'
import SvgCopy from 'nf-ui/Icons/Copy'
import React from 'react'
import styled from 'styled-components'
import {validImageMimeTypes} from '~/util/validImages'
import {Column, HideableCss, HideableCssProps, Row} from '../Primitives'

export const EMAIL_REGEX = /^\S+@\S+\.\S+$/
export const CELL_CODE_REGEX = /^\+[0-9][0-9]+$/

export const Heading1 = styled.span<{fontSize?: string} & HideableCssProps>`
    color: #111111;
    font-family: 'publico-banner';
    font-size: ${({fontSize}) => fontSize || '72px'};
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.1em;
    paragraph-spacing: 0px;
    ${HideableCss}
`

export const Heading3 = styled.span<
    {
        fontSize?: string
        letterSpacing?: string
        maxWidth?: string
        centerHorizontal?: boolean
        cursor?: string
        backgroundColor?: string
    } & HideableCssProps
>`
    font-family: 'inter';
    font-size: ${({fontSize}) => fontSize || '20px'};
    font-style: normal;
    font-weight: 400;
    letter-spacing: ${({letterSpacing}) => letterSpacing || '-0.4px'};
    line-height: 1.4em;
    paragraph-spacing: 0px;
    color: ${({color}) => (color ? color : `#5c5c5c`)};
    ${({backgroundColor}) => (backgroundColor ? `background-color: ${backgroundColor};` : ``)};
    ${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    ${({centerHorizontal}) => (centerHorizontal ? 'text-align: center;' : '')}
    ${({cursor}) => (cursor ? `cursor: ${cursor};` : '')}
    ${HideableCss}
`

export const Questions = styled.span<{fontSize?: string} & HideableCssProps>`
    border-width: 1px;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    :hover {
        background-color: rgb(217, 217, 217);
    }
    border-radius: 36px;
    height: 38px;
    padding: 0px 22px;

    font-family: 'inter';
    font-size: ${({fontSize}) => fontSize || '12px'};
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.2px;
    paragraph-spacing: 0px;

    display: flex;
    align-items: center;

    cursor: pointer;

    ${HideableCss}
`

export const ProgressBars = ({progress}: {progress?: number | string}) => {
    const progressInt = typeof progress === 'string' ? parseInt(progress) || 0 : progress
    return (
        <Row height="6px" width="100%">
            <Column grow={1} backgroundColor={(progressInt || 1) >= 1 ? 'rgb(60, 142, 255)' : 'rgb(235, 235, 235)'} />
            <Column grow={0} width="6px" />
            <Column grow={1} backgroundColor={(progressInt || 1) >= 2 ? 'rgb(60, 142, 255)' : 'rgb(235, 235, 235)'} />
            <Column grow={0} width="6px" />
            <Column grow={1} backgroundColor={(progressInt || 1) >= 3 ? 'rgb(60, 142, 255)' : 'rgb(235, 235, 235)'} />
        </Row>
    )
}

export const SpinnerPage = () => (
    <Column height="100%" width="100%" centerVertical>
        <Row width="100%" centerHorizontal>
            <Spinner size={24}></Spinner>
        </Row>
    </Column>
)

export const FieldSubText = styled.span`
    font-family: 'inter';
    font-size: 12px;
    color: rgb(92, 92, 92);
`

export const DidntGetCodeButton = styled.span`
    font-family: 'inter';
    font-size: 12px;
    color: rgb(92, 92, 92);
    :hover {
        color: #0088ff;
    }
    text-decoration: underline;
    cursor: pointer;
`

export const AddAnotherParentButton = styled.span`
    font-family: 'inter';
    font-size: 16px;
    font-weight: 600;
    color: #0088ff;
    text-decoration: underline;
    cursor: pointer;
`

const SelectPhotoFileInput = styled.input.attrs({
    type: 'file',
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
`

export const SelectPhotoContainer = styled.span`
    position: relative;
    font-family: 'inter';
    font-size: 16px;
    font-weight: 600;
    color: #3c8eff;
    background-color: rgba(60, 142, 255, 0.1);
    border: 1px dashed #3c8eff;
    padding: 14px 36px;
    border-radius: 5px;
    cursor: pointer;
`

export const SelectPhoto = ({children, onSelectPhoto}: {children: string; onSelectPhoto: (file: File) => void}) => (
    <SelectPhotoContainer>
        <SelectPhotoFileInput
            accept={validImageMimeTypes}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
                try {
                    if (!event.target.files) return
                    onSelectPhoto(Array.from(event.target.files)[0])
                } catch (error) {
                    // alert.show(error.message)
                }
                event.target.value = ''
            }}
        />
        <Row>
            {children}
            <Column width="10px" />
            <Column>
                <Row height="3px"></Row>
                <Icon icon={SvgCopy} tint="#3c8eff" />
            </Column>
        </Row>
    </SelectPhotoContainer>
)

export type ErrorState = Record<string, {message: string; visible: boolean} | undefined>

export const ErrorMessage = styled.span`
    font-family: 'inter';
    font-size: 12px;
    color: #ff0000;
`

export const Inter = styled.span<{fontSize?: string; fontWeight?: string}>`
    font-family: 'inter';
    font-size: ${({fontSize}) => fontSize || '14px'};
    ${({fontWeight}) => (fontWeight ? `font-weight: ${fontWeight};` : '')}
`

export const hideError = (key: string, errorState: ErrorState, setErrorState: (value: ErrorState) => void) => {
    if (errorState[key]?.visible) {
        setErrorState({
            ...errorState,
            ...Object.fromEntries([[key, {message: errorState[key]?.message || '', visible: false}]]),
        })
    }
}

export const ErrorRows = ({errorRecord}: {errorRecord: {message: string; visible: boolean} | undefined}) => {
    return (
        <>
            {errorRecord?.visible && errorRecord?.message && (
                <>
                    <Row height="10px" />
                    <Row>
                        <Column width="14px" grow={0} shrink={0}></Column>
                        <Column grow={0} shrink={1}>
                            <ErrorMessage>{errorRecord?.message || ''}</ErrorMessage>
                        </Column>
                        <Column width="14px" grow={1} shrink={0}></Column>
                    </Row>
                </>
            )}
        </>
    )
}
