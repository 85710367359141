import React, {useEffect} from 'react'
import {useAlert} from 'react-alert'
import {useQueryResponses} from '~/apollo/queries/Responses'
import {FormState} from './Form'
import {SpinnerPage} from './Primitives'

export const ResumeSubmission = ({
    formIdStr,
    emailAddress,
    onNext,
}: {
    formIdStr: string
    emailAddress: string
    onNext: (responseIdStr: string, formState: FormState) => void
}) => {
    const [responses] = useQueryResponses(formIdStr, emailAddress)
    const alert = useAlert()

    useEffect(() => {
        if (!responses.loading) {
            const response = responses.data?.responses?.filter((response) => !response.hasGivenConsent)?.[0]
            const draftProfile = response?.data?.draftProfile

            if (response && draftProfile) {
                onNext(response.idStr, Object.fromEntries(draftProfile.map(({name, value}) => [name, value])))
            } else {
                alert.error(
                    `Error: No incomplete submissions for ${emailAddress} and form ${formIdStr} could be found.`,
                )
                console.log(
                    `Error: No incomplete submissions for ${emailAddress} and form ${formIdStr} could be found.`,
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responses.loading])

    return <SpinnerPage></SpinnerPage>
}
