import {from} from 'fromfrom'
import {omit} from 'lodash'
import {Button, colors, Form, Spacer, Typography} from 'nf-ui'
import React, {useState} from 'react'
import {useMutationDeleteProfileLines, useMutationUpdateProfileLines} from '~/apollo/queries/ProfileLine'
import {FlexRow, FlexColumn} from '~/components/LayoutItem'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {DraggableItem} from '../DraggableItem'
import {DraggableList} from '../DraggableList'
import {onDragEndItemList} from '../onDragEndItemList'
import {FormField} from './FormField'
import {ProfilePictureField} from './ProfilePictureField'
import Handle from '../handle.svg'
import {ProfileLine, DataEditField} from '~/components/OrganisationContext'

export type FieldListProps = {
    setHoveringId: (id: string | undefined) => void
    profileLines: ProfileLine[]
    fields: DataEditField[]
}

const labelDescription = (profileLine: ProfileLine, fields: DataEditField[]) => {
    return (
        profileLine.labelDescription ||
        `${profileLine.prefix || ''}${profileLine.fieldOrCategoryIdStrs
            .map((idStr) => fields.find((field) => field.idStr === idStr)?.name)
            .join(profileLine.separator || ' & ')}${profileLine.suffix}`
    )
}

export const FieldList: React.FC<FieldListProps> = ({setHoveringId, profileLines, fields}) => {
    const organisationIdStr = useOrganisationIdStr()
    const [, updateProfileLines] = useMutationUpdateProfileLines(organisationIdStr)
    const [, deleteProfileLines] = useMutationDeleteProfileLines(organisationIdStr)
    const [expanded, setExpanded] = useState<string[]>([])
    const [combinedField, setCombinedField] = useState<string[] | undefined>(undefined)

    const draggableItems = from(profileLines)
        .sortBy((item) => item.priority)
        .toArray()
        .map((item) => ({
            profileLine: omit(item, '__typename'),
            ...item,
            canExpand: true,
            idStr: item.idStr,
            visible: item.showField,
            label: labelDescription(item, fields),
        }))

    const onDragEnd = onDragEndItemList<'ProfileLine'>((reorderedItems) => {
        // console.log(reorderedItems)
        // console.log(reorderedItems.map(item => item.parentIdStr), profileLines.map(line => line.idStr))
        updateProfileLines({
            profileLines: reorderedItems.map((item, index) => {
                const profileLine = profileLines.find((line) => line.idStr === item.idStr)
                return {
                    ...omit(profileLine, '__typename'),
                    priority: item.priority,
                    sectionLineBelow: index === reorderedItems.length - 1 ? false : !!profileLine?.sectionLineBelow,
                }
            }),
        })
    })

    const onCombinedFieldDragEnd = onDragEndItemList<'CombinedField'>((reorderedItems) => {
        // console.log(reorderedItems)
        const profileLine = profileLines.find((line) => line.idStr === reorderedItems[0].parentIdStr)
        profileLine &&
            updateProfileLines({
                profileLines: [
                    {
                        ...omit(profileLine, '__typename'),
                        fieldOrCategoryIdStrs: reorderedItems.map((item) => item.idStr),
                    },
                ],
            })
    })

    return (
        <>
            <Typography.Heading>Profile Layout</Typography.Heading>
            <Spacer height={32} />

            <FlexRow>
                <ProfilePictureField />
            </FlexRow>

            <DraggableList onDragEnd={(result) => onDragEnd(result, draggableItems)} droppableId="DesignProfile">
                {draggableItems.map((item, index) => {
                    const isCombination = item.fieldOrCategoryIdStrs.length > 1
                    const draggableFields = item.fieldOrCategoryIdStrs
                        .map((idStr) => fields.find((field) => field.idStr === idStr)!)
                        .filter((field) => field)
                        .map((field, index) => ({
                            __typename: 'CombinedField' as 'CombinedField',
                            idStr: field.idStr,
                            label: field.name,
                            visible: true,
                            canExpand: false,
                            priority: index + 1,
                            parentIdStr: item.idStr,
                        }))
                    return (
                        <DraggableItem
                            item={item}
                            index={index}
                            key={item.idStr}
                            onMouseEnter={() => setHoveringId(item.fieldOrCategoryIdStrs.join(';'))}
                            onMouseLeave={() => setHoveringId(undefined)}
                            expanded={expanded}
                            isDragDisabled={isCombination && !!expanded.length}
                            setExpanded={setExpanded}
                            editable={false}
                            canChangeSectionLine={index < draggableItems.length - 1}
                            onChangeSectionLine={() =>
                                updateProfileLines({
                                    profileLines: [{...item.profileLine, sectionLineBelow: !item.sectionLineBelow}],
                                })
                            }
                            hasSectionLine={item.sectionLineBelow}
                        >
                            <FlexColumn padding="10px 10px 10px 15px">
                                <FlexRow padding="0px 0px 10px" fontWeight={500}>
                                    {isCombination && 'Combination '}Field
                                </FlexRow>
                                {isCombination && (
                                    <FlexColumn padding="0px 0px 0px 13px">
                                        <DraggableList
                                            onDragEnd={(result) => onCombinedFieldDragEnd(result, draggableFields)}
                                            droppableId="CombinedFieldOrder"
                                        >
                                            {draggableFields.map((item, index) => (
                                                <DraggableItem
                                                    item={item}
                                                    index={index}
                                                    key={item.idStr}
                                                    editable={false}
                                                    expanded={[item.idStr]}
                                                    setExpanded={() => {}}
                                                >
                                                    <FlexRow width="227px"></FlexRow>
                                                </DraggableItem>
                                            ))}
                                        </DraggableList>
                                    </FlexColumn>
                                )}
                                <FlexColumn padding="0px 0px 20px 15px">
                                    {isCombination && (
                                        <FormField
                                            name="prefix"
                                            label="Prefix"
                                            type="text"
                                            readOnly={!item.showField}
                                            initialValue={item.prefix}
                                            onValueChanged={(value) =>
                                                updateProfileLines({
                                                    profileLines: [{...item.profileLine, prefix: value as string}],
                                                })
                                            }
                                        ></FormField>
                                    )}
                                    {isCombination && (
                                        <FormField
                                            name="separator"
                                            label="Separator"
                                            type="text"
                                            readOnly={!item.showField}
                                            initialValue={item.separator}
                                            onValueChanged={(value) =>
                                                updateProfileLines({
                                                    profileLines: [{...item.profileLine, separator: value as string}],
                                                })
                                            }
                                        ></FormField>
                                    )}
                                    {isCombination && (
                                        <FormField
                                            name="suffix"
                                            label="Suffix"
                                            type="text"
                                            readOnly={!item.showField}
                                            initialValue={item.suffix}
                                            onValueChanged={(value) =>
                                                updateProfileLines({
                                                    profileLines: [{...item.profileLine, suffix: value as string}],
                                                })
                                            }
                                        ></FormField>
                                    )}
                                    <FormField
                                        name="fieldStyle"
                                        label="Style"
                                        type="dropdown"
                                        readOnly={!item.showField}
                                        initialValue={item.fieldStyle || 'Heading 1'}
                                        dropDownValues={[
                                            {value: 'Heading 1'},
                                            {value: 'Heading 2'},
                                            {value: 'Paragraph'},
                                            {value: 'Label'},
                                        ]}
                                        onValueChanged={(value) =>
                                            updateProfileLines({
                                                profileLines: [{...item.profileLine, fieldStyle: value as string}],
                                            })
                                        }
                                    ></FormField>
                                    {item.fieldBehaviour !== 'Category' && (
                                        <FormField
                                            name="fieldBehavior"
                                            label="Behavior"
                                            type="dropdown"
                                            readOnly={!item.showField}
                                            initialValue={item.fieldBehaviour || 'text'}
                                            dropDownValues={[
                                                {label: 'Text', value: 'text'},
                                                {label: 'First Name', value: 'firstName'},
                                                {label: 'Last Name', value: 'lastName'},
                                                {label: 'Email', value: 'email'},
                                                {label: 'Mobile Number', value: 'mobileNumber'},
                                                {label: 'Landline Number', value: 'landlineNumber'},
                                                {label: 'Date', value: 'date'},
                                            ]}
                                            onValueChanged={(value) =>
                                                updateProfileLines({
                                                    profileLines: [
                                                        {...item.profileLine, fieldBehaviour: value as string},
                                                    ],
                                                })
                                            }
                                        ></FormField>
                                    )}
                                    <FormField
                                        name="showField"
                                        label="Show field"
                                        type="switch"
                                        initialValue={item.showField}
                                        onValueChanged={(value) =>
                                            updateProfileLines({
                                                profileLines: [{...item.profileLine, showField: value as boolean}],
                                            })
                                        }
                                    ></FormField>
                                    {!isCombination && (
                                        <FormField
                                            name="userCanHide"
                                            label="Allow users to hide"
                                            type="switch"
                                            readOnly={!item.showField}
                                            initialValue={item.userCanHide}
                                            onValueChanged={(value) =>
                                                updateProfileLines({
                                                    profileLines: [
                                                        {...item.profileLine, userCanHide: value as boolean},
                                                    ],
                                                })
                                            }
                                        ></FormField>
                                    )}
                                </FlexColumn>
                                <FlexRow padding="0px 0px 10px" fontWeight={500}>
                                    Label
                                </FlexRow>
                                <FlexColumn padding="0px 0px 20px 15px">
                                    <FormField
                                        name="showLabel"
                                        label="Show label"
                                        type="switch"
                                        readOnly={!item.showField}
                                        initialValue={item.showLabel}
                                        onValueChanged={(value) =>
                                            updateProfileLines({
                                                profileLines: [{...item.profileLine, showLabel: value as boolean}],
                                            })
                                        }
                                    ></FormField>
                                    <FormField
                                        name="labelDescription"
                                        label="Description"
                                        type="text"
                                        readOnly={!item.showField || !item.showLabel}
                                        initialValue={labelDescription(item, fields)}
                                        onValueChanged={(value) =>
                                            updateProfileLines({
                                                profileLines: [
                                                    {...item.profileLine, labelDescription: value as string},
                                                ],
                                            })
                                        }
                                    ></FormField>
                                    <FormField
                                        name="labelStyle"
                                        label="Style"
                                        type="dropdown"
                                        readOnly={!item.showField || !item.showLabel}
                                        initialValue={item.labelStyle || 'Label'}
                                        dropDownValues={[
                                            {value: 'Heading 1'},
                                            {value: 'Heading 2'},
                                            {value: 'Paragraph'},
                                            {value: 'Label'},
                                        ]}
                                        onValueChanged={(value) =>
                                            updateProfileLines({
                                                profileLines: [{...item.profileLine, labelStyle: value as string}],
                                            })
                                        }
                                    ></FormField>
                                </FlexColumn>
                                {isCombination && (
                                    <FlexRow horizontalAlign="flex-end">
                                        <Button
                                            variant="red"
                                            onClick={() => {
                                                deleteProfileLines({profileLines: [item.profileLine.idStr!]})
                                            }}
                                        >
                                            Delete Field
                                        </Button>
                                    </FlexRow>
                                )}
                            </FlexColumn>
                        </DraggableItem>
                    )
                })}
            </DraggableList>

            <Spacer height={8} />

            <FlexRow>
                {!combinedField && (
                    <FlexRow
                        height="56px"
                        padding="16px"
                        borderColor={colors.darkGray}
                        onClick={() => setCombinedField([])}
                    >
                        <Handle></Handle>
                        <Spacer width={8} />
                        <FlexRow color={colors.darkGray} hover={{color: '#3c8eff'}} cursor="pointer" fontWeight={500}>
                            + Add Combined Field
                        </FlexRow>
                    </FlexRow>
                )}
                {combinedField && (
                    <FlexColumn borderColor={colors.darkGray}>
                        <FlexRow padding="16px">Select fields...</FlexRow>
                        {fields
                            .filter(
                                (field) =>
                                    field.type !== 'text' ||
                                    !field.subType
                                        ?.split(',')
                                        ?.some(
                                            (pair) => pair.split('=')[0] === 'rows' && parseInt(pair.split('=')[1]) > 1,
                                        ),
                            )
                            .map((field, index) => (
                                <FlexRow
                                    key={index}
                                    padding="16px"
                                    bottom={{borderColor: colors.darkGray}}
                                    hover={{backgroundColor: colors.lightGray}}
                                    onClick={() => {
                                        setCombinedField(
                                            combinedField.includes(field.idStr)
                                                ? combinedField.filter((f) => f !== field.idStr)
                                                : [...combinedField, field.idStr],
                                        )
                                    }}
                                >
                                    <Form.Checkbox
                                        label={field.name}
                                        checked={combinedField.includes(field.idStr)}
                                        onChange={() => {
                                            setCombinedField(
                                                combinedField.includes(field.idStr)
                                                    ? combinedField.filter((f) => f !== field.idStr)
                                                    : [...combinedField, field.idStr],
                                            )
                                        }}
                                    />
                                </FlexRow>
                            ))}
                        <FlexRow horizontalAlign="space-between" padding="10px 16px">
                            <Button variant="tertiary" onClick={() => setCombinedField(undefined)}>
                                Cancel
                            </Button>
                            <Button
                                onClick={async () => {
                                    await updateProfileLines({
                                        profileLines: [
                                            {
                                                organisationIdStr: null,
                                                fieldOrCategoryIdStrs: combinedField,
                                                labelDescription: null,
                                                idStr: null,
                                                priority: null,
                                                showField: null,
                                                showLabel: null,
                                                userCanEdit: null,
                                                userCanHide: null,
                                                sectionLineBelow: null,
                                                fieldStyle: null,
                                                labelStyle: null,
                                                fieldBehaviour: null,
                                                prefix: null,
                                                suffix: null,
                                                hideYear: null,
                                                separator: null,
                                            },
                                        ],
                                    })
                                    setCombinedField(undefined)
                                }}
                            >
                                Create
                            </Button>
                        </FlexRow>
                    </FlexColumn>
                )}
            </FlexRow>
        </>
    )
}
