import dateFormat from 'dateformat'

export const today = () => new Date()

export const toStringAndPad = (component: number, width: number, char?: string) => {
    return component.toString().padStart(width, char || '0')
}

export const formatDateDDMMYYYY = (date: Date) => {
    return `${toStringAndPad(date.getDate(), 2)}/${toStringAndPad(date.getMonth() + 1, 2)}/${toStringAndPad(
        date.getFullYear(),
        4,
    )}`
}

export const formatDateYYYYMMDD = (date: Date) => {
    return `${toStringAndPad(date.getFullYear(), 4)}/${toStringAndPad(date.getMonth() + 1, 2)}/${toStringAndPad(
        date.getDate(),
        2,
    )}`
}

export const formatDateDDMM = (date: Date) => {
    return `${toStringAndPad(date.getDate(), 2)}/${toStringAndPad(date.getMonth() + 1, 2)}`
}

export const parseDate = (value: string) => {
    const [month, day, year] = value.split('-')
    const dateValue = year ? `${month}/${day}/${year}` : day ? `${month}/${day}/0000` : month
    const date = new Date(dateValue)
    return isNaN(date.getDate()) ? null : date
}
export const toStorage = (date: Date | null) => date && dateFormat(date, 'yyyy-mm-dd')
export const toDisplay = (date: Date, options?: {hideYear?: boolean}) =>
    dateFormat(date, options?.hideYear ? 'd mmmm' : 'd mmmm yyyy')
