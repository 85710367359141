import {ApolloError, gql, MutationTuple, useMutation, QueryResult, useQuery, StoreObject} from '@apollo/client'
import {useAlert} from 'react-alert'
import {DataEditFieldUpsert} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {useQueryAllHomeItemData} from './AllHomeItemData'
import {useQueryListCategories} from './ListCategories'
import {DataEditUpdateFields, DataEditUpdateFieldsVariables} from './__types__/DataEditUpdateFields'
import {useEffect, useState} from 'react'
import {DataEditGetFields, DataEditGetFieldsVariables} from './__types__/DataEditGetFields'
import {DataEditField, hydrateDataEditFieldTypes, useOrganisationContext} from '~/components/OrganisationContext'

export const DATA_EDIT_GET_FIELDS = gql`
    query DataEditGetFields($organisationIdStr: String!, $fieldName: String) {
        dataEditFields(organisationIdStr: $organisationIdStr, fieldName: $fieldName) {
            idStr
            organisationIdStr
            type
            subType
            name
            availableValues
            availableValueIdStrs
            availableValueLabels
            baseFields {
                idStr
                organisationIdStr
                type
                subType
                name
                availableValues
                availableValueIdStrs
                availableValueLabels
            }
        }
    }
`

const DATA_EDIT_UPDATE_FIELDS = gql`
    mutation DataEditUpdateFields(
        $organisationIdStr: String!
        $dataEditFields: [DataEditFieldUpsert!]!
        $removeMissingFields: Boolean!
    ) {
        updateDataEditFields(
            organisationIdStr: $organisationIdStr
            dataEditFields: $dataEditFields
            removeMissingFields: $removeMissingFields
        ) {
            idStr
            organisationIdStr
            type
            subType
            name
            availableValues
            availableValueIdStrs
            availableValueLabels
            baseFields {
                idStr
                organisationIdStr
                type
                subType
                name
                availableValues
                availableValueIdStrs
                availableValueLabels
            }
        }
    }
`

export const useQueryDataEditGetFields = (
    organisationIdStr: string,
): [QueryResult<DataEditGetFields, DataEditGetFieldsVariables>, DataEditField[]] => {
    const query = useQuery<DataEditGetFields, DataEditGetFieldsVariables>(DATA_EDIT_GET_FIELDS, {
        variables: {organisationIdStr},
        fetchPolicy: 'no-cache',
    })
    const [dataView, setDataView] = useState<DataEditField[]>([])

    useEffect(() => {
        setDataView((query.data?.dataEditFields || []).map(hydrateDataEditFieldTypes))
    }, [query])

    return [query, dataView]
}

export const cachePolicyDataEditGetFields = {
    read: (existing: StoreObject[] = []) => existing,
    merge: (existing: StoreObject[] = [], incoming: StoreObject[]) => incoming,
}

export const mergeDataEditFields = (
    original: DataEditField[],
    updated: DataEditField[],
    removeMissingFields: boolean,
): DataEditField[] => {
    return [
        ...original.flatMap((field) => {
            const updatedField = updated.find((_field) => _field.idStr === field.idStr)
            return updatedField ? [updatedField] : removeMissingFields ? [] : field
        }),
        ...updated.filter((field) => !original.some((_field) => _field.idStr === field.idStr)),
    ]
}

export const useMutationDataEditUpdateFields = (
    organisationIdStr: string,
): [
    MutationTuple<DataEditUpdateFields, DataEditUpdateFieldsVariables>,
    (variables: {
        dataEditFields: DataEditFieldUpsert[]
        removeMissingFields: boolean
    }) => Promise<{
        fields: DataEditField[]
    }>,
] => {
    const [dataEditFields] = useOrganisationContext.useDataEditFields()
    const [allHomeItemQuery] = useQueryAllHomeItemData(organisationIdStr)
    const [listCategories] = useQueryListCategories(organisationIdStr)
    const alert = useAlert()
    const mutation = useMutation<DataEditUpdateFields, DataEditUpdateFieldsVariables>(DATA_EDIT_UPDATE_FIELDS, {})
    const callMutation = async (variables: {dataEditFields: DataEditFieldUpsert[]; removeMissingFields: boolean}) => {
        try {
            const result = await mutation[0]({variables: {organisationIdStr, ...variables}})
            const merged = mergeDataEditFields(
                dataEditFields,
                result.data!.updateDataEditFields.map(hydrateDataEditFieldTypes),
                variables.removeMissingFields,
            )
            allHomeItemQuery.refetch()
            listCategories.refetch()
            return {fields: merged}
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
