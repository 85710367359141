import {gql, useQuery} from '@apollo/client'
import {useMemo} from 'react'
import {createContainer} from 'unstated-next'
import {UserSettings} from '~/components/UserSettingsContext'
import {RoleType} from '~/objectTypes'
import {getHRISName} from './Integrations/integrations'
import {Token} from './TokenContext'
import {CurrentOrganisationData} from './__types__/CurrentOrganisationData'

export const CURRENT_ORGANISATION_DATA = gql`
    query CurrentOrganisationData {
        organisations {
            idStr
            name
            currentUserRoles
            showADPOnboarding
            primaryDataSourceType
            orgChart
            editableProfile {
                idStr
                profilePictureActionRequired
            }
            messageOptions
            appFeatures {
                orgChart
                photoApproval
                photoCollection
                weeklyUserEmail
                meetings
                downloadPhotos
                downloadData
                useRulesBasedImport
                inAppData
                communityBuild
                masterDirectory
                multiplePhotosPerProfile
            }
            scenario
            parentIdStr
            forms {
                idStr
            }
        }
    }
`

const useCurrentOrganisationState = () => {
    const {token} = Token.useContainer()
    const {data, error, loading, refetch} = useQuery<CurrentOrganisationData>(CURRENT_ORGANISATION_DATA, {
        skip: !token,
    })
    const {userSettings} = UserSettings.useContainer()
    const currentOrganisation = useMemo(() => {
        if (!data) return
        if (!userSettings.currentOrganisation) return data.organisations[0]
        return (
            data.organisations.find((organisation) => organisation.idStr === userSettings.currentOrganisation) ??
            data.organisations[0]
        )
    }, [userSettings, data])

    const orgChartEnabled = !!(currentOrganisation?.orgChart && currentOrganisation?.appFeatures.orgChart)
    const hrisProvider = getHRISName(currentOrganisation?.primaryDataSourceType)
    const isAdmin = currentOrganisation?.currentUserRoles.includes(RoleType.Manage)

    if (error) {
        return {error, hrisProvider}
    }

    if (loading || !data) return {loading, hrisProvider}

    return {
        currentOrganisation,
        hrisProvider,
        isAdmin,
        orgChartEnabled,
        refetch: async () => {
            await refetch()
        },
    }
}

//This probally doesn't need to be a provider anymore
export const CurrentOrganisation = createContainer(useCurrentOrganisationState)
export const useCurrentOrganisation = CurrentOrganisation.useContainer
