import * as React from 'react'
const SvgHomeRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgHomeRegular(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M5.1 9.131H1.8c-.91 0-1.715.737-1.794 1.632L0 10.906v3.32c0 .91.76 1.693 1.657 1.769l.143.006h3.3c.97 0 1.721-.705 1.794-1.628l.006-.147v-3.32c0-.945-.704-1.696-1.649-1.77L5.1 9.132zm9.2 0h-3.4c-.884 0-1.619.713-1.694 1.622l-.006.153v3.32c0 .93.688 1.69 1.554 1.768l.146.007h3.4c.884 0 1.619-.713 1.694-1.622l.006-.153v-3.32c0-.93-.688-1.69-1.554-1.768L14.3 9.13zm-9.2 1.06c.414 0 .657.25.695.613l.005.102v3.32c0 .382-.209.664-.591.709l-.109.006H1.8a.722.722 0 01-.693-.62l-.007-.095v-3.32c0-.356.275-.66.608-.708l.092-.007h3.3zm9.2 0c.342 0 .645.275.693.62l.007.095v3.32c0 .355-.275.66-.608.708l-.092.007h-3.4a.722.722 0 01-.693-.62l-.007-.095v-3.32c0-.356.275-.66.608-.708l.092-.007h3.4zM5.1 0H1.8C.89 0 .085.737.006 1.632L0 1.775v3.32c0 .91.761 1.694 1.657 1.77l.143.006h3.3c.97 0 1.721-.705 1.794-1.628l.006-.148v-3.32C6.9.83 6.196.079 5.251.005L5.1 0zm9.2 0h-3.4c-.884 0-1.619.713-1.694 1.622l-.006.153v3.32c0 .93.688 1.692 1.554 1.77l.146.006h3.4c.884 0 1.619-.713 1.694-1.623L16 5.095v-3.32c0-.93-.688-1.69-1.554-1.768L14.3 0zM5.1 1.06c.414 0 .657.25.695.613l.005.102v3.32c0 .383-.208.665-.591.709L5.1 5.81H1.8a.721.721 0 01-.693-.62L1.1 5.096v-3.32c0-.355.275-.66.608-.708L1.8 1.06h3.3zm9.2 0c.342 0 .645.275.693.62l.007.095v3.32c0 .356-.275.66-.608.708l-.092.007h-3.4a.721.721 0 01-.693-.62l-.007-.095v-3.32c0-.355.275-.66.608-.708l.092-.007h3.4z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgHomeRegular
