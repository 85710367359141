import {useQuery} from '@apollo/client'
import {AnimatePresence, motion} from 'framer-motion'
import {Typography} from 'nf-ui'
import React, {useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {useQueryProfileLines} from '~/apollo/queries/ProfileLine'
import {PageLoading} from '~/components/PageLoading'
import {PhonePreviewProfile, SAMPLE_PROFILE} from '~/components/PhonePreview/PhonePreviewProfile'
import {SampleProfile, SampleProfileVariables} from '~/components/PhonePreview/__types__/SampleProfile'
import {SidePanelLayout} from '~/components/SidePanelLayout'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {EditProfilePicture} from './EditProfilePicture'
import {FieldList} from './FieldList'
import {DESIGN_PROFILE} from './queries'
import {DesignProfileData, DesignProfileDataVariables} from './__types__/DesignProfileData'
import {useOrganisationContext} from '~/components/OrganisationContext'

const fadeProps = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
}

const FullHeightMotion = styled(motion.div)`
    height: 100%;
`

export const DesignProfile: React.FC = () => {
    const organisationIdStr = useOrganisationIdStr()
    const [profileLinesQuery, profileLines] = useQueryProfileLines(organisationIdStr)
    const [refreshing] = useOrganisationContext.useOrganisationRefreshing()
    const [fields] = useOrganisationContext.useDataEditFields()

    const designProfileQuery = useQuery<DesignProfileData, DesignProfileDataVariables>(DESIGN_PROFILE, {
        variables: {organisationIdStr},
    })

    const sampleProfileQuery = useQuery<SampleProfile, SampleProfileVariables>(SAMPLE_PROFILE, {
        variables: {organisationIdStr},
    })

    const [hoveringId, setHoveringId] = useState<string | undefined>()
    const routeMatch = useRouteMatch<{idStr?: string}>('*/edit/:idStr')
    const editId = routeMatch?.params.idStr
    const editProfilePicture = editId === 'profilePicture'

    const error = profileLinesQuery.error || refreshing?.error || designProfileQuery.error || sampleProfileQuery.error
    const loading =
        profileLinesQuery.loading || refreshing?.loading || designProfileQuery.loading || sampleProfileQuery.loading

    if (error) return <Typography.Paragraph>Error: {getGraphQLErrorMessage(error)}</Typography.Paragraph>
    if (loading || !profileLinesQuery.data || !designProfileQuery.data || !sampleProfileQuery.data)
        return <PageLoading />

    const organisation = designProfileQuery.data.organisation
    const sampleProfile = sampleProfileQuery.data.organisation.sampleProfile
    const {canEditProfilePicture} = designProfileQuery.data.organisation.userRestrictions

    // TODO: We may have to deal with this case in a better way
    if (!sampleProfile) {
        return <Typography.Heading>Error: You do not have any profiles in your directory.</Typography.Heading>
    }

    const renderProfileDesign = () => {
        if (editProfilePicture) {
            return (
                <FullHeightMotion key="editProfilePicture" {...fadeProps}>
                    <EditProfilePicture canEditProfilePicture={canEditProfilePicture} />
                </FullHeightMotion>
            )
        }

        return (
            <motion.div key="fieldList" {...fadeProps}>
                <FieldList profileLines={profileLines} fields={fields} setHoveringId={setHoveringId} />
            </motion.div>
        )
    }

    return (
        <SidePanelLayout
            leftContent={
                <PhonePreviewProfile
                    profile={sampleProfile}
                    profileLines={profileLines}
                    accentColor={organisation.accentColor}
                    hoveringId={editId || hoveringId}
                />
            }
        >
            <AnimatePresence initial={false} exitBeforeEnter>
                {renderProfileDesign()}
            </AnimatePresence>
        </SidePanelLayout>
    )
}
