export type Maybe<T> = T | null
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any
    /** The `Upload` scalar type represents a file upload. */
    Upload: any
}

export type Query = {
    __typename: 'Query'
    getEmailUsed: Scalars['Boolean']
    isTokenValid: TokenValidResult
    signInOptions: Array<SignInMethod>
    systemAccessCheck: Array<SystemAccessOption>
    adpSignInOption: SignInMethod
    childCategory: ChildCategoryObject
    childCategories: Array<ChildCategoryObject>
    dataCollectionForm: DataCollectionForm
    availableFields: Array<AvailableFieldObject>
    availableFieldTypes: Array<AvailableFieldTypeObject>
    parseCsvOrExcel: Scalars['JSON']
    googleSheetContents: Scalars['JSON']
    dataSource: Maybe<DataSourceObject>
    fetchAuthToken: Maybe<Scalars['String']>
    field: FieldObject
    mCategory: FieldObject
    cloudfrontCookies: Array<CookieObject>
    meetingsThisWeek: Array<Meeting>
    googleCalendarOAuthUrl: Scalars['String']
    fetchOAuthUrl: Scalars['String']
    generateOAuthCredentials: OAuthCredentials
    googleDeveloperKey: Scalars['String']
    googleAppId: Scalars['String']
    hiddenProfiles: Array<HiddenProfile>
    organisations: Array<OrganisationObject>
    organisation: OrganisationObject
    verifyPhotoCollectionToken: VerifyTokenResponse
    listPhotos: Array<PhotoObject>
    matchPhotosByFieldCount: Scalars['Float']
    profile: ProfileObject
    profiles: Array<ProfileObject>
    additionalDataToken: Scalars['String']
    scenarios: Array<ScenarioObject>
    superAdminOrganisations: Array<SuperAdminOrganisationObject>
    superAdminOrganisation: SuperAdminOrganisationObject
    superAdminUsers: Array<UserObject>
    userDiagnostics: Array<UserDiagnostic>
    listPaychexCompanies: Array<PaychexCompany>
    me: Maybe<UserObject>
    welcomeUserDetails: WelcomeUserDetailsObject
    dataEditFields: Array<DataEditField>
    dataEditValues: Array<DataEditValue>
    forms: Array<Form>
    responses: Array<Response>
    profileLines: Array<ProfileLine>
}

export type QueryGetEmailUsedArgs = {
    email: Scalars['String']
}

export type QueryIsTokenValidArgs = {
    token: Scalars['String']
}

export type QuerySignInOptionsArgs = {
    email: Scalars['String']
}

export type QueryChildCategoryArgs = {
    idStr: Scalars['String']
}

export type QueryChildCategoriesArgs = {
    organisationIdStr: Scalars['String']
}

export type QueryDataCollectionFormArgs = {
    token: Scalars['String']
}

export type QueryAvailableFieldsArgs = {
    organisationIdStr: Scalars['String']
}

export type QueryParseCsvOrExcelArgs = {
    file: Scalars['Upload']
}

export type QueryGoogleSheetContentsArgs = {
    config: GoogleSheetsData
}

export type QueryDataSourceArgs = {
    dataSourceIdStr: Scalars['String']
}

export type QueryFetchAuthTokenArgs = {
    dataSourceIdStr: Scalars['String']
}

export type QueryFieldArgs = {
    idStr: Scalars['String']
}

export type QueryMCategoryArgs = {
    idStr: Scalars['String']
}

export type QueryMeetingsThisWeekArgs = {
    organisationIdStr: Maybe<Scalars['String']>
}

export type QueryFetchOAuthUrlArgs = {
    oAuthProvider: OAuthProvider
}

export type QueryGenerateOAuthCredentialsArgs = {
    code: Scalars['String']
    oAuthProvider: OAuthProvider
}

export type QueryHiddenProfilesArgs = {
    organisationIdStr: Scalars['String']
}

export type QueryOrganisationArgs = {
    idStr: Scalars['String']
}

export type QueryVerifyPhotoCollectionTokenArgs = {
    token: Scalars['String']
}

export type QueryListPhotosArgs = {
    organisationIdStr: Scalars['String']
}

export type QueryMatchPhotosByFieldCountArgs = {
    fieldIdStr: Scalars['String']
}

export type QueryProfileArgs = {
    idStr: Scalars['String']
}

export type QueryProfilesArgs = {
    includeOpenPositions?: Maybe<Scalars['Boolean']>
    limit: Maybe<Scalars['Int']>
    profileIdStrs: Maybe<Array<Scalars['String']>>
    organisationIdStr: Scalars['String']
}

export type QueryAdditionalDataTokenArgs = {
    profileIdStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type QueryScenariosArgs = {
    organisationIdStr: Scalars['String']
}

export type QuerySuperAdminOrganisationArgs = {
    idStr: Scalars['String']
}

export type QueryUserDiagnosticsArgs = {
    email: Scalars['String']
}

export type QueryWelcomeUserDetailsArgs = {
    userIdStr: Maybe<Scalars['String']>
}

export type QueryDataEditFieldsArgs = {
    fieldName: Maybe<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type QueryDataEditValuesArgs = {
    hiddenValuesOnly: Maybe<Scalars['Boolean']>
    hideHideableFields: Maybe<Scalars['Boolean']>
    useProfileLabels: Maybe<Scalars['Boolean']>
    fieldName: Maybe<Scalars['String']>
    profileIdStr: Maybe<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type QueryFormsArgs = {
    organisationIdStr: Maybe<Scalars['String']>
    formIdStr: Maybe<Scalars['String']>
}

export type QueryResponsesArgs = {
    emailAddress: Scalars['String']
    formIdStr: Scalars['String']
}

export type QueryProfileLinesArgs = {
    organisationIdStr: Scalars['String']
}

export enum TokenValidResult {
    Valid = 'Valid',
    Invalid = 'Invalid',
    Expired = 'Expired',
}

export type SignInMethod = {
    __typename: 'SignInMethod'
    type: SignInMethodType
    name: Maybe<Scalars['String']>
    icon: Maybe<SignInMethodIcon>
    public_config: Maybe<PublicConfig>
}

export enum SignInMethodType {
    OAUTH = 'OAUTH',
    NF_AUTH_PASSWORD = 'NF_AUTH_PASSWORD',
    SET_PASSWORD = 'SET_PASSWORD',
    NF_AUTH_MAGIC_LINK = 'NF_AUTH_MAGIC_LINK',
    OTP = 'OTP',
    VERIFY_SIGN_IN = 'VERIFY_SIGN_IN',
}

export enum SignInMethodIcon {
    OKTA = 'OKTA',
    AZURE = 'AZURE',
    ADP = 'ADP',
}

export type PublicConfig = {
    __typename: 'PublicConfig'
    scope: Scalars['String']
    client_id: Scalars['String']
    authorize_uri: Scalars['String']
    response_type: Scalars['String']
    identity_provider: Maybe<Scalars['String']>
}

export enum SystemAccessOption {
    LEGACY = 'LEGACY',
    SELF_BUILD = 'SELF_BUILD',
}

export type ChildCategoryObject = {
    __typename: 'ChildCategoryObject'
    idStr: Scalars['ID']
    visible: Scalars['Boolean']
    hideable: Scalars['Boolean']
    priority: Scalars['Float']
    profiles: Array<ProfileObject>
    profileIdStrs: Array<Scalars['String']>
    profilesCount: Scalars['Int']
    name: Scalars['String']
    label: Scalars['String']
    combinedCategories: Array<Scalars['String']>
}

export type ProfileObject = {
    __typename: 'ProfileObject'
    idStr: Scalars['ID']
    photoRequested: Scalars['Boolean']
    additional: Scalars['Boolean']
    openPosition: Scalars['Boolean']
    managerIdStr: Maybe<Scalars['String']>
    originalManagerIdStr: Maybe<Scalars['String']>
    managerEdited: Scalars['Boolean']
    openPositionJobLink: Maybe<Scalars['String']>
    directReports: Array<Scalars['String']>
    reportsTo: Maybe<Scalars['String']>
    additionalManagers: Array<Scalars['String']>
    additionalReports: Array<Scalars['String']>
    photo: Maybe<PhotoObject>
    photos: Maybe<Array<PhotoObject>>
    pendingPhoto: Maybe<PhotoObject>
    createdAt: Scalars['Float']
    firstName: Scalars['String']
    lastName: Scalars['String']
    fullName: Scalars['String']
    email: Scalars['String']
    secondaryLine: Maybe<Scalars['String']>
    organisation: Maybe<OrganisationObject>
    hasLinkedUser: Scalars['Boolean']
    profileLines: Array<ProfileLineObject>
    childCategoryIdStrs: Array<Scalars['String']>
    dataCollectionStatus: DataCollectionStatus
    primaryFields: Array<ProfileLineObject>
    additionalFields: Array<FormField>
    canEdit: Scalars['Boolean']
    canEditProfilePicture: Scalars['Boolean']
    profilePictureActionRequired: Scalars['Boolean']
    baseDirectoryIdStr: Maybe<Scalars['String']>
}

export type ProfileObjectProfileLinesArgs = {
    includeEmptyValues: Maybe<Scalars['Boolean']>
}

export type ProfileObjectAdditionalFieldsArgs = {
    visibility?: Maybe<Visibility>
}

export type PhotoObject = {
    __typename: 'PhotoObject'
    idStr: Scalars['String']
    originalFile: FileObject
    profileFile: FileObject
    thumbFile: FileObject
    priority: Maybe<Scalars['Int']>
    createdAt: Scalars['Float']
    profile: Maybe<ProfileObject>
    pendingProfile: Maybe<ProfileObject>
    originalUrl: Scalars['String']
    profileUrl: Scalars['String']
    thumbUrl: Scalars['String']
    tinyUrl: Maybe<Scalars['String']>
    signedProfileUrl: Scalars['String']
    signedTinyUrl: Maybe<Scalars['String']>
    filename: Scalars['String']
}

export type FileObject = {
    __typename: 'FileObject'
    idStr: Scalars['ID']
    bucket: Scalars['String']
    key: Scalars['String']
    mime: Scalars['String']
    filename: Scalars['String']
    url: Scalars['String']
    signedUrl: Scalars['String']
}

export type OrganisationObject = {
    __typename: 'OrganisationObject'
    idStr: Scalars['ID']
    name: Scalars['String']
    accentColor: Scalars['String']
    autoInvite: Scalars['Boolean']
    logo: Maybe<LogoObject>
    categoryChangesThisMonth: Array<SmartGroup>
    editableProfile: Maybe<ProfileObject>
    fields: Array<FieldObject>
    primaryFields: Array<FieldObject>
    additionalFields: Array<AdditionalFieldObject>
    profiles: Array<ProfileObject>
    profilesCount: Scalars['Int']
    dataEditFields: Array<DataEditField>
    dataEditValues: Array<DataEditValue>
    profileLines: Array<ProfileLine>
    datasources: Array<DataSourceObject>
    photos: Array<PhotoObject>
    photoImportPercentage: Maybe<Scalars['Float']>
    currentUserRoles: Array<RoleType>
    userManagementAccessible: Scalars['Boolean']
    showADPOnboarding: Scalars['Boolean']
    smartGroups: Array<SmartGroup>
    messageOptions: Array<MessageOption>
    userRestrictions: UserRestrictions
    appFeatures: AppFeatures
    linkedDirectories: Array<LinkedDirectory>
    enabledExperiments: Array<Scalars['String']>
    sampleProfile: Maybe<ProfileObject>
    homeItems: Array<HomeItemObject>
    currentUser: UserObject
    users: Array<UserObject>
    admins: Array<UserObject>
    forms: Array<FormObject>
    recentSearches: Maybe<Array<ProfileObject>>
    dataSourceRequests: Maybe<Array<DataSourceRequestObject>>
    primaryDataSourceType: Maybe<DataSourceType>
    orgChart: Scalars['Boolean']
    scenario: Scalars['Boolean']
    parentIdStr: Maybe<Scalars['String']>
}

export type OrganisationObjectProfilesArgs = {
    includeOpenPositions: Maybe<Scalars['Boolean']>
    profileIdStrs: Maybe<Array<Scalars['String']>>
}

export type OrganisationObjectDataEditFieldsArgs = {
    organisationIdStr: Scalars['String']
}

export type OrganisationObjectDataEditValuesArgs = {
    organisationIdStr: Scalars['String']
}

export type OrganisationObjectProfileLinesArgs = {
    organisationIdStr: Scalars['String']
}

export type OrganisationObjectSmartGroupsArgs = {
    limit: Maybe<Scalars['Int']>
}

export type OrganisationObjectHomeItemsArgs = {
    visibility: Maybe<Visibility>
}

export type LogoObject = {
    __typename: 'LogoObject'
    idStr: Scalars['ID']
    height: Scalars['Float']
    file: FileObject
}

export type SmartGroup = {
    __typename: 'SmartGroup'
    idStr: Scalars['ID']
    label: Scalars['String']
    profiles: Array<SmartGroupProfile>
    weeklyCount: Maybe<Scalars['Float']>
}

export type SmartGroupProfile = {
    __typename: 'SmartGroupProfile'
    profileIdStr: Scalars['ID']
    secondaryLine: Maybe<Scalars['String']>
    profile: ProfileObject
}

export type FieldObject = {
    __typename: 'FieldObject'
    idStr: Scalars['ID']
    type: Maybe<FieldType>
    name: Scalars['String']
    visible: Scalars['Boolean']
    hideable: Scalars['Boolean']
    priority: Scalars['Float']
    additional: Scalars['Boolean']
    profilesCount: Scalars['Int']
    options: Maybe<Array<ChildCategoryObject>>
    childCategories: Array<ChildCategoryObject>
    profiles: Array<ProfileObject>
    label: Scalars['String']
    helpText: Maybe<Scalars['String']>
}

export enum FieldType {
    TEXT = 'TEXT',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    EMAIL = 'EMAIL',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    LANDLINE_NUMBER = 'LANDLINE_NUMBER',
    CATEGORY = 'CATEGORY',
    DATE = 'DATE',
    SLACK = 'SLACK',
    SKYPE = 'SKYPE',
    MS_TEAMS = 'MS_TEAMS',
    URL = 'URL',
}

export type AdditionalFieldObject = {
    __typename: 'AdditionalFieldObject'
    idStr: Scalars['ID']
    name: Scalars['String']
    helpText: Maybe<Scalars['String']>
    type: AdditionalFieldType
    options: Maybe<Array<AdditionalFieldOption>>
}

export enum AdditionalFieldType {
    TEXT = 'TEXT',
    EMAIL = 'EMAIL',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    DATE = 'DATE',
    SELECT_ONE = 'SELECT_ONE',
    SELECT_MULTIPLE = 'SELECT_MULTIPLE',
    URL = 'URL',
}

export type AdditionalFieldOption = {
    __typename: 'AdditionalFieldOption'
    idStr: Scalars['ID']
    name: Scalars['String']
}

export type DataEditField = {
    __typename: 'DataEditField'
    idStr: Scalars['ID']
    organisationIdStr: Scalars['String']
    type: Scalars['String']
    subType: Maybe<Scalars['String']>
    name: Scalars['String']
    label: Maybe<Scalars['String']>
    availableValues: Array<Scalars['String']>
    availableValueIdStrs: Array<Scalars['String']>
    availableValueLabels: Maybe<Array<Scalars['String']>>
    usersCanCreateValues: Maybe<Scalars['Boolean']>
    baseFields: Maybe<Array<DataEditField>>
}

export type DataEditValue = {
    __typename: 'DataEditValue'
    fieldOrParentCategoryIdStr: Scalars['String']
    profileIdStr: Scalars['String']
    value: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type ProfileLine = {
    __typename: 'ProfileLine'
    idStr: Scalars['String']
    priority: Scalars['Float']
    fieldOrCategoryIdStrs: Array<Scalars['String']>
    showField: Scalars['Boolean']
    showLabel: Scalars['Boolean']
    userCanEdit: Scalars['Boolean']
    userCanHide: Scalars['Boolean']
    sectionLineBelow: Scalars['Boolean']
    fieldStyle: Scalars['String']
    labelStyle: Scalars['String']
    labelDescription: Maybe<Scalars['String']>
    fieldBehaviour: Scalars['String']
    prefix: Scalars['String']
    separator: Scalars['String']
    suffix: Scalars['String']
    hideYear: Scalars['Boolean']
    organisationIdStr: Maybe<Scalars['String']>
}

export type DataSourceObject = {
    __typename: 'DataSourceObject'
    idStr: Scalars['String']
    name: Scalars['String']
    profileCount: Scalars['Float']
    type: DataSourceType
    lastError: Maybe<Scalars['String']>
    numPhotosImported: Maybe<Scalars['Float']>
    organisation: Maybe<OrganisationObject>
    fileType: Maybe<DataSourceFileType>
    syncing: Scalars['Boolean']
    data: Maybe<DataSourceDataObject>
    directUploadFileUrl: Maybe<Scalars['String']>
    googleSheetUrl: Maybe<Scalars['String']>
    latestData: Maybe<Scalars['JSON']>
    latestDatasetUrl: Maybe<Scalars['String']>
    importConfig: Maybe<ImportConfig>
    dataIndex: Maybe<Scalars['JSON']>
}

export enum DataSourceType {
    ADP = 'ADP',
    PAYCHEX = 'PAYCHEX',
    PAYLOCITY = 'PAYLOCITY',
    DIRECT_UPLOAD = 'DIRECT_UPLOAD',
    GOOGLE_SHEETS = 'GOOGLE_SHEETS',
    WORKDAY_REPORT = 'WORKDAY_REPORT',
}

export enum DataSourceFileType {
    CSV = 'CSV',
    XLS = 'XLS',
}

export type DataSourceDataObject = {
    __typename: 'DataSourceDataObject'
    companyId: Maybe<Scalars['String']>
    type: Maybe<Scalars['String']>
    sheetId: Maybe<Scalars['String']>
}

export type ImportConfig = {
    __typename: 'ImportConfig'
    fieldRules: Array<FieldRule>
    filterRules: Array<Scalars['String']>
    updatedAt: Maybe<Scalars['DateTime']>
    lastImport: Maybe<LastImport>
}

export type FieldRule = {
    __typename: 'FieldRule'
    fieldName: Scalars['String']
    fieldType: Scalars['String']
    isKeyField: Scalars['Boolean']
    expression: Scalars['String']
}

export type LastImport = {
    __typename: 'LastImport'
    fieldRules: Array<FieldRule>
    filterRules: Array<Scalars['String']>
    startedDate: Maybe<Scalars['DateTime']>
    datasetCompletedDate: Maybe<Scalars['DateTime']>
    directoryCompletedDate: Maybe<Scalars['DateTime']>
}

export enum RoleType {
    Manage = 'Manage',
    View = 'View',
}

export enum MessageOption {
    EMAIL = 'EMAIL',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
}

export type UserRestrictions = {
    __typename: 'UserRestrictions'
    canEditProfilePicture: Scalars['Boolean']
    canEditAdditionalFields: Scalars['Boolean']
}

export type AppFeatures = {
    __typename: 'AppFeatures'
    orgChart: Scalars['Boolean']
    photoApproval: Scalars['Boolean']
    photoCollection: Scalars['Boolean']
    weeklyUserEmail: Scalars['Boolean']
    groupMessage: Scalars['Boolean']
    meetings: Scalars['Boolean']
    downloadPhotos: Scalars['Boolean']
    downloadData: Scalars['Boolean']
    profileThreshold: Scalars['Boolean']
    useRulesBasedImport: Scalars['Boolean']
    inAppData: Scalars['Boolean']
    communityBuild: Scalars['Boolean']
    masterDirectory: Scalars['Boolean']
    multiplePhotosPerProfile: Scalars['Boolean']
}

export type LinkedDirectory = {
    __typename: 'LinkedDirectory'
    masterOrganisationIdStr: Scalars['String']
    baseOrganisationIdStr: Scalars['String']
    isAccessibleByOtherBaseDirectoriesInMaster: Scalars['Boolean']
    canAccessAllOtherBaseDirectoriesInMaster: Scalars['Boolean']
}

export type HomeItemObject = {
    __typename: 'HomeItemObject'
    idStr: Scalars['String']
    priority: Scalars['Float']
    label: Scalars['String']
    visible: Scalars['Boolean']
    type: HomeItemType
    displayMode: Maybe<HomeItemDisplayMode>
    sourceIdStr: Maybe<Scalars['String']>
    childCategories: Maybe<Array<ChildCategoryObject>>
    profilesCount: Scalars['Int']
}

export enum HomeItemType {
    ALL = 'ALL',
    CATEGORY = 'CATEGORY',
    CHILD_CATEGORY = 'CHILD_CATEGORY',
}

export enum HomeItemDisplayMode {
    LIST = 'LIST',
    GROUPED = 'GROUPED',
}

export enum Visibility {
    VISIBLE = 'VISIBLE',
    HIDDEN = 'HIDDEN',
    BOTH = 'BOTH',
}

export type UserObject = {
    __typename: 'UserObject'
    idStr: Scalars['ID']
    firstName: Scalars['String']
    lastName: Scalars['String']
    isSuperAdmin: Maybe<Scalars['Boolean']>
    subId: Maybe<Scalars['String']>
    isEmailVerified: Scalars['Boolean']
    email: Maybe<Scalars['String']>
    fullName: Scalars['String']
    role: UserRole
    inviteStatus: Maybe<InviteStatus>
    hasAcceptedTerms: Scalars['Boolean']
    hasViewedUserland: Scalars['Boolean']
    hasViewedOrgChartPanel: Scalars['Boolean']
    hasViewedMeetings: Scalars['Boolean']
    enabledExperiments: Array<Scalars['String']>
    profiles: Array<ProfileObject>
    isRevoked: Maybe<Scalars['Boolean']>
    npsOutstanding: Maybe<Scalars['Boolean']>
    calendarConnected: Maybe<Scalars['Boolean']>
}

export enum UserRole {
    Admin = 'Admin',
    AdminUser = 'AdminUser',
    AdminGuest = 'AdminGuest',
    User = 'User',
    Guest = 'Guest',
}

export enum InviteStatus {
    PENDING = 'PENDING',
    INVITED = 'INVITED',
    ACTIVE = 'ACTIVE',
}

export type FormObject = {
    __typename: 'FormObject'
    idStr: Scalars['String']
}

export type DataSourceRequestObject = {
    __typename: 'DataSourceRequestObject'
    idStr: Scalars['ID']
    dataSource: DataSourceRequestType
}

export enum DataSourceRequestType {
    ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY',
    ADP = 'ADP',
    AIRTABLE = 'AIRTABLE',
    BAMBOOHR = 'BAMBOOHR',
    GOOGLE_DRIVE = 'GOOGLE_DRIVE',
    GUSTO = 'GUSTO',
    HIBOB = 'HIBOB',
    KRONOS = 'KRONOS',
    MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
    NAMELY = 'NAMELY',
    ORACLE = 'ORACLE',
    OTHER = 'OTHER',
    PAYCHEX = 'PAYCHEX',
    PAYCOM = 'PAYCOM',
    PAYLOCITY = 'PAYLOCITY',
    RIPPLING = 'RIPPLING',
    SAGE_PEOPLE = 'SAGE_PEOPLE',
    SALESFORCE = 'SALESFORCE',
    SAP_SUCCESS_FACTORS = 'SAP_SUCCESS_FACTORS',
    SHAREPOINT = 'SHAREPOINT',
    SLACK = 'SLACK',
    TRINET = 'TRINET',
    ULTIPRO = 'ULTIPRO',
    WORKDAY = 'WORKDAY',
    ZENEFITS = 'ZENEFITS',
}

export type ProfileLineObject = {
    __typename: 'ProfileLineObject'
    idStr: Scalars['ID']
    label: Scalars['String']
    value: Scalars['String']
    type: ProfileLineType
    extraData: ExtraDataObject
}

export enum ProfileLineType {
    TEXT = 'TEXT',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    EMAIL = 'EMAIL',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    LANDLINE_NUMBER = 'LANDLINE_NUMBER',
    CATEGORY = 'CATEGORY',
    DATE = 'DATE',
    SLACK = 'SLACK',
    MS_TEAMS = 'MS_TEAMS',
    SKYPE = 'SKYPE',
    URL = 'URL',
}

export type ExtraDataObject = {
    __typename: 'ExtraDataObject'
    dateMask: Maybe<Scalars['String']>
    helpText: Maybe<Scalars['String']>
    childCategories: Maybe<Array<ChildCategoryObject>>
    options: Maybe<Array<ChildCategoryObject>>
    profileLineTemplateIdStr: Scalars['String']
    fieldIdStr: Scalars['String']
    hiddenFromProfile: Scalars['Boolean']
    hideable: Maybe<Scalars['Boolean']>
}

export enum DataCollectionStatus {
    RESPONDED_ALL = 'RESPONDED_ALL',
    RESPONDED_SOME = 'RESPONDED_SOME',
    RESPONDED_NONE = 'RESPONDED_NONE',
    NOT_SENT = 'NOT_SENT',
}

export type FormField = {
    __typename: 'FormField'
    idStr: Scalars['String']
    name: Scalars['String']
    value: Maybe<Scalars['String']>
    options: Maybe<Array<FormFieldOption>>
    type: AdditionalFieldType
    helpText: Maybe<Scalars['String']>
    extraData: Maybe<ExtraDataObject>
}

export type FormFieldOption = {
    __typename: 'FormFieldOption'
    idStr: Scalars['ID']
    name: Scalars['String']
    selected: Scalars['Boolean']
}

export type DataCollectionForm = {
    __typename: 'DataCollectionForm'
    organisation: Scalars['String']
    admin: DataCollectionAdmin
    formFields: Array<FormField>
    profile: DataCollectionProfile
}

export type DataCollectionAdmin = {
    __typename: 'DataCollectionAdmin'
    name: Scalars['String']
    email: Scalars['String']
}

export type DataCollectionProfile = {
    __typename: 'DataCollectionProfile'
    name: Scalars['String']
}

export type AvailableFieldObject = {
    __typename: 'AvailableFieldObject'
    idStr: Scalars['String']
    name: Scalars['String']
    type: FieldType
}

export type AvailableFieldTypeObject = {
    __typename: 'AvailableFieldTypeObject'
    name: Scalars['String']
    type: FieldType
    named: Maybe<Scalars['Boolean']>
}

export type GoogleSheetsData = {
    sheetId: Maybe<Scalars['String']>
    refreshToken: Maybe<Scalars['String']>
}

export type CookieObject = {
    __typename: 'CookieObject'
    name: Scalars['String']
    value: Scalars['String']
    expires: Scalars['Float']
    domain: Scalars['String']
}

export type Meeting = {
    __typename: 'Meeting'
    idStr: Scalars['String']
    name: Maybe<Scalars['String']>
    startTime: Scalars['String']
    endTime: Scalars['String']
    allDay: Scalars['Boolean']
    meetingLink: Maybe<Scalars['String']>
    attendees: Array<Attendee>
}

export type Attendee = {
    __typename: 'Attendee'
    idStr: Scalars['String']
    firstName: Maybe<Scalars['String']>
    lastName: Maybe<Scalars['String']>
    email: Scalars['String']
    photoUrl: Maybe<Scalars['String']>
    guest: Scalars['Boolean']
    profileIdStr: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    company: Maybe<Scalars['String']>
    location: Maybe<Scalars['String']>
    bio: Maybe<Scalars['String']>
    linkedIn: Maybe<Scalars['String']>
}

/** OAuth provider to do authorization flow for */
export enum OAuthProvider {
    GOOGLE = 'GOOGLE',
}

export type OAuthCredentials = {
    __typename: 'OAuthCredentials'
    token: Scalars['String']
    refreshToken: Scalars['String']
}

export type HiddenProfile = {
    __typename: 'HiddenProfile'
    externalId: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
}

export type VerifyTokenResponse = {
    __typename: 'VerifyTokenResponse'
    firstName: Scalars['String']
    organisationName: Scalars['String']
}

export type ScenarioObject = {
    __typename: 'ScenarioObject'
    idStr: Scalars['ID']
    name: Scalars['String']
    accentColor: Scalars['String']
    autoInvite: Scalars['Boolean']
    logo: Maybe<LogoObject>
    categoryChangesThisMonth: Array<SmartGroup>
    editableProfile: Maybe<ProfileObject>
    fields: Array<FieldObject>
    primaryFields: Array<FieldObject>
    additionalFields: Array<AdditionalFieldObject>
    profiles: Array<ProfileObject>
    profilesCount: Scalars['Int']
    dataEditFields: Array<DataEditField>
    dataEditValues: Array<DataEditValue>
    profileLines: Array<ProfileLine>
    datasources: Array<DataSourceObject>
    photos: Array<PhotoObject>
    photoImportPercentage: Maybe<Scalars['Float']>
    currentUserRoles: Array<RoleType>
    userManagementAccessible: Scalars['Boolean']
    showADPOnboarding: Scalars['Boolean']
    smartGroups: Array<SmartGroup>
    messageOptions: Array<MessageOption>
    userRestrictions: UserRestrictions
    appFeatures: AppFeatures
    linkedDirectories: Array<LinkedDirectory>
    enabledExperiments: Array<Scalars['String']>
    sampleProfile: Maybe<ProfileObject>
    homeItems: Array<HomeItemObject>
    currentUser: UserObject
    users: Array<UserObject>
    admins: Array<UserObject>
    forms: Array<FormObject>
    recentSearches: Maybe<Array<ProfileObject>>
    dataSourceRequests: Maybe<Array<DataSourceRequestObject>>
    primaryDataSourceType: Maybe<DataSourceType>
    orgChart: Scalars['Boolean']
    scenario: Scalars['Boolean']
    parentIdStr: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    owner: Maybe<UserObject>
}

export type ScenarioObjectProfilesArgs = {
    includeOpenPositions: Maybe<Scalars['Boolean']>
    profileIdStrs: Maybe<Array<Scalars['String']>>
}

export type ScenarioObjectDataEditFieldsArgs = {
    organisationIdStr: Scalars['String']
}

export type ScenarioObjectDataEditValuesArgs = {
    organisationIdStr: Scalars['String']
}

export type ScenarioObjectProfileLinesArgs = {
    organisationIdStr: Scalars['String']
}

export type ScenarioObjectSmartGroupsArgs = {
    limit: Maybe<Scalars['Int']>
}

export type ScenarioObjectHomeItemsArgs = {
    visibility: Maybe<Visibility>
}

export type SuperAdminOrganisationObject = {
    __typename: 'SuperAdminOrganisationObject'
    idStr: Scalars['ID']
    name: Scalars['String']
    accentColor: Scalars['String']
    autoInvite: Scalars['Boolean']
    logo: Maybe<LogoObject>
    categoryChangesThisMonth: Array<SmartGroup>
    editableProfile: Maybe<ProfileObject>
    fields: Array<FieldObject>
    primaryFields: Array<FieldObject>
    additionalFields: Array<AdditionalFieldObject>
    profiles: Array<ProfileObject>
    profilesCount: Scalars['Int']
    dataEditFields: Array<DataEditField>
    dataEditValues: Array<DataEditValue>
    profileLines: Array<ProfileLine>
    datasources: Array<DataSourceObject>
    photos: Array<PhotoObject>
    photoImportPercentage: Maybe<Scalars['Float']>
    currentUserRoles: Array<RoleType>
    userManagementAccessible: Scalars['Boolean']
    showADPOnboarding: Scalars['Boolean']
    smartGroups: Array<SmartGroup>
    messageOptions: Array<MessageOption>
    userRestrictions: UserRestrictions
    appFeatures: AppFeatures
    linkedDirectories: Array<LinkedDirectory>
    enabledExperiments: Array<Scalars['String']>
    sampleProfile: Maybe<ProfileObject>
    homeItems: Array<HomeItemObject>
    currentUser: UserObject
    users: Array<UserObject>
    admins: Array<UserObject>
    forms: Array<FormObject>
    recentSearches: Maybe<Array<ProfileObject>>
    dataSourceRequests: Maybe<Array<DataSourceRequestObject>>
    primaryDataSourceType: Maybe<DataSourceType>
    orgChart: Scalars['Boolean']
    scenario: Scalars['Boolean']
    parentIdStr: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    chargebeeCustomerId: Maybe<Scalars['String']>
    matchedPhotoCount: Scalars['Int']
    userCount: Scalars['Int']
    firstAdminUser: Maybe<UserObject>
    owner: Maybe<UserObject>
}

export type SuperAdminOrganisationObjectProfilesArgs = {
    includeOpenPositions: Maybe<Scalars['Boolean']>
    profileIdStrs: Maybe<Array<Scalars['String']>>
}

export type SuperAdminOrganisationObjectDataEditFieldsArgs = {
    organisationIdStr: Scalars['String']
}

export type SuperAdminOrganisationObjectDataEditValuesArgs = {
    organisationIdStr: Scalars['String']
}

export type SuperAdminOrganisationObjectProfileLinesArgs = {
    organisationIdStr: Scalars['String']
}

export type SuperAdminOrganisationObjectSmartGroupsArgs = {
    limit: Maybe<Scalars['Int']>
}

export type SuperAdminOrganisationObjectHomeItemsArgs = {
    visibility: Maybe<Visibility>
}

export type UserDiagnostic = {
    __typename: 'UserDiagnostic'
    group: Scalars['String']
    diagnostic: Scalars['String']
    outcomes: Array<Scalars['String']>
}

export type PaychexCompany = {
    __typename: 'PaychexCompany'
    companyId: Scalars['String']
    displayId: Scalars['String']
    legalName: Scalars['String']
}

export type WelcomeUserDetailsObject = {
    __typename: 'WelcomeUserDetailsObject'
    firstName: Scalars['String']
    organisationName: Scalars['String']
}

export type Form = {
    __typename: 'Form'
    formIdStr: Scalars['String']
    directoryName: Scalars['String']
    organisationIdStr: Scalars['String']
    fields: Maybe<Array<DataEditField>>
    masterDirectoryIdStr: Maybe<Scalars['String']>
}

export type Response = {
    __typename: 'Response'
    idStr: Scalars['String']
    formIdStr: Scalars['String']
    emailAddresses: Array<Scalars['String']>
    hasGivenConsent: Scalars['Boolean']
    data: ResponseData
}

export type ResponseData = {
    __typename: 'ResponseData'
    responseIdStr: Scalars['String']
    draftProfile: Array<DraftProfile>
    originalFileIdStr: Maybe<Scalars['String']>
    thumbFileIdStr: Maybe<Scalars['String']>
}

export type DraftProfile = {
    __typename: 'DraftProfile'
    responseIdStr: Scalars['String']
    name: Scalars['String']
    value: Scalars['String']
}

export type Mutation = {
    __typename: 'Mutation'
    generateCode: Scalars['String']
    sendResetPasswordEmail: Scalars['Boolean']
    resendResetPasswordEmail: Scalars['Boolean']
    resetPassword: Scalars['Boolean']
    swapTokens: SwapTokenResult
    signIn: SignInReturnObject
    signInMagicLink: SignInReturnObject
    signInOTP: SignInReturnObject
    oAuthSignIn: SignInReturnObject
    sendMagicLinkEmail: Scalars['Boolean']
    sendOTPEmail: Scalars['Boolean']
    signOut: Scalars['Boolean']
    verifySignIn: SignInReturnObject
    setPassword: SignInReturnObject
    updateChildCategoryLabel: ChildCategoryObject
    updateChildCategoryPriority: Array<ChildCategoryObject>
    combineCategories: ChildCategoryObject
    removeCombinedCategory: Scalars['Boolean']
    dataCollectionForm: Scalars['Boolean']
    sendDataCollectionEmailBatch: Scalars['Boolean']
    createAdditionalFields: Array<AdditionalFieldObject>
    updateAdditionalFieildsForm: Array<AdditionalFieldObject>
    deleteAdditionalField: Scalars['Boolean']
    deleteAllAdditionalFields: Scalars['Boolean']
    reUploadData: Scalars['Boolean']
    requestDataSource: DataSourceRequestObject
    suggestDataSource: Scalars['Boolean']
    importDataSource: Scalars['Boolean']
    setUpADPDataSource: Scalars['Boolean']
    deleteDataSource: Scalars['Boolean']
    downloadLatestRawData: Maybe<Scalars['String']>
    updateImportConfig: Scalars['Boolean']
    updateFieldVisibility: FieldObject
    updateFieldLabel: FieldObject
    updateFieldHideability: FieldObject
    updateFieldBehaviour: FieldObject
    updateFieldPriority: Array<FieldObject>
    updateHomeItems: Array<HomeItemObject>
    updateHomeItem: HomeItemObject
    insertHomeItem: HomeItemObject
    deleteHomeItem: HomeItemObject
    generateGoogleCalendarCredentials: Scalars['Boolean']
    disconnectGoogleCalendar: Scalars['Boolean']
    createAccount: Scalars['String']
    addOnboardingDetails: OrganisationObject
    useInAppData: Scalars['Boolean']
    connectHRIS: Scalars['Boolean']
    connectGoogleSheet: Scalars['Boolean']
    connectCSV: Scalars['Boolean']
    resendVerificationEmail: Scalars['Boolean']
    requestDemoAccount: Scalars['Boolean']
    addOpenPosition: ProfileObject
    deleteOpenPosition: Scalars['Boolean']
    updateOpenPosition: ProfileObject
    addHiddenProfiles: Array<HiddenProfile>
    removeHiddenProfiles: Array<HiddenProfile>
    dataExport: Scalars['String']
    updateOrganisation: OrganisationObject
    updateUserRestrictions: OrganisationObject
    updatePhotoApproval: OrganisationObject
    addAdditionalDataSource: DataSourceObject
    reconfigureAdditionalDataSource: DataSourceObject
    addRecentSearch: Array<ProfileObject>
    removeRecentSearch: Array<ProfileObject>
    updateLinkedDirectories: Array<LinkedDirectory>
    updateOrganisationEnabledExperiments: Array<Scalars['String']>
    sendPhotoCollectionEmails: Array<ProfileObject>
    uploadPhotoCollectionPhoto: Scalars['String']
    uploadPhoto: Maybe<PhotoObject>
    downloadPhotosReport: Scalars['String']
    downloadPhotos: Scalars['String']
    deletePhoto: Maybe<ProfilePhotoReturnObject>
    deletePhotos: Maybe<ProfilesPhotosReturnObject>
    unlinkPhoto: ProfilePhotoReturnObject
    unlinkPhotos: ProfilesPhotosReturnObject
    approvePhotos: ProfilesPhotosReturnObject
    rotatePhoto: ProfilePhotoReturnObject
    cropAndRotate: PhotoObject
    matchPhotosByField: Scalars['Boolean']
    updateAdditionalManagers: Array<Scalars['String']>
    updatePrimaryManager: ProfileObject
    resetManager: ProfileObject
    linkPhotoToProfile: Maybe<ProfilesPhotosReturnObject>
    updateHiddenFields: Array<ProfileLineObject>
    updateProfileHiddenFields: Array<DataEditValue>
    uploadPhotoForProfile: PhotoObject
    editAdditionalFields: Scalars['Boolean']
    updateAdditionalFields: Array<ProfileLineObject>
    updateAdditionalFieldValues: Array<DataEditValue>
    createScenario: Scalars['Boolean']
    updateScenarioName: Scalars['Boolean']
    deleteScenario: Scalars['Boolean']
    deleteOrganisationsByName: Array<SuperAdminOrganisationObject>
    deleteOrganisation: SuperAdminOrganisationObject
    deleteOrganisations: Scalars['Boolean']
    addOrganisation: SuperAdminOrganisationObject
    reseedDemoOrganisation: Scalars['Boolean']
    setOwner: Scalars['Boolean']
    updateChargebeeCustomerId: SuperAdminOrganisationObject
    updateAppFeatures: SuperAdminOrganisationObject
    fixUserSubIds: Scalars['Boolean']
    activateAdmins: Scalars['Boolean']
    downloadAdpProfilePhoto: ImageFile
    stopSyncing: Scalars['Boolean']
    addSubIds: Scalars['Boolean']
    setPrimaryDataSourceCompanyId: Scalars['Boolean']
    createMasterDirectory: Array<Scalars['String']>
    deleteSuperAdmin: Scalars['Boolean']
    addSuperAdmin: UserObject
    rollbackMigration: Scalars['Boolean']
    seedMeetingsOn: Scalars['Boolean']
    deleteUsers: Scalars['Boolean']
    backfillRevokeType: Scalars['Boolean']
    generateTemporaryMagicLink: Scalars['String']
    makeAdmin: Scalars['Float']
    removeAdmin: Scalars['Float']
    setSyncTypeManual: Scalars['Float']
    setSyncTypeAuto: Scalars['Float']
    inviteUsers: Array<UserObject>
    addAndInviteUsers: Array<UserObject>
    revokeUsers: Array<UserObject>
    invitePendingUsers: Array<UserObject>
    updateAutoInvite: OrganisationObject
    removeOrganisationUsers: Scalars['Float']
    acceptTerms: Scalars['Boolean']
    setViewedUserland: Scalars['Boolean']
    setViewedOrgChartPanel: Scalars['Boolean']
    setViewedMeetings: Scalars['Boolean']
    submitNPS: Scalars['Boolean']
    updateUserEnabledExperiments: Array<Scalars['String']>
    analyticsEvent: Scalars['Boolean']
    logSessionActivity: Scalars['Boolean']
    updateDataEditFields: Array<DataEditField>
    addNewProfiles: Array<DataEditValue>
    deleteProfiles: Scalars['Boolean']
    updateDataEditValues: Array<DataEditValue>
    deleteProfile: DeleteProfileResult
    updateProfile: UpdateProfileResult
    useCommunityBuild: Form
    requestCommunityBuildOTP: Scalars['Boolean']
    updateCommunityBuildDraftProfile: Scalars['Boolean']
    uploadCommunityBuildPhotos: Scalars['Boolean']
    giveCommunityBuildConsent: Scalars['Boolean']
    updateProfileLines: Array<ProfileLine>
    deleteProfileLines: Scalars['Boolean']
}

export type MutationSendResetPasswordEmailArgs = {
    email: Scalars['String']
}

export type MutationResendResetPasswordEmailArgs = {
    token: Scalars['String']
}

export type MutationResetPasswordArgs = {
    acceptTerms: Maybe<Scalars['Boolean']>
    token: Scalars['String']
    password: Scalars['String']
}

export type MutationSwapTokensArgs = {
    accessToken: Scalars['String']
}

export type MutationSignInArgs = {
    password: Scalars['String']
    email: Scalars['String']
}

export type MutationSignInMagicLinkArgs = {
    token: Scalars['String']
}

export type MutationSignInOtpArgs = {
    email: Scalars['String']
    otp: Scalars['String']
}

export type MutationOAuthSignInArgs = {
    redirectUri: Scalars['String']
    clientId: Scalars['String']
    authCode: Scalars['String']
}

export type MutationSendMagicLinkEmailArgs = {
    email: Scalars['String']
}

export type MutationSendOtpEmailArgs = {
    email: Scalars['String']
}

export type MutationSignOutArgs = {
    accessToken: Scalars['String']
}

export type MutationVerifySignInArgs = {
    token: Scalars['String']
}

export type MutationSetPasswordArgs = {
    password: Scalars['String']
    token: Scalars['String']
}

export type MutationUpdateChildCategoryLabelArgs = {
    label: Maybe<Scalars['String']>
    idStr: Scalars['String']
}

export type MutationUpdateChildCategoryPriorityArgs = {
    categories: Array<CategoryPriorityInput>
    organisationIdStr: Scalars['String']
}

export type MutationCombineCategoriesArgs = {
    orgIdStr: Scalars['String']
    categoryIdStrs: Array<Scalars['String']>
    name: Scalars['String']
}

export type MutationRemoveCombinedCategoryArgs = {
    orgIdStr: Scalars['String']
    categoryIdStr: Scalars['String']
}

export type MutationDataCollectionFormArgs = {
    fields: Array<DataCollectionFormField>
    token: Scalars['String']
}

export type MutationSendDataCollectionEmailBatchArgs = {
    organisationIdStr: Scalars['String']
    profileIdStrs: Array<Scalars['String']>
}

export type MutationCreateAdditionalFieldsArgs = {
    fields: Array<AdditionalFieldInput>
    organisationIdStr: Scalars['String']
}

export type MutationUpdateAdditionalFieildsFormArgs = {
    fields: Array<AdditionalFieldInput>
    organisationIdStr: Scalars['String']
}

export type MutationDeleteAdditionalFieldArgs = {
    idStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationDeleteAllAdditionalFieldsArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationReUploadDataArgs = {
    fileUpload: Scalars['Upload']
    dataSourceIdStr: Scalars['String']
}

export type MutationRequestDataSourceArgs = {
    dataSource: DataSourceRequestType
    organisationIdStr: Scalars['String']
}

export type MutationSuggestDataSourceArgs = {
    dataSource: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationImportDataSourceArgs = {
    importOptions: Maybe<ImportOptionsInput>
    dataSourceIdStr: Scalars['String']
}

export type MutationSetUpAdpDataSourceArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationDeleteDataSourceArgs = {
    dataSourceIdStr: Scalars['String']
}

export type MutationDownloadLatestRawDataArgs = {
    dataSourceIdStr: Scalars['String']
}

export type MutationUpdateImportConfigArgs = {
    importConfig: UpdateImportConfigInput
    dataSourceIdStr: Scalars['String']
}

export type MutationUpdateFieldVisibilityArgs = {
    visible: Scalars['Boolean']
    idStr: Scalars['String']
}

export type MutationUpdateFieldLabelArgs = {
    label: Scalars['String']
    idStr: Scalars['String']
}

export type MutationUpdateFieldHideabilityArgs = {
    hideable: Scalars['Boolean']
    idStr: Scalars['String']
}

export type MutationUpdateFieldBehaviourArgs = {
    type: FieldType
    idStr: Scalars['String']
}

export type MutationUpdateFieldPriorityArgs = {
    fields: Array<FieldPriorityInput>
    organisationIdStr: Scalars['String']
}

export type MutationUpdateHomeItemsArgs = {
    homeItems: Array<HomeItemInput>
}

export type MutationUpdateHomeItemArgs = {
    homeItem: HomeItemInput
}

export type MutationInsertHomeItemArgs = {
    categoryIdStr: Scalars['String']
}

export type MutationDeleteHomeItemArgs = {
    homeIdStr: Scalars['String']
}

export type MutationGenerateGoogleCalendarCredentialsArgs = {
    code: Scalars['String']
}

export type MutationCreateAccountArgs = {
    password: Scalars['String']
    email: Scalars['String']
}

export type MutationAddOnboardingDetailsArgs = {
    organisationName: Scalars['String']
    lastName: Scalars['String']
    firstName: Scalars['String']
}

export type MutationUseInAppDataArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationConnectHrisArgs = {
    organisationIdStr: Scalars['String']
    config: Maybe<IntegrationConfig>
    type: Scalars['String']
}

export type MutationConnectGoogleSheetArgs = {
    reconnectPrimaryDataSource: Maybe<Scalars['Boolean']>
    selectedColumns: Array<SelectedColumn>
    data: Maybe<GoogleSheetsData>
    organisationIdStr: Scalars['String']
}

export type MutationConnectCsvArgs = {
    selectedColumns: Array<SelectedColumn>
    fileInput: Scalars['Upload']
    organisationIdStr: Scalars['String']
}

export type MutationResendVerificationEmailArgs = {
    email: Scalars['String']
}

export type MutationRequestDemoAccountArgs = {
    firstName: Scalars['String']
    email: Scalars['String']
}

export type MutationAddOpenPositionArgs = {
    openPositionJobLink: Maybe<Scalars['String']>
    title: OpenPositionField
    managerIdStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationDeleteOpenPositionArgs = {
    idStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationUpdateOpenPositionArgs = {
    openPositionJobLink: Maybe<Scalars['String']>
    fields: Array<OpenPositionField>
    organisationIdStr: Scalars['String']
    idStr: Scalars['String']
}

export type MutationAddHiddenProfilesArgs = {
    profileIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationRemoveHiddenProfilesArgs = {
    externalIds: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationDataExportArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationUpdateOrganisationArgs = {
    logo: Maybe<LogoInput>
    accentColor: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationUpdateUserRestrictionsArgs = {
    userRestrictions: UserRestrictionsInput
    organisationIdStr: Scalars['String']
}

export type MutationUpdatePhotoApprovalArgs = {
    photoApproval: Scalars['Boolean']
    organisationIdStr: Scalars['String']
}

export type MutationAddAdditionalDataSourceArgs = {
    columnMap: Array<ColumnMap>
    googleSheetsData: Maybe<GoogleSheetsData>
    directUploadFile: Maybe<Scalars['Upload']>
    type: DataSourceType
    organisationIdStr: Scalars['String']
}

export type MutationReconfigureAdditionalDataSourceArgs = {
    columnMap: Array<ColumnMap>
    dataSourceIdStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationAddRecentSearchArgs = {
    profileIdStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationRemoveRecentSearchArgs = {
    profileIdStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationUpdateLinkedDirectoriesArgs = {
    linkedDirectories: Array<LinkedDirectoryInput>
    organisationIdStr: Scalars['String']
}

export type MutationUpdateOrganisationEnabledExperimentsArgs = {
    enabledExperiments: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationSendPhotoCollectionEmailsArgs = {
    organisationIdStr: Scalars['String']
    profileIdStrs: Array<Scalars['String']>
}

export type MutationUploadPhotoCollectionPhotoArgs = {
    file: Scalars['Upload']
    token: Scalars['String']
}

export type MutationUploadPhotoArgs = {
    imgFile: Scalars['Upload']
    organisationIdStr: Scalars['String']
}

export type MutationDownloadPhotosReportArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationDownloadPhotosArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationDeletePhotoArgs = {
    photoIdStr: Scalars['String']
}

export type MutationDeletePhotosArgs = {
    photoIdStrs: Array<Scalars['String']>
}

export type MutationUnlinkPhotoArgs = {
    photoIdStr: Scalars['String']
}

export type MutationUnlinkPhotosArgs = {
    photoIdStrs: Array<Scalars['String']>
}

export type MutationApprovePhotosArgs = {
    photoIdStrs: Array<Scalars['String']>
}

export type MutationRotatePhotoArgs = {
    rotation: Scalars['Float']
    photoIdStr: Scalars['String']
}

export type MutationCropAndRotateArgs = {
    cropAndRotate: CropAndRotateInput
    photoIdStr: Scalars['String']
}

export type MutationMatchPhotosByFieldArgs = {
    fieldIdStr: Scalars['String']
}

export type MutationUpdateAdditionalManagersArgs = {
    managerIdStrs: Array<Scalars['String']>
    profileIdStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationUpdatePrimaryManagerArgs = {
    managerIdStr: Maybe<Scalars['String']>
    profileIdStr: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationResetManagerArgs = {
    organisationIdStr: Scalars['String']
    profileIdStr: Scalars['String']
}

export type MutationLinkPhotoToProfileArgs = {
    photoIdStr: Scalars['String']
    profileIdStr: Scalars['String']
}

export type MutationUpdateHiddenFieldsArgs = {
    hiddenFields: Array<Scalars['String']>
    profileIdStr: Scalars['String']
}

export type MutationUpdateProfileHiddenFieldsArgs = {
    hiddenFields: Array<Scalars['String']>
    profileIdStr: Scalars['String']
}

export type MutationUploadPhotoForProfileArgs = {
    file: Scalars['Upload']
    profileIdStr: Scalars['String']
}

export type MutationEditAdditionalFieldsArgs = {
    fields: Array<DataCollectionFormField>
    profileIdStr: Scalars['String']
}

export type MutationUpdateAdditionalFieldsArgs = {
    fields: Array<DataCollectionFormField>
    profileIdStr: Scalars['String']
}

export type MutationUpdateAdditionalFieldValuesArgs = {
    fields: Array<DataCollectionFormField>
    profileIdStr: Scalars['String']
}

export type MutationCreateScenarioArgs = {
    organisationIdStr: Scalars['String']
    name: Scalars['String']
}

export type MutationUpdateScenarioNameArgs = {
    scenarioIdStr: Scalars['String']
    name: Scalars['String']
}

export type MutationDeleteScenarioArgs = {
    scenarioIdStr: Scalars['String']
}

export type MutationDeleteOrganisationsByNameArgs = {
    name: Scalars['String']
}

export type MutationDeleteOrganisationArgs = {
    idStr: Scalars['String']
}

export type MutationAddOrganisationArgs = {
    paychexCompanyId: Maybe<Scalars['String']>
    paylocityCompanyId: Maybe<Scalars['String']>
    seedSize: Maybe<Scalars['Float']>
    seedADPData: Maybe<Scalars['Boolean']>
    name: Scalars['String']
}

export type MutationReseedDemoOrganisationArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationSetOwnerArgs = {
    organisationIdStr: Scalars['String']
    userIdStr: Scalars['String']
}

export type MutationUpdateChargebeeCustomerIdArgs = {
    chargebeeCustomerId: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationUpdateAppFeaturesArgs = {
    appFeatures: AppFeaturesInput
    organisationIdStr: Scalars['String']
}

export type MutationFixUserSubIdsArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationDownloadAdpProfilePhotoArgs = {
    idStr: Scalars['String']
}

export type MutationStopSyncingArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationAddSubIdsArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationSetPrimaryDataSourceCompanyIdArgs = {
    companyId: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationCreateMasterDirectoryArgs = {
    appFeatures: AppFeaturesInput
    profileLines: Array<ProfileLineSpec>
    baseDirectories: Array<BaseDirectorySpec>
    masterDirectoryName: Scalars['String']
}

export type MutationDeleteSuperAdminArgs = {
    idStr: Scalars['String']
}

export type MutationAddSuperAdminArgs = {
    email: Scalars['String']
    lastName: Scalars['String']
    firstName: Scalars['String']
}

export type MutationDeleteUsersArgs = {
    email: Scalars['String']
}

export type MutationGenerateTemporaryMagicLinkArgs = {
    email: Scalars['String']
}

export type MutationMakeAdminArgs = {
    userIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationRemoveAdminArgs = {
    userIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationSetSyncTypeManualArgs = {
    userIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationSetSyncTypeAutoArgs = {
    userIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationInviteUsersArgs = {
    userIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationAddAndInviteUsersArgs = {
    userEmails: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationRevokeUsersArgs = {
    unrevoke: Maybe<Scalars['Boolean']>
    userIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationInvitePendingUsersArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationUpdateAutoInviteArgs = {
    autoInvite: Scalars['Boolean']
    organisationIdStr: Scalars['String']
}

export type MutationRemoveOrganisationUsersArgs = {
    userIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationSetViewedUserlandArgs = {
    lastStep: Scalars['String']
    organisationIdStr: Scalars['String']
}

export type MutationSetViewedOrgChartPanelArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationSetViewedMeetingsArgs = {
    organisationIdStr: Scalars['String']
}

export type MutationSubmitNpsArgs = {
    npsInput: UserNpsInput
}

export type MutationUpdateUserEnabledExperimentsArgs = {
    enabledExperiments: Array<Scalars['String']>
    userIdStr: Scalars['String']
}

export type MutationAnalyticsEventArgs = {
    attributes: Maybe<UserEventAttributes>
    event: Scalars['String']
    userIdStr: Scalars['String']
}

export type MutationLogSessionActivityArgs = {
    platform: Scalars['String']
    userIdStr: Scalars['String']
}

export type MutationUpdateDataEditFieldsArgs = {
    removeMissingFields: Scalars['Boolean']
    dataEditFields: Array<DataEditFieldUpsert>
    organisationIdStr: Scalars['String']
}

export type MutationAddNewProfilesArgs = {
    newProfiles: Array<Array<DataEditFieldValue>>
    organisationIdStr: Scalars['String']
}

export type MutationDeleteProfilesArgs = {
    profileIdStrs: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type MutationUpdateDataEditValuesArgs = {
    removeMissingValues: Maybe<Scalars['Boolean']>
    dataEditValues: Array<DataEditValueUpsert>
    organisationIdStr: Scalars['String']
}

export type MutationDeleteProfileArgs = {
    profileIdStr: Scalars['String']
}

export type MutationUpdateProfileArgs = {
    removeMissingValues: Maybe<Scalars['Boolean']>
    dataEditValues: Array<DataEditValueUpsert>
}

export type MutationUseCommunityBuildArgs = {
    homeItems: Maybe<Array<FormHomeItem>>
    profileLines: Maybe<Array<FormProfileLine>>
    fields: Array<DataEditFieldUpsert>
    organisationIdStr: Scalars['String']
}

export type MutationRequestCommunityBuildOtpArgs = {
    emailAddress: Scalars['String']
    formIdStr: Scalars['String']
}

export type MutationUpdateCommunityBuildDraftProfileArgs = {
    draftProfile: Array<DraftProfileInput>
    emailAddress: Scalars['String']
    responseIdStr: Scalars['String']
}

export type MutationUploadCommunityBuildPhotosArgs = {
    photoIndex: Maybe<Scalars['Int']>
    thumbRegion: Maybe<RegionInput>
    originalFile: Scalars['Upload']
    emailAddress: Scalars['String']
    responseIdStr: Scalars['String']
}

export type MutationGiveCommunityBuildConsentArgs = {
    emailAddress: Scalars['String']
    responseIdStr: Scalars['String']
}

export type MutationUpdateProfileLinesArgs = {
    profileLines: Array<ProfileLineInput>
    organisationIdStr: Scalars['String']
}

export type MutationDeleteProfileLinesArgs = {
    profileLines: Array<Scalars['String']>
    organisationIdStr: Scalars['String']
}

export type SwapTokenResult = {
    __typename: 'SwapTokenResult'
    accessToken: Scalars['String']
    idToken: Scalars['String']
}

export type SignInReturnObject = {
    __typename: 'SignInReturnObject'
    accessToken: Scalars['String']
    idToken: Scalars['String']
    subId: Scalars['String']
}

export type CategoryPriorityInput = {
    idStr: Scalars['ID']
    priority: Scalars['Float']
}

export type DataCollectionFormField = {
    idStr: Scalars['String']
    value: Maybe<Scalars['String']>
    options: Maybe<Array<Scalars['String']>>
}

export type AdditionalFieldInput = {
    idStr: Maybe<Scalars['String']>
    name: Scalars['String']
    type: AdditionalFieldType
    helpText: Maybe<Scalars['String']>
    options: Maybe<Array<Scalars['String']>>
}

export type ImportOptionsInput = {
    processDataset: Scalars['Boolean']
    processDirectory: Scalars['Boolean']
    processUsers: Scalars['Boolean']
    processPhotos: Scalars['Boolean']
}

export type UpdateImportConfigInput = {
    fieldRules: Maybe<Array<FieldRuleInput>>
    filterRules: Maybe<Array<Scalars['String']>>
}

export type FieldRuleInput = {
    fieldName: Scalars['String']
    fieldType: Scalars['String']
    isKeyField: Scalars['Boolean']
    expression: Scalars['String']
}

export type FieldPriorityInput = {
    idStr: Scalars['ID']
    priority: Scalars['Float']
}

export type HomeItemInput = {
    idStr: Scalars['String']
    priority: Maybe<Scalars['Float']>
    label: Maybe<Scalars['String']>
    visible: Maybe<Scalars['Boolean']>
}

export type IntegrationConfig = {
    clientId: Maybe<Scalars['String']>
    clientSecret: Maybe<Scalars['String']>
    companyId: Maybe<Scalars['String']>
    employeeUrl: Maybe<Scalars['String']>
    photoUrl: Maybe<Scalars['String']>
    username: Maybe<Scalars['String']>
    password: Maybe<Scalars['String']>
}

export type SelectedColumn = {
    columnName: Scalars['String']
    fieldName: Scalars['String']
    type: Maybe<Scalars['String']>
}

export type OpenPositionField = {
    fieldIdStr: Scalars['String']
    categoryIdStr: Maybe<Scalars['String']>
    value: Maybe<Scalars['String']>
}

export type LogoInput = {
    height: Maybe<Scalars['Int']>
    fileInput: Maybe<Scalars['Upload']>
}

export type UserRestrictionsInput = {
    canEditProfilePicture: Maybe<Scalars['Boolean']>
    canEditAdditionalFields: Maybe<Scalars['Boolean']>
}

export type ColumnMap = {
    fieldOrCatIdStr: Scalars['String']
    column: Scalars['String']
}

export type LinkedDirectoryInput = {
    masterOrganisationIdStr: Scalars['String']
    baseOrganisationIdStr: Scalars['String']
    isAccessibleByOtherBaseDirectoriesInMaster: Maybe<Scalars['Boolean']>
    canAccessAllOtherBaseDirectoriesInMaster: Maybe<Scalars['Boolean']>
}

export type ProfilePhotoReturnObject = {
    __typename: 'ProfilePhotoReturnObject'
    photo: PhotoObject
    profile: Maybe<ProfileObject>
}

export type ProfilesPhotosReturnObject = {
    __typename: 'ProfilesPhotosReturnObject'
    photos: Array<PhotoObject>
    profiles: Maybe<Array<ProfileObject>>
}

export type CropAndRotateInput = {
    rotation: Scalars['Float']
    profile: Maybe<CropRectangle>
    thumb: Maybe<CropRectangle>
}

export type CropRectangle = {
    left: Scalars['Float']
    top: Scalars['Float']
    width: Scalars['Float']
    height: Scalars['Float']
}

export type AppFeaturesInput = {
    orgChart: Maybe<Scalars['Boolean']>
    photoApproval: Maybe<Scalars['Boolean']>
    photoCollection: Maybe<Scalars['Boolean']>
    weeklyUserEmail: Maybe<Scalars['Boolean']>
    groupMessage: Maybe<Scalars['Boolean']>
    meetings: Maybe<Scalars['Boolean']>
    downloadPhotos: Maybe<Scalars['Boolean']>
    downloadData: Maybe<Scalars['Boolean']>
    profileThreshold: Maybe<Scalars['Boolean']>
    useRulesBasedImport: Maybe<Scalars['Boolean']>
    inAppData: Maybe<Scalars['Boolean']>
    communityBuild: Maybe<Scalars['Boolean']>
    masterDirectory: Maybe<Scalars['Boolean']>
    multiplePhotosPerProfile: Maybe<Scalars['Boolean']>
}

export type ImageFile = {
    __typename: 'ImageFile'
    content: Scalars['String']
    type: Scalars['String']
}

export type ProfileLineSpec = {
    fieldNames: Array<Scalars['String']>
    prefix: Maybe<Scalars['String']>
    separator: Maybe<Scalars['String']>
    suffix: Maybe<Scalars['String']>
    showLabel: Scalars['Boolean']
    fieldStyle: Scalars['String']
    labelStyle: Maybe<Scalars['String']>
    fieldBehaviour: Scalars['String']
}

export type BaseDirectorySpec = {
    nameSuffix: Scalars['String']
    dataFields: Maybe<Array<DataEditFieldSpec>>
    homeItems: Maybe<Array<HomeItemSpec>>
    isAccessibleByOtherBaseDirectoriesInMaster: Maybe<Scalars['Boolean']>
    canAccessAllOtherBaseDirectoriesInMaster: Maybe<Scalars['Boolean']>
    appFeatures: Maybe<AppFeaturesInput>
}

export type DataEditFieldSpec = {
    name: Scalars['String']
    type: Scalars['String']
    subType: Maybe<Scalars['String']>
    availableValues: Array<Scalars['String']>
    usersCanCreateValues: Maybe<Scalars['Boolean']>
}

export type HomeItemSpec = {
    forCategoryName: Maybe<Scalars['String']>
    label: Scalars['String']
}

export type UserNpsInput = {
    score: Maybe<Scalars['Float']>
    primaryUsage: Maybe<Scalars['String']>
    additionalFeedback: Maybe<Scalars['String']>
    requestCallback: Maybe<Scalars['Boolean']>
}

export type UserEventAttributes = {
    redirectLink: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    device_type: Maybe<Scalars['String']>
    button: Maybe<Scalars['String']>
    organisationId: Maybe<Scalars['String']>
}

export type DataEditFieldUpsert = {
    idStr: Maybe<Scalars['String']>
    organisationIdStr: Scalars['String']
    name: Scalars['String']
    type: Scalars['String']
    subType: Maybe<Scalars['String']>
    availableValues: Array<Scalars['String']>
    usersCanCreateValues: Maybe<Scalars['Boolean']>
}

export type DataEditFieldValue = {
    fieldOrParentCategoryIdStr: Scalars['String']
    value: Scalars['String']
    organisationIdStr: Maybe<Scalars['String']>
}

export type DataEditValueUpsert = {
    fieldOrParentCategoryIdStr: Scalars['String']
    profileIdStr: Scalars['String']
    value: Scalars['String']
    organisationIdStr: Maybe<Scalars['String']>
}

export type DeleteProfileResult = {
    __typename: 'DeleteProfileResult'
    remainingUserOrganisations: Array<Scalars['String']>
}

export type UpdateProfileResult = {
    __typename: 'UpdateProfileResult'
    values: Array<DataEditValue>
    fields: Array<DataEditField>
}

export type FormHomeItem = {
    forCategoryName: Maybe<Scalars['String']>
    label: Scalars['String']
}

export type FormProfileLine = {
    fieldNames: Array<Scalars['String']>
    prefix: Maybe<Scalars['String']>
    separator: Maybe<Scalars['String']>
    suffix: Maybe<Scalars['String']>
    showLabel: Scalars['Boolean']
    fieldStyle: Scalars['String']
    labelStyle: Maybe<Scalars['String']>
    fieldBehaviour: Scalars['String']
    labelDescription: Maybe<Scalars['String']>
}

export type DraftProfileInput = {
    name: Scalars['String']
    value: Scalars['String']
}

export type RegionInput = {
    left: Scalars['Float']
    top: Scalars['Float']
    width: Scalars['Float']
    height: Scalars['Float']
}

export type ProfileLineInput = {
    idStr: Maybe<Scalars['String']>
    priority: Maybe<Scalars['Float']>
    fieldOrCategoryIdStrs: Maybe<Array<Scalars['String']>>
    showField: Maybe<Scalars['Boolean']>
    showLabel: Maybe<Scalars['Boolean']>
    userCanEdit: Maybe<Scalars['Boolean']>
    userCanHide: Maybe<Scalars['Boolean']>
    sectionLineBelow: Maybe<Scalars['Boolean']>
    fieldStyle: Maybe<Scalars['String']>
    labelStyle: Maybe<Scalars['String']>
    labelDescription: Maybe<Scalars['String']>
    fieldBehaviour: Maybe<Scalars['String']>
    prefix: Maybe<Scalars['String']>
    separator: Maybe<Scalars['String']>
    suffix: Maybe<Scalars['String']>
    hideYear: Maybe<Scalars['Boolean']>
    organisationIdStr: Maybe<Scalars['String']>
}
