import {Button, Card, HeaderLayout, Spacer, Spinner, Typography} from 'nf-ui'
import React, {FC} from 'react'
import styled from 'styled-components'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {Illustrations} from '~/components/Illustrations'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import Pottery from '~/illustrations/pottery.png'
import {getGraphQLErrorMessage} from '~/util'

const LoadingContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
`
const DashboardCard = styled(Card)`
    flex-direction: row;
    flex: 1;
    width: unset;
    min-height: 100px;
    align-items: center;
    ${Typography.Label} {
        display: inline-block;
        padding-bottom: 4px;
    }
`

const CardContainer = styled.div`
    width: 640px;
    display: flex;
    flex-direction: column;
    ${DashboardCard} {
        margin-bottom: 16px;
    }
`

export const Dashboard: FC = () => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const {me, loading, error} = useCurrentUser()
    const {pushRelative} = useRelativeRoute()

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>

    if (loading || !me || !currentOrganisation) {
        return (
            <LoadingContainer>
                <Spinner size={32} />
            </LoadingContainer>
        )
    }

    return (
        <HeaderLayout heading={`Welcome to Names & Faces, ${me.firstName}`}>
            <Spacer height={16} />
            <CardContainer>
                <DashboardCard>
                    <div>
                        <Typography.Label>Connect data</Typography.Label>
                        <Typography.Paragraph bottomMargin={false}>
                            Import a list of your people as a Google Sheet, XLS or CSV file.
                        </Typography.Paragraph>
                    </div>
                    <Button
                        variant="secondary"
                        onClick={() => pushRelative('integrations')}
                        onClickAnalyticsEvent="select_data"
                    >
                        Start
                    </Button>
                </DashboardCard>
                <DashboardCard>
                    <div>
                        <Typography.Label>Collect or upload photos</Typography.Label>
                        <Typography.Paragraph bottomMargin={false}>
                            Collect, upload, and match your photos to the people in your data.
                        </Typography.Paragraph>
                    </div>
                    <Button
                        variant="secondary"
                        onClick={() => pushRelative('photos')}
                        onClickAnalyticsEvent="select_photos"
                    >
                        Start
                    </Button>
                </DashboardCard>
                <DashboardCard>
                    <div>
                        <Typography.Label>Design your directory</Typography.Label>
                        <Typography.Paragraph bottomMargin={false}>
                            Add branding and customize directory categories and fields.
                        </Typography.Paragraph>
                    </div>
                    <Button
                        variant="secondary"
                        onClick={() => pushRelative('design/branding')}
                        onClickAnalyticsEvent="select_design"
                    >
                        Start
                    </Button>
                </DashboardCard>
                <DashboardCard>
                    <div>
                        <Typography.Label>Manage users</Typography.Label>
                        <Typography.Paragraph bottomMargin={false}>
                            Control who gets access to your directory and invite users.
                        </Typography.Paragraph>
                    </div>
                    <Button
                        variant="secondary"
                        onClick={() => pushRelative('users')}
                        onClickAnalyticsEvent="select_users"
                    >
                        Start
                    </Button>
                </DashboardCard>
            </CardContainer>
            <Illustrations RightIllutration={Pottery} width={160} sidebar />
        </HeaderLayout>
    )
}
