import {gql, useQuery} from '@apollo/client'
import {
    colors,
    Icon,
    List,
    ListItem as Item,
    ListItemWrapper as ItemWrapper,
    Panel,
    Spacer,
    TooltipTarget,
    Typography,
    useAnalyticsEventTracker,
    useTheme,
    WhiteButton,
} from 'nf-ui'
import SvgAlertRed from 'nf-ui/Icons/AlertRed'
import SvgCheck from 'nf-ui/Icons/Check'
import SvgEditThin from 'nf-ui/Icons/EditThin'
import SvgFlag from 'nf-ui/Icons/Flag'
import SvgSignOut from 'nf-ui/Icons/SignOut'
import SvgSwitcher from 'nf-ui/Icons/Switcher'
import React, {FC, useCallback, useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import {useHistory, useRouteMatch} from 'react-router-dom'
import styled, {css} from 'styled-components'
import {PANEL_WIDTH} from '~/constants'
import {CurrentOrganisation, useCurrentOrganisation} from './CurrentOrganisationContext'
import {DirectoryAdminSwitcher} from './OrganisationSwitcher/DirectoryViewSwitcher'
import {LegacyViewSwitcher} from './OrganisationSwitcher/LegacyViewSwitcher'
import {ManagementViewSwitcher} from './OrganisationSwitcher/ManagementViewSwitcher'
import {useSignout} from './useSignout'
import {CurrentOrganisationData_organisations} from './__types__/CurrentOrganisationData'
import {OrganisationSwitcherData} from './__types__/OrganisationSwitcherData'
import {DirectoryOrgChartSwitcher} from './OrganisationSwitcher/DirectoryOrgChartSwitcher'
import {useCurrentUser} from './useCurrentUser'

const FlexItem = styled.div<{border?: boolean}>`
    display: flex;
    justify-content: space-between;
    border-top: ${({border = false}) => (border ? css`1px solid ${colors.darkGray}` : 'none')};
    margin: -16px;
    padding: 16px;
`

const OrganisationName: React.FC = ({children}) => (
    <Typography.Paragraph bottomMargin={false} maxLines={1}>
        {children}
    </Typography.Paragraph>
)

const ListItem: React.FC<{clickable?: boolean; borderBottom?: boolean}> = ({
    children,
    clickable = true,
    borderBottom = true,
}) => {
    const borderStyle = borderBottom ? {borderBottom: `1px solid ${colors.darkGray}`} : {}
    return (
        <ItemWrapper borders={true} clickable={clickable} style={borderStyle}>
            <Item>{children}</Item>
        </ItemWrapper>
    )
}

const IconPadded = styled(Icon)`
    padding: 4px 4px 0px;
`

const AlertWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

type SwitcherOrganisation = {
    idStr: string
    name: string
    scenario: boolean
    parentIdStr: string | null
}

type SwitcherPanelProps = {
    organisations: SwitcherOrganisation[]
    currentOrganisation: CurrentOrganisationData_organisations
    actionRequired?: boolean
    onClickRename?: () => void
    onClickEdit?: () => void
    onClickApprovalSettings?: () => void
}

const useOrgSwitcherOrganisations = (allOrganisations: SwitcherOrganisation[]) => {
    const {me} = useCurrentUser()
    const {currentOrganisation} = useCurrentOrganisation()

    const isCurrentOrganisation = (organisation: SwitcherOrganisation) => {
        if (currentOrganisation?.parentIdStr) return organisation.idStr === currentOrganisation?.parentIdStr
        return organisation.idStr === currentOrganisation?.idStr
    }

    const isCurrentScenario = (organisation: SwitcherOrganisation) => {
        if (currentOrganisation?.parentIdStr) return organisation.parentIdStr === currentOrganisation?.parentIdStr
        return organisation.parentIdStr === currentOrganisation?.idStr
    }

    let organisations = allOrganisations.filter((organisation) => !organisation.scenario)
    let scenarios = allOrganisations.filter((organisation) => organisation.scenario)

    if (me?.isSuperAdmin) {
        organisations = organisations.filter(isCurrentOrganisation)
        scenarios = scenarios.filter(isCurrentScenario)
    }

    return {scenarios, organisations}
}

const SwitcherPanel: React.FC<SwitcherPanelProps> = ({
    organisations: allOrganisations,
    currentOrganisation,
    actionRequired,
    onClickRename,
    onClickEdit,
    onClickApprovalSettings,
}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const alert = useAlert()
    const {signOut} = useSignout()
    const theme = useTheme()
    const {path} = useRouteMatch()
    const {push} = useHistory()

    const isAdminLand = path?.includes('admin') ?? false
    const canEditProfile = !!onClickEdit && currentOrganisation.editableProfile

    const {scenarios, organisations} = useOrgSwitcherOrganisations(allOrganisations)

    const rows = [...organisations, ...scenarios]

    const handleOrgClick = useCallback(
        (destinationOrganisation: SwitcherPanelProps['organisations'][0]) => {
            const orgIdStr = destinationOrganisation.idStr
            if (destinationOrganisation.scenario || currentOrganisation.scenario) push(`/${orgIdStr}/org-chart/full`)
            else push(isAdminLand ? `/${orgIdStr}/admin` : `/${orgIdStr}`)
        },
        [isAdminLand, push, currentOrganisation],
    )

    const organisationLabel = (organisation: SwitcherOrganisation) => {
        if (!scenarios.length) return organisation.name

        return organisation.scenario ? `Scenario: ${organisation.name}` : `Live: ${organisation.name}`
    }

    return (
        <>
            <List
                rows={rows}
                renderRow={(organisation) => {
                    return (
                        <FlexItem
                            onClick={() => {
                                trackAnalyticsEvent('select_directory')
                                handleOrgClick(organisation)
                            }}
                        >
                            <OrganisationName>{organisationLabel(organisation)}</OrganisationName>
                            {currentOrganisation.idStr === organisation.idStr && (
                                <IconPadded icon={SvgCheck} tint={theme.primary.color} />
                            )}
                        </FlexItem>
                    )
                }}
                width={PANEL_WIDTH}
                heading={<Typography.Label>Your views</Typography.Label>}
                variant="dropdown"
            />
            <ListItem clickable={false} borderBottom={false}>
                <Typography.Label>Settings</Typography.Label>
            </ListItem>
            {onClickApprovalSettings && (
                <ListItem>
                    <FlexItem onClick={onClickApprovalSettings}>
                        <Typography.Paragraph bottomMargin={false}>Approval settings</Typography.Paragraph>
                        <IconPadded icon={SvgFlag} />
                    </FlexItem>
                </ListItem>
            )}
            {onClickRename && (
                <ListItem>
                    <FlexItem onClick={onClickRename}>
                        <Typography.Paragraph bottomMargin={false}>Rename organization</Typography.Paragraph>
                        <IconPadded icon={SvgEditThin} />
                    </FlexItem>
                </ListItem>
            )}
            {canEditProfile && (
                <ListItem>
                    <FlexItem onClick={onClickEdit}>
                        <AlertWrapper>
                            {actionRequired && (
                                <>
                                    <Icon icon={SvgAlertRed} />
                                    <Spacer width={16} />
                                </>
                            )}
                            <Typography.Paragraph bottomMargin={false}>Edit profile</Typography.Paragraph>
                        </AlertWrapper>

                        <IconPadded icon={SvgEditThin} />
                    </FlexItem>
                </ListItem>
            )}
            <ListItem borderBottom={false}>
                <FlexItem
                    onClick={async () => {
                        trackAnalyticsEvent('tap_sign_out')
                        await signOut()
                        alert.success('Successfully signed out')
                    }}
                >
                    <Typography.Paragraph bottomMargin={false}>Sign out</Typography.Paragraph>
                    <IconPadded icon={SvgSignOut} />
                </FlexItem>
            </ListItem>
        </>
    )
}

const DATA = gql`
    query OrganisationSwitcherData {
        organisations {
            idStr
            name
            scenario
            parentIdStr
        }
    }
`

const SwitcherContainer = styled.div`
    margin: 0 -16px -16px -16px;
`

const AlertContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(calc(50% + 0.5px), calc(-50% - 1px));
    background-color: ${colors.lightGray};
    border-radius: 50%;
    width: 20px;
    height: 20px;
`

export type OrganisationSwitcherProps = {
    hideViewSwitches?: boolean
    actionRequired?: boolean
    onClickRename?: () => void
    onClickEdit?: () => void
    onClickApprovalSettings?: () => void
}

export const OrganisationSwitcher: FC<OrganisationSwitcherProps> = ({
    hideViewSwitches = false,
    actionRequired = false,
    onClickRename,
    onClickEdit,
    onClickApprovalSettings,
}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [open, setOpen] = useState(false)
    const {data, loading} = useQuery<OrganisationSwitcherData>(DATA)
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const targetRef = useRef<HTMLButtonElement>(null)

    if (!data || loading || !currentOrganisation) return null

    const scenario = currentOrganisation.scenario

    const organisationName = () => {
        if (!currentOrganisation) return 'No organisation'

        return currentOrganisation.scenario ? `Scenario: ${currentOrganisation.name}` : `${currentOrganisation.name}`
    }

    return (
        <SwitcherContainer>
            {!hideViewSwitches && (
                <>
                    {!scenario && (
                        <>
                            <DirectoryOrgChartSwitcher />
                            <LegacyViewSwitcher />
                        </>
                    )}
                    <DirectoryAdminSwitcher />
                    <ManagementViewSwitcher />
                </>
            )}
            <WhiteButton
                ref={targetRef}
                onClick={() => {
                    trackAnalyticsEvent('open_settings')
                    setOpen((o) => !o)
                }}
                icon={SvgSwitcher}
                style={{position: 'relative'}}
            >
                {organisationName()}
                {actionRequired && (
                    <AlertContainer>
                        <TooltipTarget title="Missing profile picture" position="right" maxWidth={144}>
                            <Icon icon={SvgAlertRed} />
                        </TooltipTarget>
                    </AlertContainer>
                )}
            </WhiteButton>
            <Panel
                boundariesElement="viewport"
                positionFixed
                targetRef={targetRef}
                open={open}
                onClose={() => setOpen(false)}
                closeOnClick
            >
                <SwitcherPanel
                    currentOrganisation={currentOrganisation}
                    actionRequired={actionRequired}
                    organisations={data.organisations}
                    onClickRename={onClickRename}
                    onClickEdit={onClickEdit}
                    onClickApprovalSettings={onClickApprovalSettings}
                />
            </Panel>
        </SwitcherContainer>
    )
}
