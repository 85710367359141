import * as React from 'react'
const SvgHomeBold = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgHomeBold(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M5.205 0h-3.18C.963 0 0 .895 0 2v3.2c0 1.105.964 2.001 2.024 2.001h3.18c1.157 0 2.025-.896 2.025-2.001V2c0-1.105-.868-2-2.024-2zM1.639 2c0-.22.192-.4.385-.4h3.18c.29 0 .386.18.386.4v3.2c0 .222-.096.4-.385.4h-3.18a.406.406 0 01-.386-.4V2zm3.566 6.801h-3.18c-1.061 0-2.025.895-2.025 2v3.2c0 1.105.964 2 2.024 2h3.18c1.157 0 2.025-.895 2.025-2v-3.2c0-1.105-.868-2-2.024-2zm-3.566 2c0-.222.192-.4.385-.4h3.18c.29 0 .386.178.386.4v3.2c0 .22-.096.4-.385.4h-3.18a.407.407 0 01-.386-.4v-3.2zm9.156-2h3.277c1.06 0 1.928.895 1.928 2v3.2c0 1.105-.867 2-1.928 2h-3.277c-1.06 0-1.928-.895-1.928-2v-3.2c0-1.105.868-2 1.928-2zm0 1.6a.406.406 0 00-.385.4v3.2c0 .22.192.4.385.4h3.277c.193 0 .386-.18.386-.4v-3.2c0-.222-.193-.4-.386-.4h-3.277zM14.072 0h-3.277c-1.06 0-1.928.895-1.928 2v3.2c0 1.105.868 2.001 1.928 2.001h3.277C15.132 7.201 16 6.305 16 5.2V2c0-1.105-.867-2-1.928-2zM10.41 2c0-.22.192-.4.385-.4h3.277c.193 0 .386.18.386.4v3.2c0 .222-.193.4-.386.4h-3.277a.406.406 0 01-.385-.4V2z"
                fill="#3C8EFF"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgHomeBold
