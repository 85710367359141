import {Panel} from 'nf-ui'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {StyledInputProps, TextInputElement, VisibleInput} from './Primitives'
import {Menu, MenuEntry} from 'nf-ui/Menu'

export type SelectInputProps = StyledInputProps & {
    width?: string
    freeText?: boolean
    multipleSelection?: boolean
    availableValues: string[]
    availableValuesOrdered: boolean
    defaultValues: string[]
    placeholder: string
    renderTextValue: (values: string[]) => string
    parseTextValue: (text: string) => string[]
    onChange?: (values: string[]) => void
    onDoneEditing?: () => void
    panelRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const SelectInput = forwardRef<TextInputElement, SelectInputProps>((props: SelectInputProps, ref) => {
    const [availableValues, setAvailableValues] = useState(
        props.availableValuesOrdered
            ? props.availableValues.filter((value) => value).sort()
            : props.availableValues.filter((value) => value),
    )
    const [text, setText] = useState('')
    const [values, setValues] = useState(props.defaultValues.filter((value) => value))
    const [hasFocus, setHasFocus] = useState(false)
    const [inputHasFocus, setInputHasFocus] = useState(false)
    const [scrollingComplete, setScrollingComplete] = useState<boolean>(false)
    const innerRef = useRef<TextInputElement>(null)
    useImperativeHandle<TextInputElement | null, TextInputElement | null>(ref, () => innerRef.current)

    const renderTextValue = (values: string[]) =>
        props.renderTextValue(availableValues.filter((value) => value && values.includes(value)))

    useEffect(() => {
        if (availableValues.length === 1 && !props.freeText) {
            setValues(availableValues)
            setText(renderTextValue(availableValues))
        } else {
            setText(renderTextValue(values))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        props.onChange?.(values)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    useEffect(() => {
        if (inputHasFocus) {
            setText('')
        } else {
            setText(renderTextValue(values))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputHasFocus, values])

    useEffect(() => {
        if (!hasFocus) props.onDoneEditing?.()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFocus])

    const filterValues = text.toLowerCase().split(' ')
    const matches = inputHasFocus
        ? availableValues.filter((value) =>
              filterValues.every((filterValue) => value.toLowerCase().includes(filterValue)),
          )
        : availableValues

    return (
        <span>
            <VisibleInput
                type="text"
                width={props.width}
                placeholder={props.placeholder}
                ref={innerRef}
                value={text}
                scrollMarginBottom={235}
                readOnly={false}
                onChange={(ev) => setText(ev.currentTarget.value)}
                onHasFocusChange={(focus) => {
                    setTimeout(() => {
                        if (focus || !props.multipleSelection) setHasFocus(focus)
                        setInputHasFocus(focus)
                    }, 100)
                }}
                onKeyUp={(ev) => {
                    if (ev.key === 'Enter' && text && !availableValues.includes(text)) {
                        setAvailableValues(
                            props.availableValuesOrdered
                                ? [...availableValues, text].sort()
                                : [...availableValues, text],
                        )
                        if (props.multipleSelection) {
                            setValues([...values, text])
                        } else {
                            setValues([text])
                        }
                        setText('')
                    }
                }}
                onScrollIntoViewBegin={() => setScrollingComplete(false)}
                onScrollIntoViewComplete={() => setScrollingComplete(true)}
            ></VisibleInput>
            <Panel targetRef={innerRef} open={hasFocus && scrollingComplete} onClose={() => setHasFocus(false)}>
                <Menu
                    currentParentMenuEntryId="selection"
                    menuEntries={[
                        {id: 'selection', values, behaviours: []},
                        ...matches.map((match) => ({
                            id: `selection|${match}`,
                            label: match,
                            behaviours: [props.multipleSelection ? 'pick-many' : 'pick-one'] as MenuEntry['behaviours'],
                            topBorder: true,
                        })),
                        ...(inputHasFocus && text && !availableValues.includes(text)
                            ? [
                                  {
                                      id: `selection|add-new-category`,
                                      label: `Add Category '${text}'`,
                                      behaviours: ['tertiary-button'] as MenuEntry['behaviours'],
                                      topBorder: true,
                                  },
                              ]
                            : []),
                    ]}
                    handlers={{
                        onValuesChanged: async (id, values) => {
                            setValues(values)
                        },
                        onButtonClicked: async (id) => {
                            setAvailableValues(
                                props.availableValuesOrdered
                                    ? [...availableValues, text].sort()
                                    : [...availableValues, text],
                            )
                            if (props.multipleSelection) {
                                setValues([...values, text])
                            } else {
                                setValues([text])
                            }
                        },
                    }}
                ></Menu>
            </Panel>
        </span>
    )
})
