import {Button, colors, measureTextWidth, Panel, Spacer, Spinner, Typography, useOnClickOutside} from 'nf-ui'
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {getGraphQLErrorMessage} from '~/util'
import {
    DataEditor,
    DataEditorRef,
    EditableGridCell,
    GridCellKind,
    GridColumn,
    GridKeyEventArgs,
    GridMouseEventArgs,
    GridSelection,
    Item,
    SizedGridColumn,
} from '@glideapps/glide-data-grid'
import {useExtraCells} from '@glideapps/glide-data-grid-cells'
import '@glideapps/glide-data-grid/dist/index.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {difference, uniq} from 'lodash'
import {useAlert} from 'react-alert'
import {PanelLayout} from '~/components/PanelLayout'
import {usePanelNavigator} from '~/components/PanelNavigator'
import {PickCategory} from './PickCategory'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {ManageColumn} from './ManageColumn'
import {Column, Row} from '~/components/Primitives'
import {useOrganisationContext} from '~/components/OrganisationContext'
import {CellValue, Field, useData} from './useData'
import {DataEditValue} from '~/objectTypes'

const CellEditor = styled.div`
    position: absolute;
    display: none;
`

const CellEditorInput = styled.input`
    border: none;
    &:focus {
        outline: none;
    }
`

const CellEditorSpinnerContainer = styled.div`
    display: flex;
    justify-content: ${(props) => (props.className?.includes('rename-column') ? 'flex-end' : 'center')};
    align-items: center;
    height: 30px;
`

const AddColumnButton = styled.div`
    font-weight: 200;
    font-size: x-large;
    padding: ${(props) =>
        props.className?.includes('add-column')
            ? '10px 7px 10px 6px'
            : props.className?.includes('scrolling-horizontally')
            ? '4px 7px 4px 8px'
            : '4px 8px 4px 8px'};
    margin: 1px 0px 1px
        ${(props) =>
            props.className?.includes('add-column') && props.className?.includes('scrolling-vertically')
                ? '-16px'
                : props.className?.includes('add-column')
                ? '-1px'
                : props.className?.includes('scrolling-vertically') &&
                  props.className?.includes('scrolling-horizontally')
                ? '-16px'
                : props.className?.includes('scrolling-vertically')
                ? '-17px'
                : props.className?.includes('scrolling-horizontally')
                ? '-2px'
                : '-3px'};
    cursor: pointer;
    background-color: #f8f8f8;
    position: absolute;
    border-right: 1px solid #e8e8e8;
`

const AddRowButton = styled.div`
    font-weight: 200;
    font-size: x-large;
    padding: ${(props) => (props.className?.includes('add-row') ? '10px 8px 9px 9px' : '4px 9px 2px 9px')};
    margin: ${(props) => (props.className?.includes('scrolling-horizontally') ? '-48px' : '-33px')} 0px 0px;
    cursor: pointer;
    background-color: #ffffff;
    position: absolute;
`

export type ManageDataProps = {
    width: number
    height: number
}

export const ManageData: FC<ManageDataProps> = ({width, height}) => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const [refreshing] = useOrganisationContext.useOrganisationRefreshing()

    const data = useData()

    const dataEditorRef = React.useRef<DataEditorRef | null>(null)
    const {customRenderers} = useExtraCells()
    const [columns, setColumns] = useState<SizedGridColumn[]>()
    const [gridSelection, setGridSelection] = useState<GridSelection>()
    const [cellEditorValue, setCellEditorValue] = useState('')
    const alert = useAlert()
    const [hoverColumn, setHoverColumn] = useState<number>(-1)
    const [otherMouseGesture, setOtherMouseGesture] = useState<{buttons: number; type: string} | undefined>()
    const cellRef = useRef<HTMLDivElement>(null)
    const {openPanel: openCellMenu, panelProps: cellMenuProps, closePanel: closeCellMenu} = usePanelNavigator(
        'cellMenu',
    )
    const [editing, setEditing] = useState<{
        field: Field
        profileIdStr?: string
        originalValues: string[]
        item: Item
    }>()
    const [mutating, setMutating] = useState<{mutation: string; col?: number; row?: number} | undefined>()

    const minimumColumnWidth = (field: Field, headerOnly: boolean = false) =>
        Math.min(
            250,
            headerOnly
                ? measureTextWidth(field.name, {fontSize: 14, fontWeight: 600}) + 60
                : Math.max(
                      ...[
                          measureTextWidth(field.name, {fontSize: 14, fontWeight: 600}) + 60,
                          ...Object.keys(data.profilesByIdStr).map(
                              (profileId) =>
                                  measureTextWidth(
                                      data.profilesByIdStr[profileId].cellValues.find(
                                          (cellValue) => cellValue.fieldOrParentCategoryIdStr === field.idStr,
                                      )?.values?.[0] || '',
                                      {fontSize: 14, fontWeight: 200},
                                  ) + 20,
                          ),
                          ...(field.availableValues || []).map(
                              (value) => measureTextWidth(value, {fontSize: 16, fontWeight: 300}) + 20,
                          ),
                      ],
                  ),
        )

    const calculateColumns = (fields: Field[]): SizedGridColumn[] => [
        ...(fields
            .filter((field) => field.name)
            .map((field, index) => ({
                title: field.name,
                id: field.idStr,
                hasMenu: false,
                width: (columns || [])[index]?.width
                    ? Math.max(minimumColumnWidth(field, true), (columns || [])[index].width)
                    : minimumColumnWidth(field),
            })) || []),
        {
            title: '',
            id: 'add-column',
            hasMenu: false,
            width: 30,
        },
    ]

    const lastColumnResize = useRef<Date | undefined>()
    const onColumnResize = useCallback(
        (col: GridColumn, newSize: number) => {
            if (!columns) return
            const index = columns.indexOf(col as SizedGridColumn)
            const newCols = [...columns]
            newCols[index] = {
                ...newCols[index],
                width: Math.max(minimumColumnWidth(data.fields[index], true), newSize),
            }
            setColumns(newCols)
            lastColumnResize.current = new Date()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [columns],
    )

    const initializeCellEditor = (col: number, row?: number | undefined, initialText?: string) => {
        const element = document.getElementById('activeCellEditor')
        if (!element) return true
        if (initialText !== undefined) {
            setCellEditorValue(initialText)
        }

        if (col < 0) {
            element.style['display'] = 'none'
            return true
        }
        const bounds = dataEditorRef.current?.getBounds(col, row)
        if (!bounds) {
            element.style['display'] = 'none'
            return true
        }

        if (row === undefined) {
            element.style['top'] = `${bounds.y + 1}px`
            element.style['left'] = `${bounds.x - 246}px`
            element.style['width'] = `${bounds.width}px`
            element.style['height'] = `${bounds.height}px`
        } else {
            element.style['top'] = `${bounds.y + 3}px`
            element.style['left'] = `${bounds.x - 242}px`
            element.style['width'] = `${bounds.width}px`
            element.style['height'] = `${bounds.height}px`
        }
        element.style['display'] = 'inherit'

        const childElement = element.children[0] as HTMLInputElement
        if (!childElement) return true
        if (row === undefined) {
            childElement.style['width'] = `${bounds.width - 8}px`
            childElement.style['height'] = `${bounds.height - 4}px`
        } else {
            childElement.style['width'] = `${bounds.width - 11}px`
            childElement.style['height'] = `${bounds.height - 9}px`
        }
        childElement.focus()
        setTimeout(() => {
            typeof childElement.setSelectionRange === 'function' &&
                childElement.setSelectionRange(0, (initialText || '').length, 'forward')
        }, 1)
        return true
    }

    const displayCellMenu = ([col, row]: Item) => {
        if (
            data.profiles.length === row ||
            data.fields.length === col ||
            (editing && editing.item[0] === col && editing.item[1] === row)
        ) {
            setEditing(undefined)
            return true
        }

        const field = data.fields[col]
        if (field.type !== 'category') return true

        setEditing({
            field,
            originalValues: data.profiles[row].cellValues
                .filter((cellValue) => cellValue.fieldOrParentCategoryIdStr === field.idStr)
                .flatMap((cellValue) => cellValue.values),
            profileIdStr: data.profiles[row].idStr,
            item: [col, row],
        })
        openCellMenu('cellMenu', {itemId: 'cellMenu'})
        return false
    }

    const displayHeaderRename = (col: number) => {
        if (data.fields.length === col) {
            return
        }
        const field = data.fields[col]
        setEditing({field, originalValues: [field.name], item: [col, -1]})
    }

    const completeHeaderRename = async () => {
        if (editing && !editing.profileIdStr) {
            if (cellEditorValue !== '' && cellEditorValue !== editing.originalValues[0]) {
                setHoverColumn(-1)
                setMutating({mutation: 'rename-column', col: editing.item[0]})
                await data.updateFields([{...editing.field, name: cellEditorValue}], {})
                setMutating(undefined)
                for (let row = 0; row < data.profiles.length; row++) {
                    dataEditorRef.current?.updateCells([{cell: [editing.item[0], row]}])
                }
            }
            setEditing(undefined)
        }
    }

    const refreshCellMenu = (field: Field, options?: {hide?: boolean; newValues?: string[]}) => {
        if (options?.hide) {
            setEditing(undefined)
            closeCellMenu()
            return
        }
        if (!editing) return

        const [col, row] = editing.item
        if (field.type !== 'category') return
        const values =
            options?.newValues ||
            data.profiles[row].cellValues
                .filter((cellValue) => cellValue.fieldOrParentCategoryIdStr === field.idStr)
                .flatMap((cellValue) => cellValue.values)

        initializeCellEditor(col, row, '')
        setEditing({field, originalValues: values, profileIdStr: data.profiles[row].idStr, item: [col, row]})
    }

    useOnClickOutside([cellRef], completeHeaderRename)

    const addCategoryAndApply = async (value: string, options?: {removeMissingValues?: boolean}) => {
        if (!editing) return
        if (editing.field.availableValues.includes(value)) alert.error(`Value '${value}' cannot be duplicated.`)

        const orphanedValues = editing.field.subTypes.includes('multipleValues')
            ? undefined
            : findOrphanedValuesByField([editing.field.idStr], [editing!.profileIdStr!], [value]).find(
                  (ov) => ov.categoryIdStr === editing.field.idStr,
              )?.orphanedValues

        const updatedFields = await data.updateFieldsAndValues(
            [
                {
                    ...editing.field,
                    availableValues: [
                        ...editing.field.availableValues.filter(
                            (value) => !orphanedValues || !orphanedValues.includes(value),
                        ),
                        value,
                    ],
                },
            ],
            [
                {
                    fieldOrParentCategoryIdStr: editing.field.idStr,
                    profileIdStr: editing!.profileIdStr!,
                    values: [value],
                },
            ],
            {removeMissingValues: options?.removeMissingValues},
        )

        dataEditorRef.current?.updateCells([{cell: editing.item}])
        refreshCellMenu(updatedFields[0], {
            hide: !editing.field.subTypes.includes('multipleValues'),
            newValues: [...editing.originalValues, value],
        })
    }

    const renameCategory = async (newValue: string, oldValue: string) => {
        if (!editing) return

        const affectedProfiles = data.profiles.filter((profile) =>
            profile.cellValues.some(
                (cellValue) =>
                    cellValue.fieldOrParentCategoryIdStr === editing.field.idStr && cellValue.values.includes(oldValue),
            ),
        )

        const draftAvailableValues = uniq(
            editing.field.availableValues.map((value) => (value === oldValue ? newValue : value)),
        )

        const updatedFields = await data.updateFieldsAndValues(
            [
                {
                    ...editing.field,
                    availableValues: editing.field.subTypes?.includes('orderCustom')
                        ? draftAvailableValues
                        : draftAvailableValues.sort(),
                },
            ],
            affectedProfiles
                .flatMap((profile) =>
                    profile.cellValues.filter(
                        (cellValue) => cellValue.fieldOrParentCategoryIdStr === editing.field.idStr,
                    ),
                )
                .map((cellValue) => ({...cellValue, values: [...cellValue.values, newValue]})),
            {skipServerValues: true},
        )

        affectedProfiles.forEach((profile) => {
            dataEditorRef.current?.updateCells([{cell: [editing.item[0], profile.index]}])
        })
        refreshCellMenu(updatedFields[0], {
            hide: !editing.field.subTypes.includes('multipleValues'),
            newValues: editing.originalValues.map((v) => (v === oldValue ? newValue : v)),
        })
    }

    const findOrphanedValuesByField = (
        fieldIdStrsToBeCleared: string[],
        profileIdStrsToBeCleared: string[],
        valuesToBeKept: string[],
    ) =>
        fieldIdStrsToBeCleared
            .map((fieldIdStr) => ({
                categoryIdStr: fieldIdStr,
                orphanedValues: difference(
                    profileIdStrsToBeCleared.flatMap((profileIdStr) =>
                        data.profilesByIdStr[profileIdStr].cellValues
                            .filter((cellValue) => cellValue.fieldOrParentCategoryIdStr === fieldIdStr)
                            .flatMap((cellValue) => cellValue.values),
                    ),
                    valuesToBeKept,
                    data.profiles
                        .filter(({idStr}) => !profileIdStrsToBeCleared.includes(idStr))
                        .flatMap(({idStr}) =>
                            data.profilesByIdStr[idStr].cellValues
                                .filter((cellValue) => cellValue.fieldOrParentCategoryIdStr === fieldIdStr)
                                .flatMap((cellValue) => cellValue.values),
                        ),
                ),
            }))
            .filter(({orphanedValues}) => orphanedValues.length > 0)

    const removeOrphanedChildCategories = (
        orphanedValues: {
            categoryIdStr: string
            orphanedValues: string[]
        }[],
    ) =>
        data.updateFields(
            orphanedValues.map(({categoryIdStr, orphanedValues}) => {
                const field = data.fieldsByIdStr[categoryIdStr]
                return {
                    ...field,
                    availableValues: field.availableValues.filter((value) => !orphanedValues.includes(value)),
                }
            }),
            {
                removeMissingFields: false,
            },
        )

    const applyCategoryValues = async (values: string[], options?: {removeMissingValues?: boolean}) => {
        if (!editing) return
        if (!editing.profileIdStr) return

        const orphanedValues =
            editing.field.subTypes.includes('multipleValues') && !options?.removeMissingValues
                ? []
                : findOrphanedValuesByField([editing.field.idStr], [editing.profileIdStr], values)
        await data.updateValues(
            [
                {
                    fieldOrParentCategoryIdStr: editing.field.idStr,
                    profileIdStr: editing.profileIdStr!,
                    values,
                },
            ],
            {
                removeMissingValues: options?.removeMissingValues !== false,
                profilesByIdStr: data.profilesByIdStr,
                profiles: data.profiles,
            },
        )

        if (orphanedValues.length && !currentOrganisation?.appFeatures.communityBuild) {
            // console.log('removing found orhpans', orphanedValues)
            await removeOrphanedChildCategories(orphanedValues)
        }
        dataEditorRef.current?.updateCells([{cell: editing.item}])
        refreshCellMenu(editing.field, {
            hide: !editing.field.subTypes.includes('multipleValues'),
            newValues: options?.removeMissingValues ? values : uniq([...editing.originalValues, ...values]),
        })
    }

    const applyOrAddCategory = async (options?: {removeMissingValues?: boolean}) => {
        if (cellEditorValue === '') {
            return
        }
        try {
            if ((editing?.field?.availableValues || []).includes(cellEditorValue)) {
                setMutating({mutation: `pick-value-${cellEditorValue}`})
                await applyCategoryValues([cellEditorValue], options)
            } else {
                setMutating({mutation: 'add-value', col: (editing?.item || [])[0], row: (editing?.item || [])[1]})
                await addCategoryAndApply(cellEditorValue, options)
            }
        } finally {
            setMutating(undefined)
            dataEditorRef?.current?.focus()
        }
    }

    const addColumn = async () => {
        setMutating({mutation: 'add-column'})
        try {
            await data.addFields(
                [
                    {
                        name: `Column ${1 + data.fields.length}`,
                        type: 'text',
                        subTypes: [],
                        availableValues: [],
                    },
                ],
                {},
            )
            setTimeout(() => dataEditorRef.current?.scrollTo({amount: 100000, unit: 'px'}, {amount: 0, unit: 'px'}), 20)
        } finally {
            setMutating(undefined)
        }
    }

    const addRow = async () => {
        setMutating({mutation: 'add-row'})
        try {
            await data.addNewProfiles(
                [
                    data.fields.map((field) => ({
                        fieldIdStr: field.idStr,
                        values: [''],
                    })),
                ],
                {},
            )
            setTimeout(() => dataEditorRef.current?.scrollTo({amount: 0, unit: 'px'}, {amount: 100000, unit: 'px'}), 20)
        } finally {
            setMutating(undefined)
        }
    }

    const deleteRows = async (zeroBasedRowIndexes: number[]) => {
        setMutating({mutation: 'delete-rows', col: 0, row: zeroBasedRowIndexes[0]})
        try {
            const profileIdStrs = zeroBasedRowIndexes.map((row) => data.profiles[row].idStr)
            const orphanedValues = findOrphanedValuesByField(
                data.fields.filter((field) => field.type === 'category').map(({idStr}) => idStr),
                profileIdStrs,
                [],
            )
            await data.deleteProfiles(profileIdStrs, {})
            if (orphanedValues.length && !currentOrganisation?.appFeatures.communityBuild) {
                // console.log('removing found orhpans', orphanedValues)
                await removeOrphanedChildCategories(orphanedValues)
            }
            setGridSelection(undefined)
        } finally {
            setMutating(undefined)
        }
    }

    const deleteColumns = async (zeroBasedColumnIndexes: number[]) => {
        setMutating({mutation: 'delete-columns', col: zeroBasedColumnIndexes[0], row: 0})
        try {
            const remainingFields = data.fields.filter((field, index) => !zeroBasedColumnIndexes.includes(index))
            await data.updateFields(remainingFields, {
                removeMissingFields: true,
            })
            setGridSelection(undefined)
        } finally {
            setMutating(undefined)
        }
    }

    const deleteValues = async (
        zeroBasedColumnIndex: number,
        zeroBasedRowIndex: number,
        width: number,
        height: number,
    ) => {
        setMutating({mutation: 'delete-values', col: zeroBasedColumnIndex, row: zeroBasedRowIndex})
        const profiles = [...new Array(height)]
            .map((_, index) => data.profiles[zeroBasedRowIndex + index])
            .filter((profile) => profile)
        const fields = [...new Array(width)]
            .map((_, index) => data.fields[zeroBasedColumnIndex + index])
            .filter((field) => field)
        const orphanedValues = findOrphanedValuesByField(
            fields.filter((field) => field.type === 'category').map(({idStr}) => idStr),
            profiles.map(({idStr}) => idStr),
            [],
        )
        try {
            await data.updateValues(
                profiles.flatMap((profile) =>
                    fields.flatMap((field) => [
                        {
                            fieldOrParentCategoryIdStr: field.idStr,
                            profileIdStr: profile.idStr,
                            values: [],
                        },
                    ]),
                ),
                {removeMissingValues: true, profilesByIdStr: data.profilesByIdStr, profiles: data.profiles},
            )

            if (orphanedValues.length && !currentOrganisation?.appFeatures.communityBuild) {
                // console.log('removing found orhpans', orphanedValues)
                await removeOrphanedChildCategories(orphanedValues)
            }
        } finally {
            setMutating(undefined)
        }
    }

    const handleDelete = (selection: GridSelection) => {
        if (selection.rows?.toArray()?.length) {
            deleteRows(selection.rows.toArray())
        } else if (selection.columns?.toArray()?.length) {
            deleteColumns(selection.columns.toArray())
        } else if (selection.current?.range) {
            deleteValues(
                selection.current.range.x,
                selection.current.range.y,
                selection.current.range.width,
                selection.current.range.height,
            )
        }
        return true
    }

    const pasteValues = async (zeroBasedColumnIndex: number, zeroBasedRowIndex: number, values: string[][][]) => {
        if (values.length === 0) {
            return
        }
        setMutating({mutation: 'paste-values', col: zeroBasedColumnIndex, row: zeroBasedRowIndex})
        try {
            const fields = await data.pasteValues(
                zeroBasedRowIndex,
                zeroBasedColumnIndex,
                values.map((row) =>
                    row.map((cellValues, colIndex) =>
                        data.fields[zeroBasedColumnIndex + colIndex].type === 'category'
                            ? cellValues.flatMap((value) => value.split(',').map((value) => value.trim()))
                            : cellValues,
                    ),
                ),
            )

            dataEditorRef.current?.updateCells(
                values.flatMap((_, row) =>
                    fields.map((_, col) => ({cell: [zeroBasedColumnIndex + col, zeroBasedRowIndex + row]})),
                ),
            )
        } finally {
            setMutating(undefined)
        }
    }

    const handlePaste = ([col, row]: Item, values: readonly (readonly string[])[]) => {
        navigator.clipboard.readText().then((text) => {
            if (!text) {
                pasteValues(
                    col,
                    row,
                    values.map((row) => row.map((cell) => [cell])),
                )
                return
            }
            const matrix: string[][][] = [[]]
            const pushValue = (value: string) => {
                const unquoted =
                    value.startsWith('"') && value.endsWith('"') ? value.slice(1, -1).replace(/""/g, '"') : value
                matrix[matrix.length - 1].push([unquoted])
            }
            let index = 0
            let inQuote = false
            let accumulatedValue = ''
            while (index < text.length) {
                const char = text[index]
                if (char === '"') {
                    inQuote = !inQuote
                    accumulatedValue += char
                } else if (!inQuote && (char === '\t' || char === '\n')) {
                    pushValue(accumulatedValue)
                    if (char === '\n') {
                        matrix.push([])
                    }
                    accumulatedValue = ''
                } else {
                    accumulatedValue += char
                }
                index++
            }
            pushValue(accumulatedValue)
            pasteValues(col, row, matrix)
        })
        return false
    }

    const handleGridMouseMove = (ev: GridMouseEventArgs) => {
        const newValue = ev.kind !== 'header' || ev.location[0] >= (columns || []).length - 1 ? -1 : ev.location[0]
        if (hoverColumn !== newValue) {
            setHoverColumn(newValue)
        }
    }

    const handleGridKeyUp = (ev: GridKeyEventArgs) => {
        if (ev.key === 'x' && ev.ctrlKey) {
            dataEditorRef.current?.emit('copy')
            dataEditorRef.current?.emit('delete')
        }
    }

    const handleDoubleClick = (ev: React.MouseEvent) => {
        if (lastColumnResize.current && lastColumnResize.current.valueOf() > new Date().valueOf() - 1000) return
        for (let index = 0; index < data.fields.length; index++) {
            const bounds = dataEditorRef.current?.getBounds(index)
            if (!bounds) return
            if (bounds.y > ev.clientY) return
            if (bounds.y + bounds.height < ev.clientY) return
            if (bounds.x + 8 > ev.clientX) return
            if (bounds.x + bounds.width - 45 < ev.clientX) continue
            displayHeaderRename(index)
        }
    }

    const getData = ([col, row]: Item): EditableGridCell => {
        if (row >= data.profiles.length || col >= data.fields.length) {
            return {
                kind: GridCellKind.Custom,
                data: {
                    kind: 'button-cell',
                    title: '',
                    color: '#000000',
                },
                readonly: true,
                allowOverlay: false,
                copyData: '',
                themeOverride: {
                    baseFontStyle: '500 25px halyard-display',
                    bgCell: '#f8f8f8',
                    borderColor: '#f8f8f8',
                    accentColor: '#f8f8f8',
                    accentFg: '#f8f8f8',
                    accentLight: '#f8f8f8',
                },
            }
        }
        const field = data.fields[col]
        const values = data.profiles[row].cellValues
            .filter((cellValue) => cellValue.fieldOrParentCategoryIdStr === field.idStr)
            .flatMap((cellValue) => cellValue.values.filter((value) => value))
        if (field && field.type === 'category') {
            const orderedValues = field.availableValues.filter((v) => values.includes(v))
            const cell: EditableGridCell = {
                kind: GridCellKind.Text,
                data: orderedValues.join(', '),
                allowOverlay: false,
                displayData: orderedValues.join(', '),
            }
            return cell
        }
        return {
            kind: GridCellKind.Text,
            data: values.join(', '),
            allowOverlay: true,
            displayData: values.join(', '),
        }
    }

    const setData = async ([col, row]: Item, newValue: any) => {
        const value = (newValue?.data?.value || newValue?.data) as string
        if (!value) return
        await data.updateValues(
            [
                {
                    fieldOrParentCategoryIdStr: data.fields[col].idStr,
                    profileIdStr: data.profiles[row].idStr,
                    values: [value],
                },
            ],
            {
                removeMissingValues: !data.fields[col].subTypes.includes('multipleValues'),
                profilesByIdStr: data.profilesByIdStr,
                profiles: data.profiles,
            },
        )
        dataEditorRef.current?.updateCells([{cell: [col, row]}])
    }

    useEffect(() => {
        if (mutating?.col !== undefined) {
            initializeCellEditor(mutating.col, mutating.row)
        } else if (editing && editing.item[1] >= 0) {
            initializeCellEditor(editing.item[0], editing.item[1])
        } else if (editing) {
            initializeCellEditor(editing.item[0], undefined, editing.originalValues.join('\n'))
        } else {
            initializeCellEditor(-1, -1, '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, editing, mutating])

    useEffect(() => {
        setColumns(calculateColumns(data.fields))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.profiles, data.fields])

    if (refreshing?.error) {
        return (
            <>
                <Typography.Heading>Something went wrong</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>
                    {refreshing?.error && getGraphQLErrorMessage(refreshing?.error)}
                </Typography.Subheading>
                {/* <Spacer height={32} />
                <Button variant="primary" onClick={() => setRefreshing({})}>
                    Try again
                </Button> */}
            </>
        )
    }

    if (refreshing?.loading || data.loading) {
        return <PageLoading />
    }

    const gridDesiredSize = {
        width: 34 + columns!.reduce((p, c) => p + c.width, 0),
        height: 71 + data.profiles!.length * 34,
    }
    const gridSize = {
        width: Math.min(
            width - 250,
            gridDesiredSize.height > height - 2 ? gridDesiredSize.width + 14 : gridDesiredSize.width,
        ),
        height: Math.min(
            height - 2,
            gridDesiredSize.width > width - 250 ? gridDesiredSize.height + 14 : gridDesiredSize.height,
        ),
    }

    return (
        <>
            <div style={{maxWidth: width, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: width - 300}}>
                    <Row>
                        <Column>
                            <Typography.Heading>Your data</Typography.Heading>
                            <Row height="16px" />
                            <Typography.Subheading style={{maxHeight: '36px'}}>
                                This is the current data available for display in your Names & Faces index.
                            </Typography.Subheading>
                        </Column>
                        {currentOrganisation?.appFeatures?.communityBuild ? (
                            <Column shrink={0} centerVertical>
                                <Row height="40px"></Row>
                                <Button
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            `${window.location.origin}/community_build/${currentOrganisation?.forms[0]?.idStr}`,
                                        )
                                        alert.success('Invite link copied.')
                                    }}
                                >
                                    Copy invite link
                                </Button>
                            </Column>
                        ) : (
                            <></>
                        )}
                    </Row>
                    <Row height="40px"></Row>
                </div>
            </div>
            <div style={{maxWidth: width, display: 'flex', flexDirection: 'column'}}>
                <div
                    style={{
                        width: gridSize.width,
                        border: 'solid 1px #e8e8e8',
                        borderRight: 'none',
                        display: 'inline-block',
                    }}
                    onMouseMove={setOtherMouseGesture}
                    onWheel={setOtherMouseGesture}
                    onDoubleClick={handleDoubleClick}
                >
                    <DataEditor
                        width={gridSize.width}
                        height={gridSize.height}
                        getCellContent={getData}
                        columns={columns!}
                        rows={data.profiles!.length + 1}
                        ref={dataEditorRef}
                        customRenderers={customRenderers}
                        theme={{
                            fontFamily: 'halyard-display',
                            accentColor: colors.primary['100'],
                            accentFg: '#FFFFFF',
                            accentLight: colors.primary['10'],
                            headerFontStyle: '600 14px',
                            baseFontStyle: '14px',
                            editorFontSize: '14px',
                            bgCellMedium: '#f8f8f8',
                        }}
                        rightElement={false}
                        maxColumnWidth={1000}
                        minColumnWidth={10}
                        smoothScrollX={true}
                        smoothScrollY={true}
                        rowMarkers={'clickable-number'}
                        gridSelection={gridSelection}
                        getCellsForSelection={true}
                        onCellEdited={setData}
                        onCellActivated={displayCellMenu}
                        onColumnResize={onColumnResize}
                        onDelete={handleDelete}
                        onPaste={handlePaste}
                        onGridSelectionChange={(gridSelection) => setGridSelection(gridSelection)}
                        onKeyUp={handleGridKeyUp}
                        onMouseMove={handleGridMouseMove}
                    ></DataEditor>
                    <AddRowButton
                        onClick={() => addRow()}
                        className={`${mutating?.mutation || ''} ${
                            gridSize.width < gridDesiredSize.width ? 'scrolling-horizontally' : ''
                        }`}
                    >
                        {mutating?.mutation !== 'add-row' ? '+' : <Spinner></Spinner>}
                    </AddRowButton>
                    {dataEditorRef.current && (
                        <ManageColumn
                            hoverColumn={hoverColumn}
                            otherMouseGesture={otherMouseGesture}
                            columns={data.fields.map((column, index) => ({
                                ...column,
                                isSelected: gridSelection?.columns?.hasIndex(index) || false,
                                subTypes: column.subTypes,
                            }))}
                            getCellBounds={dataEditorRef.current.getBounds}
                            updateField={async (
                                col: number,
                                field: Partial<Field>,
                                valueMutator?: (value: string) => string | undefined,
                            ) => {
                                if (field?.type === 'category' && field?.availableValues?.length === 0) {
                                    field.availableValues = uniq(
                                        data.profiles.flatMap((profile) =>
                                            profile.cellValues[col].values
                                                .flatMap((value) => value.split(','))
                                                .map((value) => value.trim())
                                                .filter((value) => value),
                                        ),
                                    )
                                }
                                const updatedFields = await data.updateFields([{...data.fields[col], ...field}], {})
                                if (valueMutator) {
                                    const cellValuesToUpdate = data.profiles.flatMap((profile) => {
                                        const cellValue = profile.cellValues[col]
                                        const mutation = cellValue.values.map((value) => ({
                                            current: value,
                                            mutated: valueMutator(value),
                                        }))
                                        return mutation.some((pair) => pair.current !== pair.mutated)
                                            ? [
                                                  {
                                                      ...cellValue,
                                                      values: mutation
                                                          .map((pair) => pair.mutated)
                                                          .filter((value) => value) as string[],
                                                  },
                                              ]
                                            : []
                                    })
                                    if (cellValuesToUpdate.length) {
                                        await data.updateValues(cellValuesToUpdate, {
                                            skipServer: true,
                                            fields: updatedFields,
                                        })
                                    }
                                }
                            }}
                            sortField={data.sortField}
                            setSortField={data.setSortField}
                        ></ManageColumn>
                    )}
                    <CellEditor id="activeCellEditor" ref={cellRef}>
                        {['delete-rows', 'delete-columns', 'delete-values', 'paste-values'].includes(
                            mutating?.mutation || '',
                        ) ? (
                            <CellEditorSpinnerContainer className={mutating?.mutation || ''}>
                                <Spinner></Spinner>
                            </CellEditorSpinnerContainer>
                        ) : ['rename-column'].includes(mutating?.mutation || '') ? (
                            <CellEditorSpinnerContainer className={mutating?.mutation || ''}>
                                <Spinner tint="#FFFFFF"></Spinner>
                            </CellEditorSpinnerContainer>
                        ) : (
                            <CellEditorInput
                                id="activeCellEditorInput"
                                type="text"
                                value={cellEditorValue}
                                onChange={(e) => setCellEditorValue(e.target.value)}
                                onKeyUp={(e) =>
                                    e.key === 'Enter' &&
                                    (editing?.profileIdStr === undefined
                                        ? completeHeaderRename()
                                        : applyOrAddCategory({
                                              removeMissingValues: !(editing?.field?.subTypes || []).includes(
                                                  'multipleValues',
                                              ),
                                          }))
                                }
                            ></CellEditorInput>
                        )}
                    </CellEditor>
                    <Panel
                        targetRef={cellRef}
                        {...cellMenuProps}
                        onClose={() => refreshCellMenu(data.fields[0], {hide: true})}
                    >
                        <PanelLayout key="cellMenu">
                            <PickCategory
                                availableValues={editing?.field?.availableValues || []}
                                selectedValues={editing?.originalValues || []}
                                searchValue={cellEditorValue?.split('\n')?.[0] || ''}
                                addValue={addCategoryAndApply}
                                pickValue={applyCategoryValues}
                                updateValue={renameCategory}
                                mutating={mutating}
                                setMutating={setMutating}
                                pickMultiple={(editing?.field?.subTypes || []).includes('multipleValues')}
                            ></PickCategory>
                        </PanelLayout>
                    </Panel>
                </div>
                <AddColumnButton
                    onClick={() => addColumn()}
                    className={`${mutating?.mutation || ''} ${
                        gridSize.height < gridDesiredSize.height ? 'scrolling-vertically' : ''
                    } ${gridSize.width < gridDesiredSize.width ? 'scrolling-horizontally' : ''}`}
                    style={{left: gridSize.width - 27}}
                >
                    {mutating?.mutation !== 'add-column' ? '+' : <Spinner></Spinner>}
                </AddColumnButton>
            </div>
        </>
    )
}
