import styled, {css} from 'styled-components'
import {isIOS} from 'react-device-detect'

const BREAKPOINT_DESKTOP_MIN = '1200px'
const BREAKPOINT_TABLET_MAX = '1199px'
const BREAKPOINT_TABLET_MIN = '810px'
const BREAKPOINT_PHONE_MAX = '809px'
const BREAKPOINT_PHONE_MIN = '390px'
const BREAKPOINT_SMALL_MAX = '389px'

export type DeviceWidth = 'Desktop' | 'Tablet' | 'Phone' | 'Small'

export type HideableCssProps = {deviceWidths?: DeviceWidth[]}

export type PositionCssProps = {position?: string; top?: string; right?: string; bottom?: string; left?: string}

export const PositionCss = css<PositionCssProps>`
    ${({position}) => position && `position: ${position};`}
    ${({top}) => top && `top: ${top};`}
    ${({right}) => right && `right: ${right};`}
    ${({bottom}) => bottom && `bottom: ${bottom};`}
    ${({left}) => left && `left: ${left};`}
`

export const HideableCss = css<HideableCssProps>`
    ${({deviceWidths: deviceWidth}) => `
        ${
            deviceWidth && !deviceWidth.includes('Desktop')
                ? `
            @media (min-width: ${BREAKPOINT_DESKTOP_MIN}) {
                display: none;
            }
        `
                : ''
        }
        ${
            deviceWidth && !deviceWidth.includes('Tablet')
                ? `
            @media (min-width: ${BREAKPOINT_TABLET_MIN}) and (max-width: ${BREAKPOINT_TABLET_MAX}) {
                display: none;
            }
        `
                : ''
        }
        ${
            deviceWidth && !deviceWidth.includes('Phone')
                ? `
            @media (min-width: ${BREAKPOINT_PHONE_MIN}) and (max-width: ${BREAKPOINT_PHONE_MAX}) {
                display: none;
            }
        `
                : ''
        }
        ${
            deviceWidth && !deviceWidth.includes('Small')
                ? `
            @media (max-width: ${BREAKPOINT_SMALL_MAX}) {
                display: none;
            }
        `
                : ''
        }
    `}
`

export const Column = styled.div<
    {
        width?: string
        minWidth?: string
        maxWidth?: string
        height?: string
        grow?: number
        shrink?: number
        overflow?: string
        overflowX?: string
        overflowY?: string
        backgroundColor?: string
        borderRadius?: string
        centerVertical?: boolean
        cursor?: string
    } & HideableCssProps &
        PositionCssProps
>`
    display: flex;
    flex-direction: column;
    justify-content: ${({centerVertical}) => (centerVertical ? 'center' : 'space-between')};
    ${({backgroundColor}) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
    ${({borderRadius}) => (borderRadius ? `border-radius: ${borderRadius};` : '')}
    ${({width}) => (width ? `width: ${width};` : '')}
    ${({minWidth}) => (minWidth ? `min-width: ${minWidth};` : '')}
    ${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    ${({height}) => (height ? `height: ${height};` : '')}
    ${({grow}) => (grow !== undefined ? `flex-grow: ${grow};` : '')}
    ${({shrink}) => (shrink !== undefined ? `flex-shrink: ${shrink};` : '')}
    ${({overflow}) => (overflow !== undefined ? `overflow: ${overflow};` : '')}
    ${({overflowX}) => (overflowX !== undefined ? `overflow-x: ${overflowX};` : '')}
    ${({overflowY}) => (overflowY !== undefined ? `overflow-y: ${overflowY};` : '')}
    ${({cursor}) => (cursor !== undefined ? `cursor: ${cursor};` : '')}
    ${HideableCss}
    ${PositionCss}
`

export const Row = styled.div<
    {
        position?: string
        height?: string
        width?: string
        maxWidth?: string
        grow?: number
        shrink?: number
        overflow?: string
        wrapLine?: boolean
        backgroundColor?: string
        borderRadius?: string
        padding?: string
        centerHorizontal?: boolean
        alignLeft?: boolean
        cursor?: string
    } & HideableCssProps &
        PositionCssProps
>`
    display: flex;
    flex-direction: row;
    justify-content: ${({alignLeft, centerHorizontal}) =>
        alignLeft ? 'flex-start' : centerHorizontal ? 'center' : 'space-between'};
    ${({backgroundColor}) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
    ${({borderRadius}) => (borderRadius ? `border-radius: ${borderRadius};` : '')}
    ${({padding}) => (padding ? `padding: ${padding};` : '')}
    ${({wrapLine}) => wrapLine && `flex-wrap: wrap;`}
    ${({height}) => (height ? `height: ${height};` : '')}
    ${({width}) => (width ? `width: ${width};` : '')}
    ${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    ${({grow}) => (grow !== undefined ? `flex-grow: ${grow};` : '')}
    ${({shrink}) => (shrink !== undefined ? `flex-shrink: ${shrink};` : '')}
    ${({overflow}) => (overflow !== undefined ? `overflow: ${overflow};` : '')}
    ${({cursor}) => (cursor !== undefined ? `cursor: ${cursor};` : '')}
    ${HideableCss}
    ${PositionCss}
`

const DEFAULT_FINISH_TIMEOUT_MILLISECONDS = 400
export const scrollIfNeeded = (
    element: HTMLElement | null,
    options?: {
        startTimeoutMilliseconds?: number
        finishTimeoutMilliseconds?: number
        marginTop?: number
        marginBottom?: number
        scrollContainer?: HTMLElement | null
        scrolling?: {scrollToY: number; finishTimeoutMilliseconds: number}
        align?: 'top' | 'center' | 'bottom'
        onScrollBegin?: (element: HTMLElement) => void
        onScrollComplete?: (element: HTMLElement) => void
    },
) => {
    if (!element) return
    const container = {
        width: options?.scrollContainer
            ? options.scrollContainer.clientWidth
            : isIOS
            ? window.visualViewport.width
            : window.innerWidth,
        height: options?.scrollContainer
            ? options.scrollContainer.clientHeight
            : isIOS
            ? window.visualViewport.height
            : window.innerHeight,
    }
    const scroll = {
        x: options?.scrollContainer ? options.scrollContainer.scrollLeft : window.scrollX,
        y: options?.scrollContainer ? options.scrollContainer.scrollTop : window.scrollY,
    }
    const offset = {
        x: element.offsetLeft,
        y: element.offsetTop,
        width: element.offsetWidth,
        height: element.offsetHeight,
    }
    const neededTop = scroll.y - offset.y + (options?.marginTop || 0)
    const neededBottom = offset.y + offset.height + (options?.marginBottom || 0) - scroll.y - container.height
    const scrollingNeededY =
        offset.height > 0 && (neededTop > 0 || neededBottom > 0) && (neededTop <= 0 || neededBottom <= 0)
    const scrollToY =
        options?.align === 'top'
            ? offset.y - (options?.marginTop || 0)
            : options?.align === 'bottom'
            ? offset.y + (offset.height + (options?.marginBottom || 0)) - container.height
            : offset.y +
              (offset.height + (options?.marginBottom || 0) - (options?.marginTop || 0)) / 2 -
              container.height / 2
    const initiateNewScrolling = scrollingNeededY && (!options?.scrolling || options.scrolling.scrollToY !== scrollToY)

    // console.log(
    //     'scrollIfNeeded',
    //     'container',
    //     container,
    //     'scroll',
    //     scroll,
    //     'offset',
    //     offset,
    //     'scrollingNeededY',
    //     scrollingNeededY,
    //     'scrollToY',
    //     scrollToY,
    //     'options',
    //     options
    // )

    if (options?.scrolling && options.scrolling.finishTimeoutMilliseconds <= 0) {
        options?.onScrollComplete && options.onScrollComplete(element)
        return
    }

    if (!scrollingNeededY && (options?.startTimeoutMilliseconds || 0) <= 0) {
        options?.onScrollComplete && options.onScrollComplete(element)
        return
    }

    if (initiateNewScrolling) {
        options?.onScrollBegin && options.onScrollBegin(element)
        const scrollContainer = options?.scrollContainer || window
        if (
            (options?.scrolling?.finishTimeoutMilliseconds !== undefined
                ? options?.scrolling?.finishTimeoutMilliseconds
                : options?.finishTimeoutMilliseconds !== undefined
                ? options?.finishTimeoutMilliseconds
                : DEFAULT_FINISH_TIMEOUT_MILLISECONDS) <= 0
        ) {
            scrollContainer.scrollTo({top: scrollToY})
            options?.onScrollComplete && options.onScrollComplete(element)
            return
        } else {
            scrollContainer.scrollTo({top: scrollToY, behavior: 'smooth'})
        }
    }

    setTimeout(
        () =>
            scrollIfNeeded(element, {
                startTimeoutMilliseconds: (options?.startTimeoutMilliseconds || 0) - 200,
                marginTop: options?.marginTop,
                marginBottom: options?.marginBottom,
                align: options?.align,
                scrollContainer: options?.scrollContainer,
                scrolling: initiateNewScrolling
                    ? {
                          scrollToY,
                          finishTimeoutMilliseconds: options?.scrolling
                              ? (options?.scrolling?.finishTimeoutMilliseconds || 0) - 200
                              : options?.finishTimeoutMilliseconds !== undefined
                              ? options?.finishTimeoutMilliseconds
                              : DEFAULT_FINISH_TIMEOUT_MILLISECONDS,
                      }
                    : options?.scrolling
                    ? {
                          ...options?.scrolling,
                          finishTimeoutMilliseconds: (options?.scrolling?.finishTimeoutMilliseconds || 0) - 200,
                      }
                    : undefined,
                onScrollComplete: options?.onScrollComplete,
            }),
        200,
    )
}
