import {ApolloError, FetchResult, gql, MutationHookOptions, MutationTuple, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {LogSessionActivity, LogSessionActivityVariables} from './__types__/LogSessionActivity'

const LOG_SESSION_ACTIVITY = gql`
    mutation LogSessionActivity($userIdStr: String!, $platform: String!) {
        logSessionActivity(userIdStr: $userIdStr, platform: $platform)
    }
`

export const useMutationLogSessionActivity = (
    options?: MutationHookOptions<LogSessionActivity, LogSessionActivityVariables>,
): [
    MutationTuple<LogSessionActivity, LogSessionActivityVariables>,
    (variables: {
        userIdStr: string
        platform: string
    }) => Promise<FetchResult<LogSessionActivity, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<LogSessionActivity, LogSessionActivityVariables>(LOG_SESSION_ACTIVITY, options)
    const callMutation = async (variables: {userIdStr: string; platform: string}) => {
        try {
            const result = await mutation[0]({variables})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
